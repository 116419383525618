import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useUser as useClerkUser } from '@clerk/clerk-react';
import { supabase } from '../utils/supabaseClient';
import { User } from '../types/User';

interface UserContextType {
  isLoaded: boolean;
  isSignedIn: boolean | undefined;
  clerkUser: any | null;
  supabaseUser: User | null;
  refreshSupabaseUser: () => Promise<void>;
}

const UserContext = createContext<UserContextType>({
  isLoaded: false,
  isSignedIn: undefined,
  clerkUser: null,
  supabaseUser: null,
  refreshSupabaseUser: async () => {},
});

export function UserProvider({ children }: { children: ReactNode }) {
  const { isLoaded, isSignedIn, user: clerkUser } = useClerkUser();
  const [supabaseUser, setSupabaseUser] = useState<User | null>(null);

  const fetchSupabaseUser = async () => {
    if (!clerkUser?.id) return;

    try {
      const { data: existingUser, error: fetchError } = await supabase
        .from('users')
        .select('*')
        .eq('clerk_id', clerkUser.id)
        .single();

      if (fetchError) {
        if (fetchError.code === 'PGRST116') {
          const { data: newUser, error: createError } = await supabase
            .from('users')
            .insert([{
              clerk_id: clerkUser.id,
              language: navigator.language.split('-')[0] || 'ja',
              created_at: new Date().toISOString(),
              is_admin: false
            }])
            .select()
            .single();

          if (createError) throw createError;
          setSupabaseUser(newUser);
          return;
        }
        throw fetchError;
      }

      setSupabaseUser(existingUser);
    } catch (error) {
      console.error('Error in fetchSupabaseUser:', error);
      setSupabaseUser(null);
    }
  };

  useEffect(() => {
    if (isSignedIn && clerkUser?.id) {
      fetchSupabaseUser();
    } else {
      setSupabaseUser(null);
    }
  }, [isSignedIn, clerkUser?.id]);

  return (
    <UserContext.Provider value={{ 
      isLoaded, 
      isSignedIn, 
      clerkUser, 
      supabaseUser,
      refreshSupabaseUser: fetchSupabaseUser
    }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
} 