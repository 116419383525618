import { useEffect } from 'react';
import './PrivacyPage.scss';

export function PrivacyPage({ countryCode }: { countryCode: string }) {
  const isJapanese = countryCode.toLowerCase() === 'jp';

  useEffect(() => {
    document.body.style.background = '';
  }, []);

  return (
    <div className="privacy-container">
      <div className="privacy-content">
        <h1>{isJapanese ? 'プライバシーポリシー' : 'Privacy Policy'}</h1>

        {isJapanese ? (
          <>
            <h2>Bar Doppi - プライバシーポリシー</h2>
            <p>最終更新日: 2024年12月13日</p>

            <p>
              本プライバシーポリシー（以下「本ポリシー」）は、Bar Doppi（以下「当社」または「当社の」）およびその関連会社が、「本ポリシー」へリンクするウェブサイト、モバイルアプリ、その他の電子的・デジタルな製品・サービス、およびオフラインで提供されるサービス（以下総称して「本サービス」）を通じて、ユーザー（「ユーザー」または「あなた」）から収集・処理する情報の種類および取扱い方法について説明します。
            </p>

            <p>
              本ポリシーに特段の記載がない限り、本ポリシーは、当社が提供・運営するすべてのサービスに適用されます。
            </p>

            <p>
              本サービスを利用することで、あなたは本ポリシーに記載された慣行に同意したものとみなされます。本ポリシーに同意いただけない場合は、本サービスへのアクセスまたは利用をお控えください。
            </p>

            <p>
              本ポリシーで使用される未定義の用語は、当社の利用規約（Terms of Service）において定義されます。
            </p>

            <h2>1. 収集する情報</h2>
            <p>
              当社は、あなたが本サービスにアクセスまたは利用する際、あなたから情報を収集する場合があります。収集する情報の種類は、あなたが本サービスをどのように利用するかに依存します。また、本サービス提供に不可欠な情報があり、その情報が提供されない場合や削除を求められた場合、特定の機能が利用できなくなる可能性があります。
            </p>

            <h3>（1）あなたが直接提供する情報</h3>
            <p>
              あなたが本サービスを利用する過程で、当社に直接情報を提供する場合があります。たとえば、アカウント登録、ニュースレター購読、キャンペーン参加、サポートへの問い合わせなどを通じて、以下のような情報を提供することがあります：<br />
              - メールアドレス<br />
              - ユーザー名<br />
              - 支払い情報（決済代行業者を通じて）<br />
              - 利用規約で定義されるユーザーコンテンツ（チャット内容、投稿画像、共有キャラクター等）<br />
              - 当社とのコミュニケーション内容
            </p>
            <p>
              一部情報の提供は任意ですが、特定の機能が利用不可となる場合があります。
            </p>

            <h3>（2）自動的に収集する情報</h3>
            <p>
              当社および第三者ベンダー（分析事業者、広告ネットワーク等）は、クッキー、ウェブビーコン、その他のトラッキング技術を利用して、あなたが本サービスにアクセスする際に使用するコンピュータやデバイスに関する情報を自動的に収集する場合があります。
            </p>
            <p>
              この情報には、以下が含まれます：<br />
              - ブラウザ種類<br />
              - ISPまたはOS情報<br />
              - ドメイン名<br />
              - アクセス時刻<br />
              - 参照元/離脱ページ<br />
              - ページビュー数<br />
              - IPアドレス<br />
              - デバイス固有ID（IDFA、Android IDなど）<br />
              - 利用中の本サービスのバージョン<br />
              - 使用デバイスの種類
            </p>
            <p>
              当社は、あなたが本サービスをいつ、どのように利用するか、どのページを閲覧するか、どのように操作するかを追跡することがあります。これらの情報は、分析やサービス改善、広告効果測定、その他本ポリシーに記載された目的で利用します。
            </p>
            <p>
              クッキーやピクセルタグなどの技術は、ユーザー行動の理解、個別化、研究・分析、本サービス改善に役立ちます。また、これらの技術は、パスワード保存、ページ訪問記録、コンテンツ管理、使用状況統計の作成、メール開封追跡などにも利用されることがあります。
            </p>
            <p>
              多くのブラウザは自動的にクッキーを受け入れますが、ブラウザ設定で拒否できる場合があります。ただし、クッキーを無効化すると、本サービスの一部機能が正常に動作しない可能性があります。詳細は https://www.allaboutcookies.org/ を参照してください。
            </p>
            <p>
              あなたが本サービスを利用する際、IPアドレス等から推定した大まかな位置情報を収集することがあります。
            </p>

            <h3>（3）他の情報源からの情報収集</h3>
            <p>
              当社は、他のユーザーや第三者（ソーシャルメディアプラットフォーム等）からあなたに関する情報を取得する場合があります。また、あなたが第三者アカウント（FacebookやGoogleなど）で当社サービスに接続した場合、当該アカウントから権限に応じて情報を取得する場合があります。
            </p>
            <p>
              あなたはこれらの第三者アカウントと接続することを選択できますが、これは任意であり、後から接続を解除することも可能です。ただし、当社は過去に取得した情報を保持する場合があります。
            </p>

            <h2>2. 情報の利用目的</h2>
            <p>
              当社は、収集した情報を以下の目的で利用します：<br />
              - 本サービスの提供・運営<br />
              - 本サービスのパーソナライズ<br />
              - あなたとのコミュニケーション（本サービス機能のお知らせ、利用規約・本ポリシーの変更通知など）<br />
              - あなたからのお問い合わせ、コメント、フィードバック、質問への対応<br />
              - 本サービスの分析・維持・改善・修正・カスタマイズ、およびAI/機械学習モデルのトレーニング<br />
              - 新プログラム・新サービスの開発<br />
              - 不正行為や犯罪行為、サービスの不正利用防止、システムやネットワークのセキュリティ確保<br />
              - 法的義務遵守や法的手続への対応、当社・関連会社・あなた・その他第三者の権利、プライバシー、安全、資産の保護、利用規約や契約の執行<br />
              - 情報収集時に特定したその他の目的
            </p>
            <p>
              当社は、他の情報源から取得した情報を組み合わせたり、情報を匿名化または集計化した上で、研究・マーケティングなどあらゆる目的で利用・開示する場合があります。
            </p>

            <h2>3. 情報の開示</h2>
            <p>
              当社は、以下の場合にあなたの情報を開示することがあります：
            </p>
            <p>
              <strong>関連会社：</strong><br />
              当社は、当社を支配、または当社によって支配、または共通の支配下にある関連会社に対して、本ポリシーに従って情報を開示することがあります。
            </p>
            <p>
              <strong>ベンダー：</strong><br />
              当社は、当社に代わりデータ保管、決済、技術サポート、カスタマーサービス、分析、詐欺防止、法務、マーケティングなどを行う外部業者へ情報を開示します。
            </p>
            <p>
              <strong>安全・保護：</strong><br />
              当社は、善意に基づき、権利・財産・安全の保護や、本ポリシー・利用規約・契約の執行のために必要と判断した場合、情報を開示することがあります。
            </p>
            <p>
              <strong>広告・分析：</strong><br />
              現時点で当社は広告を行っていませんが、将来的に、広告配信や分析目的で第三者パートナーと情報を共有する場合があります。
            </p>
            <p>
              <strong>法的要請：</strong><br />
              令状、召喚状、裁判所命令、その他の法的手続きまたは法令遵守のために、情報を開示することがあります。
            </p>
            <p>
              <strong>事業譲渡：</strong><br />
              当社事業または資産の全部または一部の合併、売却、資金調達、買収交渉時や成立時に、情報を開示することがあります。
            </p>
            <p>
              <strong>ユーザーコンテンツ：</strong><br />
              あなたが作成または共有したユーザーコンテンツは、他のユーザーから閲覧可能になる場合があります。例えば、あなたが「公開」設定したキャラクターは他のユーザーに表示され、グループチャットでの発言はそのグループ内の他ユーザーに表示されます。
            </p>
            <p>
              <strong>同意・指示による開示：</strong><br />
              あなたの同意または指示に基づき、非関連第三者へ情報を開示することがあります。
            </p>

            <h2>4. オンライン分析およびターゲット広告</h2>
            <p>
              <strong>分析：</strong><br />
              当社は、Google Analyticsなどの第三者ウェブ分析サービスを利用する場合があります。これらはクッキー等を利用して、本サービス利用状況を分析します。Google Analyticsによる解析を望まない場合、Google Analyticsオプトアウトブラウザアドオンを利用できます。
            </p>
            <p>
              <strong>ターゲット広告：</strong><br />
              現在は行っていないものの、将来的に第三者広告ネットワークがお客様の利用履歴を用いて興味・関心に基づく広告を配信する場合があります。ユーザーは、Network Advertising Initiative (NAI) や Digital Advertising Alliance (DAA) のオプトアウトリンク等を利用してターゲット広告をオプトアウトできます。
            </p>

            <h2>5. ユーザーの選択肢</h2>
            <p>
              当社は、情報の収集・利用・開示に関して、ユーザーが一定の選択を行えるようにしています。
            </p>
            <p>
              <strong>プロフィール情報：</strong><br />
              アカウント設定ページからアカウントを無効化したり、個人情報を確認・訂正・削除できます。
            </p>
            <p>
              <strong>マーケティングコミュニケーション：</strong><br />
              マーケティングメール内の指示に従って配信停止できますが、利用規約や本ポリシー変更などサービス運営上必要な連絡は継続する場合があります。
            </p>
            <p>
              <strong>クッキーと分析：</strong><br />
              ブラウザ設定や上記のオプトアウト方法を利用してクッキーや分析を制御できます。ただし、一部機能に支障が生じる可能性があります。
            </p>
            <p>
              <strong>各地域の権利：</strong><br />
              お住まいの地域の法律に応じて、個人情報に関する開示・訂正・削除・利用停止・移転要求や、プロファイリング拒否、同意撤回などの権利を有する場合があります。詳細は「6. 地域別プライバシー開示事項」を参照してください。
            </p>

            <h2>6. 地域別プライバシー開示事項</h2>
            <p>
              EEA、英国、カリフォルニア州、ネバダ州居住者向けの追加開示事項は以下のとおりです。
            </p>

            <h3>欧州経済領域（EEA）および英国居住者</h3>
            <p>
              当社はGDPR上の「データ管理者」です。法的根拠は、契約履行、同意、正当な利益、法的義務遵守などが含まれます。ユーザーは監督当局への苦情申立権を有します。
            </p>

            <h3>ネバダ州居住者</h3>
            <p>
              ネバダ州居住者は特定の個人情報を第三者に販売しないよう要求する権利があります。当社は現時点でこのような販売は行っていません。
            </p>

            <h3>カリフォルニア州居住者</h3>
            <p>
              CCPAに基づき、当社が収集する個人情報カテゴリー、利用目的、開示先は本ポリシー中で説明しています。当社は将来的にオンライン識別子を広告パートナーに「共有」する場合があります。16歳未満の子供の個人情報を意図的に「販売」または「共有」しません。
            </p>

            <h2>7. 子供のプライバシー</h2>
            <p>
              本サービスは13歳未満（EEAでは16歳未満）の未成年者を対象としていません。これらの年齢未満のユーザーから個人情報を取得したと判明した場合、法定範囲で情報を削除します。
            </p>

            <h2>8. セキュリティ</h2>
            <p>
              当社は技術的・物理的・組織的手段を用いて情報を保護しますが、インターネットやメール通信上の完全な安全性は保証できません。
            </p>

            <h2>9. データ保持</h2>
            <p>
              当社は、収集目的を達成するために必要な期間、情報を保持します。その後、法的義務または紛争解決、契約執行に必要な場合を除き、削除または集計化を行います。
            </p>
            <p>
              公開されたキャラクターが非常に人気を博した場合、当社はそのキャラクターの特徴を保持することがありますが、ユーザーに関する個人情報は保持しないよう努めます。
            </p>

            <h2>10. 国際データ移転</h2>
            <p>
              当社は、米国などあなたの居住国とは異なるデータ保護法を有する国であなたの情報を保存・処理する場合があります。本サービス利用により、これらの国への情報移転に同意したものとみなします。
            </p>

            <h2>11. 第三者サイトへのリンク</h2>
            <p>
              本サービスには第三者サイトへのリンクが含まれる場合があります。当社は第三者サイトのコンテンツやプライバシー慣行に責任を負いません。第三者サイトに個人情報を提供する前に、そのプライバシーポリシーを必ず確認してください。
            </p>

            <h2>12. 本ポリシーの変更</h2>
            <p>
              当社は法令変更や実務慣行変更に応じて本ポリシーを改訂することがあります。最新の更新日は本ポリシー冒頭に記載され、重大な変更の場合は適切な通知を行います。改訂後も本サービスを利用することで、改訂後のポリシーに同意したものとみなします。
            </p>

            <h2>13. お問い合わせ</h2>
            <p>
              本ポリシーまたは情報取扱いに関するお問い合わせは、以下までご連絡ください：<br />
              メール：keiji@doppi.ai<br />
              <br />
              住所：<br />
              166 Geary St<br />
              STE 1500 #1897<br />
              San Francisco, CA 94108
            </p>
          </>
        ) : (
          <>
            <h2>Bar Doppi - Privacy Policy</h2>
            <p>Last Updated: December 13, 2024</p>

            <p>
              This Privacy Policy (the “Privacy Policy” or “Policy”) describes the types of information Bar Doppi and its affiliates (“Bar Doppi,” “we,” “our,” or “us”) collect and process from and about you in connection with any websites, mobile applications, and any other electronic and/or digital products and/or offline services that link to this Policy (collectively, the “Services”).
            </p>

            <p>
              Except as described in this Privacy Policy, this Policy applies to any and all Services that link to this Policy and are provided by Bar Doppi and our affiliates.
            </p>

            <p>
              By using the Services, you agree to the practices described in this Policy. If you do not agree to the practices described in this Policy, please do not access or use the Services.
            </p>

            <p>
              Undefined capitalized terms used herein shall have the meaning set forth in the Bar Doppi Terms of Service.
            </p>

            <h2>1. Information We Collect</h2>
            <p>
              When you access or otherwise use our Services, we may collect information from you. The types of information we collect depend on how you use our Services. Some information may be necessary for providing the Services; if you do not provide such information, or if you ask us to delete it, certain features or aspects of the Services may no longer be available.
            </p>

            <h3>Information You Provide Directly to Us</h3>
            <p>
              We may collect information directly from you when you use the Services, communicate with us, register for an account, subscribe to newsletters, or participate in promotions. This may include, but is not limited to:<br />
              - Email address<br />
              - User name<br />
              - Payment information (through our payment processing vendors)<br />
              - User Content (as defined in our Terms of Service), such as chat communications, posted images, and shared Characters<br />
              - Contents of communications with us
            </p>

            <h3>Information We Collect Automatically</h3>
            <p>
              We and our third-party vendors (including analytics companies and potential advertising networks) may use cookies, web beacons, and other tracking technologies to collect information about the computers or devices you use to access the Services.
            </p>
            <p>
              Such information may include:<br />
              - Browser type<br />
              - ISP or operating system<br />
              - Domain name<br />
              - Access times<br />
              - Referring/exit pages<br />
              - Page views<br />
              - IP address<br />
              - Unique device identifiers (e.g., IDFA or Android ID)<br />
              - The version of the Services you’re using<br />
              - The type of device you use
            </p>
            <p>
              We may also track how frequently you use the Services, how you engage with or navigate through our sites or apps. We use this information for analytics, improving our Services, evaluating advertising campaigns (if any), and other purposes described in this Policy.
            </p>
            <p>
              We and our third-party vendors may use cookies, clear GIFs, pixel tags, and other technologies to better understand user behavior, personalize user experiences, perform research and analytics, and improve the Services. These technologies may allow us to tailor the Services to your needs, save your password, track pages visited, manage content, and compile usage statistics. We may also use these technologies in emails to help track email response rates and when emails are viewed or forwarded.
            </p>
            <p>
              Most browsers accept cookies by default, but you may modify your browser settings to decline cookies. Disabling cookies may prevent some parts of the Services from functioning properly. For more information, visit https://www.allaboutcookies.org/.
            </p>
            <p>
              When you use the Services, we may collect general location information (such as that inferred from your IP address).
            </p>

            <h3>Information We Collect from Other Sources</h3>
            <p>
              We may also obtain information about you from other users or third parties, including social media platforms. When you connect a third-party account (e.g., Facebook, Google) to our Services, we receive information according to the permissions granted. Connecting your account is optional, and you can disconnect it later, though we may retain previously collected information.
            </p>

            <h2>2. How We Use the Information We Collect</h2>
            <p>
              We may use the information we collect for the following purposes:<br />
              - To provide and operate the Services<br />
              - To personalize the Services<br />
              - To communicate with you (about features, policy changes, etc.)<br />
              - To respond to your inquiries, comments, feedback, or questions<br />
              - To analyze, maintain, improve, modify, customize, and measure the Services, and to train our AI/machine learning models<br />
              - To develop new programs and services<br />
              - To detect and prevent fraud, criminal activity, or misuse of our Services, and to ensure security of our systems and networks<br />
              - To comply with legal obligations, legal processes, and to protect our rights, privacy, safety or property, and/or those of our affiliates, you, or others, and to enforce our Terms of Service and other agreements<br />
              - For any other purpose identified at the time of collection
            </p>
            <p>
              We may combine information from different sources and may anonymize or aggregate it for research, marketing, and any other purposes.
            </p>

            <h2>3. When We Disclose the Information We Collect</h2>
            <p>
              We may disclose your information in the following circumstances:
            </p>
            <p>
              <strong>Affiliates:</strong><br />
              We may disclose information to our affiliates (entities that control, are controlled by, or are under common control with Bar Doppi) in a manner consistent with this Policy.
            </p>
            <p>
              <strong>Vendors:</strong><br />
              We may disclose information to vendors who perform services on our behalf, such as data storage, payment processing, technical support, customer service, analytics, fraud prevention, legal services, and marketing services.
            </p>
            <p>
              <strong>Safety and Protection:</strong><br />
              We may disclose information if we believe in good faith that it is necessary to protect or defend Bar Doppi or other parties (including to enforce this Policy, our Terms of Service, or other agreements) and to protect the rights, property, or personal safety of Bar Doppi, our affiliates, our employees, our users, or the public.
            </p>
            <p>
              <strong>Advertising and Analytics:</strong><br />
              While we do not currently engage in advertising, we may in the future share certain information with third parties for advertising or analytics purposes.
            </p>
            <p>
              <strong>Legal Requirements:</strong><br />
              We may disclose information as required by law, such as to comply with a subpoena, warrant, court order, or other legal processes.
            </p>
            <p>
              <strong>Business Transfers:</strong><br />
              In connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business, we may disclose certain information during negotiations or completion of such transactions.
            </p>
            <p>
              <strong>Your User Content:</strong><br />
              Certain actions you take or Content you provide (such as creating a Public Character or participating in a Group Chat) may be visible to other users of the Services.
            </p>
            <p>
              <strong>With Your Consent or Direction:</strong><br />
              We may disclose your information to third parties based on your consent or at your direction.
            </p>

            <h2>4. Online Analytics and Tailored Advertising</h2>
            <p>
              <strong>Analytics:</strong><br />
              We may use third-party analytics services, such as Google Analytics. These services use the information described above to help us analyze how users use the Services. To prevent Google Analytics from using your information for analytics, you may install the Google Analytics Opt-Out Browser Add-on.
            </p>
            <p>
              <strong>Tailored Advertising:</strong><br />
              While we do not currently engage in advertising, we may in the future allow third-party advertising networks to place cookies or other tracking technologies to collect information about your use of the Services to serve you targeted advertising. You can learn more about opting out of targeted advertising through the NAI and DAA sites and other industry opt-out mechanisms.
            </p>

            <h2>5. Your Choices</h2>
            <p>
              We offer certain choices about how we collect, use, and disclose your information.
            </p>
            <p>
              <strong>Profile Information:</strong><br />
              You may deactivate your account and update or delete certain personal information through your account settings.
            </p>
            <p>
              <strong>Marketing Communications:</strong><br />
              You can unsubscribe from marketing emails by following the instructions in those emails. However, we may still send you administrative emails regarding the Services.
            </p>
            <p>
              <strong>Cookies and Analytics:</strong><br />
              You can control or opt out of certain cookie-based analytics as described above. Disabling cookies may affect the functionality of some parts of the Services.
            </p>
            <p>
              <strong>Rights Under Applicable Laws:</strong><br />
              Depending on your jurisdiction, you may have rights related to your personal information (such as access, correction, deletion, processing restrictions, portability, and objection to certain processing, including profiling). See Section 6 for regional disclosures.
            </p>

            <h2>6. Regional Privacy Disclosures</h2>
            <p>
              Additional disclosures and rights for residents of the European Economic Area (EEA), United Kingdom, California, and Nevada are provided below.
            </p>

            <h3>EEA and UK Residents</h3>
            <p>
              Bar Doppi is the “data controller” for GDPR purposes. Our legal bases include contractual necessity, consent, legitimate interests, and legal compliance. You have the right to lodge a complaint with your local supervisory authority.
            </p>

            <h3>Nevada Residents</h3>
            <p>
              Nevada residents have the right to opt out of certain sales of personal information. We do not currently sell personal information as defined by Nevada law.
            </p>

            <h3>California Residents</h3>
            <p>
              Under the California Consumer Privacy Act (CCPA), we provide additional disclosures throughout this Policy. We may in the future “share” certain online identifiers with advertising partners. We do not knowingly “sell” or “share” personal information of children under 16.
            </p>

            <h2>7. Children’s Privacy</h2>
            <p>
              The Services are not directed to children under 13 (or under 16 in the EEA). If we learn that a child under these ages has provided us with personal information, we will delete such information as required by applicable law.
            </p>

            <h2>8. Security</h2>
            <p>
              We use technical, administrative, and physical safeguards to protect your information. However, no security measure is perfect, and we cannot guarantee complete security.
            </p>

            <h2>9. Data Retention</h2>
            <p>
              We retain your information for as long as necessary to fulfill the purposes for which it was collected. We may also retain information as needed to comply with legal obligations, resolve disputes, and enforce agreements.
            </p>
            <p>
              If a Public Character you create becomes highly popular, we may preserve that Character’s attributes to avoid impacting other users’ experiences, while endeavoring not to retain personal information about you.
            </p>

            <h2>10. Consent to Transfer</h2>
            <p>
              Your information may be stored and processed in the United States or other countries with different data protection laws than your country of residence. By using the Services, you consent to such transfers.
            </p>

            <h2>11. Links to Third-Party Websites</h2>
            <p>
              Our Services may contain links to third-party websites or services. We are not responsible for their content or practices. Their collection and use of your information is subject to their own privacy policies.
            </p>

            <h2>12. Changes to this Policy</h2>
            <p>
              We may update this Policy to reflect changes in law, our data practices, or features of the Services. The last updated date is indicated at the top. If we make material changes, we will provide notice as required by law. By continuing to use the Services, you affirm that you have read and understand the latest version of this Policy.
            </p>

            <h2>13. Contact Us</h2>
            <p>
              If you have any questions about this Policy or our information practices, please contact us at:<br />
              Email: keiji@doppi.ai<br />
              <br />
              Address:<br />
              166 Geary St<br />
              STE 1500 #1897<br />
              San Francisco, CA 94108
            </p>
          </>
        )}
      </div>
    </div>
  );
}
