import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { AssistantDataType } from '../types/Assistant';
import './AssistantPage.scss';
import { useUser } from '@clerk/clerk-react';

export function AssistantPage() {
  const { shortId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [assistant, setAssistant] = useState<AssistantDataType | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userCoins, setUserCoins] = useState<number | null>(null);
  const [isCheckingCoins, setIsCheckingCoins] = useState(false);
  const { user } = useUser();

  const countryCode = location.pathname.split('/')[1];

  const isRegionPath = (id: string) => {
    return id.includes('-') || id.includes(' ');
  };

  useEffect(() => {
    const fetchAssistant = async () => {
      try {
        if (!shortId) throw new Error('No assistant ID provided');
        
        if (isRegionPath(shortId)) {
          navigate(`/${countryCode}`, { replace: true });
          return;
        }

        if (!/^[a-z0-9]{12}$/.test(shortId)) {
          console.error('Invalid shortId format:', shortId);
          throw new Error('Invalid assistant ID format');
        }

        console.log('Searching for assistant with shortId:', shortId);

        const { data: assistants, error } = await supabase
          .from('assistants')
          .select('*');

        if (error) throw error;

        const matchingAssistant = assistants.find(a => a.id.endsWith(shortId));

        if (!matchingAssistant) {
          console.log('No match found for ID:', shortId);
          throw new Error('No matching assistant found');
        }

        if (matchingAssistant.country.toLowerCase() !== countryCode) {
          navigate(`/${matchingAssistant.country.toLowerCase()}/${shortId}`, { replace: true });
          return;
        }

        console.log('Found assistant:', matchingAssistant);
        setAssistant(matchingAssistant);
        setLoading(false);
        
      } catch (err) {
        console.error('Error in fetchAssistant:', err);
        setError('アシスタントの取得に失敗しました');
        setLoading(false);
      }
    };

    fetchAssistant();
  }, [shortId, countryCode, navigate]);

  if (loading) {
    return (
      <div className="assistant-page loading">
        <div className="spinner"></div>
      </div>
    );
  }

  if (error || !assistant || !assistant.country) {
    return (
      <div className="assistant-page error">
        アシスタントが見つかりませんでした
      </div>
    );
  }

  const countryPath = assistant.country.toLowerCase();

  const fetchUserCoins = async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('user_coins')
        .select('coins')
        .eq('user_id', userId)
        .single();

      if (error) {
        console.error('Error fetching user coins:', error);
        return 0;
      }

      return data?.coins || 0;
    } catch (err) {
      console.error('Error in fetchUserCoins:', err);
      return 0;
    }
  };

  const handleStartCall = async () => {
    if (!user) {
      navigateToConversation();
      return;
    }

    setIsCheckingCoins(true);
    try {
      const { data: userData } = await supabase
        .from('users')
        .select('id')
        .eq('clerk_id', user.id)
        .single();

      if (!userData?.id) {
        setIsCheckingCoins(false);
        return;
      }

      const coins = await fetchUserCoins(userData.id);
      setUserCoins(coins);

      if (coins <= 0) {
        setIsCheckingCoins(false);
        alert('コインがありません。コインを購入するか、毎日のボーナスコインをお待ちください。');
        return;
      }

      navigateToConversation();
    } catch (err) {
      console.error('Error checking coins:', err);
      setIsCheckingCoins(false);
      alert('コインの確認中にエラーが発生しました。もう一度お試しください。');
    }
  };

  const navigateToConversation = () => {
    navigate(`/${countryPath}`, {
      state: { 
        assistant,
        autoConnect: true,
        startCall: true 
      }
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="assistant-page">
      <div className="assistant-profile">
        <button 
          onClick={handleBack} 
          className="back-button" 
          aria-label="戻る"
        >
          ←
        </button>
        <div className="avatar-container">
          {assistant.avatar_url ? (
            <img 
              src={assistant.avatar_url} 
              alt={assistant.display_name} 
              className="avatar-image"
            />
          ) : (
            <div className="avatar-fallback">
              {assistant.display_name[0]}
            </div>
          )}
        </div>
        <h1 className="assistant-name">{assistant.display_name}</h1>
        <button 
          className="call-button"
          onClick={handleStartCall}
          disabled={isCheckingCoins}
        >
          START
        </button>
        {user && userCoins === 0 && (
          <p className="no-coins-warning">
            コインがありません。コインを購入するか、毎日のボーナスコインをお待ちください。
          </p>
        )}
      </div>
    </div>
  );
}
