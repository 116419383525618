import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { supabase } from '../lib/supabase';
import './NicknameSetupPage.scss';
import { CountryProps } from '../types/props';

// 言語ごとのラベル定義
const translations = {
  ar: {
    title: 'اختر اسمًا مستعارًا',
    description: 'أدخل اسمًا مستعارًا للاستخدام في التطبيق',
    placeholder: 'أدخل اسمك المستعار',
    button: 'حفظ والمتابعة',
    saving: 'جاري الحفظ...',
    errors: {
      required: 'الرجاء إدخال اسم مستعار',
      network: 'يوجد مشكلة في الاتصال بالإنترنت',
      timeout: 'انتهت مهلة الاتصال بالخادم',
      duplicate: 'هذا الاسم المستعار مستخدم بالفعل'
    }
  },
  de: {
    title: 'Spitznamen festlegen',
    description: 'Geben Sie einen Spitznamen für die App ein',
    placeholder: 'Spitznamen eingeben',
    button: 'Speichern und fortfahren',
    saving: 'Wird gespeichert...',
    errors: {
      required: 'Bitte geben Sie einen Spitznamen ein',
      network: 'Netzwerkverbindungsproblem',
      timeout: 'Zeitüberschreitung der Serverantwort',
      duplicate: 'Dieser Spitzname wird bereits verwendet'
    }
  },
  en: {
    title: 'Set Nickname',
    description: 'Enter a nickname to use in the app',
    placeholder: 'Enter nickname',
    button: 'Save and continue',
    saving: 'Saving...',
    errors: {
      required: 'Please enter a nickname',
      network: 'Network connection problem',
      timeout: 'Server response timeout',
      duplicate: 'This nickname is already in use'
    }
  },
  es: {
    title: 'Establecer apodo',
    description: 'Ingrese un apodo para usar en la aplicación',
    placeholder: 'Ingrese apodo',
    button: 'Guardar y continuar',
    saving: 'Guardando...',
    errors: {
      required: 'Por favor ingrese un apodo',
      network: 'Problema de conexión de red',
      timeout: 'Tiempo de espera del servidor agotado',
      duplicate: 'Este apodo ya está en uso'
    }
  },
  fr: {
    title: 'Définir un pseudo',
    description: 'Entrez un pseudo à utiliser dans l\'application',
    placeholder: 'Entrez un pseudo',
    button: 'Enregistrer et continuer',
    saving: 'Enregistrement...',
    errors: {
      required: 'Veuillez entrer un pseudo',
      network: 'Problème de connexion réseau',
      timeout: 'Délai d\'attente du serveur dépassé',
      duplicate: 'Ce pseudo est déjà utilisé'
    }
  },
  id: {
    title: 'Atur Nama Panggilan',
    description: 'Masukkan nama panggilan untuk digunakan di aplikasi',
    placeholder: 'Masukkan nama panggilan',
    button: 'Simpan dan lanjutkan',
    saving: 'Menyimpan...',
    errors: {
      required: 'Silakan masukkan nama panggilan',
      network: 'Masalah koneksi jaringan',
      timeout: 'Waktu respons server habis',
      duplicate: 'Nama panggilan ini sudah digunakan'
    }
  },
  it: {
    title: 'Imposta nickname',
    description: 'Inserisci un nickname da utilizzare nell\'app',
    placeholder: 'Inserisci nickname',
    button: 'Salva e continua',
    saving: 'Salvataggio...',
    errors: {
      required: 'Inserisci un nickname',
      network: 'Problema di connessione di rete',
      timeout: 'Timeout risposta server',
      duplicate: 'Questo nickname è già in uso'
    }
  },
  ja: {
    title: 'ニックネームを設定',
    description: 'アプリで使用するニックネームを入力してください',
    placeholder: 'ニックネームを入力',
    button: '保存して続ける',
    saving: '保存中...',
    errors: {
      required: 'ニックネームを入力してださい',
      network: 'ネットワーク接続に問題があります',
      timeout: 'サーバーからの応答がありません',
      duplicate: 'このニックネームは既に使用されています'
    }
  },
  ko: {
    title: '닉네임 설정',
    description: '앱에서 사용할 닉네임을 입력하세요',
    placeholder: '닉네임 입력',
    button: '저장하고 계속하기',
    saving: '저장 중...',
    errors: {
      required: '닉네임을 입력해주세요',
      network: '네트워크 연결 문제',
      timeout: '서버 응답 시간 초과',
      duplicate: '이미 사용 중인 닉네임입니다'
    }
  },
  ru: {
    title: 'Установить никнейм',
    description: 'Введите никнейм для использования в приложении',
    placeholder: 'Введите никнейм',
    button: 'Сохранить и продолжить',
    saving: 'Сохранение...',
    errors: {
      required: 'Пожалуйста, введите никнейм',
      network: 'Проблема с сетевым подключением',
      timeout: 'Превышено время ожидания ответа сервера',
      duplicate: 'Этот никнейм уже используется'
    }
  },
  th: {
    title: 'ตั้ชื่อเล่น',
    description: 'ป้อนชื่อเล่นที่จะใช้ในแอป',
    placeholder: 'ป้อนชื่อเล่น',
    button: 'บันทึกและดำเนินการต่อ',
    saving: 'กำลังบันทึก...',
    errors: {
      required: 'กรุณาป้อนชื่อเล่น',
      network: 'ปัญหาการเชื่อมต่อเครือข่าย',
      timeout: 'หมดเวลาการตอบสนองของเซิร์ฟเวอร์',
      duplicate: 'ชื่อเล่นนี้ถูกใช้แล้ว'
    }
  },
  tr: {
    title: 'Takma Ad Belirle',
    description: 'Uygulamada kullanmak için bir takma ad girin',
    placeholder: 'Takma ad girin',
    button: 'Kaydet ve devam et',
    saving: 'Kaydediliyor...',
    errors: {
      required: 'Lütfen bir takma ad girin',
      network: 'Ağ bağlantı sorunu',
      timeout: 'Sunucu yanıt zaman aşımı',
      duplicate: 'Bu takma ad zaten kullanılıyor'
    }
  },
  vi: {
    title: 'Đặt biệt danh',
    description: 'Nhập biệt danh để sử dụng trong ứng dụng',
    placeholder: 'Nhập biệt danh',
    button: 'Lưu và tiếp tục',
    saving: 'Đang lưu...',
    errors: {
      required: 'Vui lòng nhập biệt danh',
      network: 'Sự cố kết nối mạng',
      timeout: 'Hết thời gian phản hồi từ máy chủ',
      duplicate: 'Biệt danh này đã được sử dụng'
    }
  },
  zh: {
    title: '设置昵称',
    description: '输入要在应用中使用的昵称',
    placeholder: '输入昵称',
    button: '保存并继续',
    saving: '保存中...',
    errors: {
      required: '请输入昵称',
      network: '网络连接问题',
      timeout: '服务器响应超时',
      duplicate: '该昵称已被使用'
    }
  }
};

// CountryPropsを拡張して新しい型を作成
interface NicknameSetupPageProps extends CountryProps {
  returnPath?: string;
}

export function NicknameSetupPage({ countryCode, returnPath }: NicknameSetupPageProps) {
  const [nickname, setNickname] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isLoaded: isUserLoaded } = useUser();
  const [language, setLanguage] = useState('ja');

  useEffect(() => {
    let isMounted = true;

    const setupUser = async () => {
      if (!user?.id) return;
      
      try {
        const { data: existingUser, error: fetchError } = await supabase
          .from('users')
          .select('nickname, language, id')
          .eq('clerk_id', user.id)
          .single();

        if (!isMounted) return;

        if (fetchError && fetchError.code === 'PGRST116') {
          const { data: duplicateCheck } = await supabase
            .from('users')
            .select('id')
            .eq('clerk_id', user.id);

          if (!duplicateCheck || duplicateCheck.length === 0) {
            const { data: newUser, error: insertError } = await supabase
              .from('users')
              .insert({
                clerk_id: user.id,
                language: navigator.language.split('-')[0] || 'ja',
                country: countryCode,
                created_at: new Date().toISOString(),
                is_admin: false,
                stripe_customer_id: null
              })
              .select('id')
              .single();

            if (insertError) throw insertError;

            if (newUser?.id) {
              console.log('Attempting to create referral code for user:', newUser.id);
              const { data: referralCode, error: referralError } = await supabase
                .rpc('create_referral_code', {
                  input_user_id: newUser.id
                });

              console.log('Referral code creation result:', { referralCode, referralError });
              
              if (referralError) {
                console.error('Error creating referral code:', referralError);
              }
            }
          }
          
          setIsLoading(false);
          setIsPageLoading(false);
          return;
        }

        if (existingUser?.id) {
          const { data: existingCode } = await supabase
            .from('referral_codes')
            .select('code')
            .eq('user_id', existingUser.id)
            .single();

          if (!existingCode) {
            const { error: referralError } = await supabase
              .rpc('create_referral_code', {
                input_user_id: existingUser.id
              });

            if (referralError) {
              console.error('Error creating referral code:', referralError);
            }
          }
        }

        if (fetchError) throw fetchError;

        if (existingUser?.language) {
          setLanguage(existingUser.language);
        }

        if (existingUser?.nickname) {
          navigate(returnPath || `/${countryCode.toLowerCase()}`, { replace: true });
        } else {
          setIsLoading(false);
          setIsPageLoading(false);
        }
      } catch (error) {
        console.error('Error in setupUser:', error);
        setError('ユーザー設定の初期化に失敗しました');
        setIsLoading(false);
        setIsPageLoading(false);
      }
    };

    if (isUserLoaded) {
      setupUser();
    }

    return () => {
      isMounted = false;
    };
  }, [user, navigate, isUserLoaded, language, countryCode, returnPath]);

  if (isPageLoading) {
    return null;
  }

  const t = translations[language as keyof typeof translations];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !nickname.trim()) return;

    setIsLoading(true);
    try {
      const { data: userData, error: insertError } = await supabase
        .from('users')
        .update({ nickname: nickname.trim() })
        .eq('clerk_id', user.id)
        .select('id')
        .single();

      if (insertError) {
        console.error('Insert error:', insertError);
        switch (insertError.code) {
          case '23505':
            setError(t.errors.duplicate);
            break;
          case '23502':
            setError(t.errors.required);
            break;
          default:
            setError(t.errors.network);
        }
        return;
      }

      if (userData?.id) {
        const { error: referralError } = await supabase
          .rpc('create_referral_code', {
            input_user_id: userData.id
          });

        if (referralError) {
          console.error('Error creating referral code:', referralError);
        }

        const { error: bonusError } = await supabase
          .rpc('give_referral_bonus', {
            target_user_id: userData.id,
            bonus_amount: 20
          });

        if (bonusError) {
          console.error('Error giving signup bonus:', bonusError);
        }
      }

      // 現在のパスから特別モードを判断
      const pathParts = window.location.pathname.split('/');
      const isSpecialMode = ['soka', 'christian', 'ozen', 'mise'].includes(pathParts[2]);

      if (isSpecialMode) {
        navigate(`/${countryCode.toLowerCase()}/${pathParts[2]}`, { replace: true });
      } else {
        navigate(`/${countryCode.toLowerCase()}`, { replace: true });
      }

    } catch (err) {
      console.error('Error saving nickname:', err);
      if (err instanceof Error) {
        if (err.message.includes('network')) {
          setError(t.errors.network);
        } else if (err.message.includes('timeout')) {
          setError(t.errors.timeout);
        } else {
          setError(t.errors.network);
        }
      } else {
        setError(t.errors.network);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="nickname-setup-page">
      <div className="setup-container">
        <h1>{t.title}</h1>
        <p>{t.description}</p>
        
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            placeholder={t.placeholder}
            maxLength={20}
            disabled={isLoading}
            autoFocus
          />
          
          {error && <div className="error-message">{error}</div>}
          
          <button 
            type="submit" 
            disabled={isLoading || !nickname.trim()}
          >
            {isLoading ? (
              <div className="button-content">
                <div className="button-spinner"></div>
                <span>{t.saving}</span>
              </div>
            ) : (
              t.button
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
