import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../lib/supabase';
import { 
  getCountryFromPath, 
  SUPPORTED_COUNTRIES,
  getLanguageFromCountry 
} from '../utils/country';

interface SEOProps {
  children?: React.ReactNode;
}

interface SEOSettings {
  title: string;
  description: string;
  ogTitle: string;
  ogDescription: string;
}

// デフォルトのSEO設定を改善
const DEFAULT_SEO_SETTINGS: SEOSettings = {
  title: 'Doppi Bar - AI Assistant',
  description: 'Experience natural conversations with AI assistants.',
  ogTitle: 'Doppi Bar - Your AI Conversation Partner',
  ogDescription: 'Experience natural conversations with our AI assistants.'
};

// 地域のデフォルトSEO設定を生成する関数を追加
const getDefaultRegionSEO = (regionName: string, countryName: string): SEOSettings => {
  // 日本の地域の場合
  if (countryName.toUpperCase() === 'JP') {
    return {
      title: `Doppi BAR ${regionName}店 - AIアシスタントとの会話`,
      description: `Doppi BAR ${regionName}店の公式ページです。${regionName}で最高のAIアシスタント体験をお届けします。気軽に立ち寄れるAIバーで、新しいコミュニケーションを。`,
      ogTitle: `Doppi BAR ${regionName}店 - AIアシスタント`,
      ogDescription: `${regionName}で最高のAIアシスタント体験を。Doppi BAR ${regionName}店で、新しいコミュニケーションの形を体験してください。`
    };
  }
  
  // 英語圏の地域の場合
  return {
    title: `Doppi AI Bar ${regionName} - Experience AI Conversations`,
    description: `Visit Doppi AI Bar in ${regionName}, ${countryName}. Enjoy natural conversations with our AI assistants in a unique bar setting.`,
    ogTitle: `Doppi AI Bar ${regionName} - Your AI Conversation Partner`,
    ogDescription: `Experience engaging AI conversations at Doppi Bar ${regionName}. Join us for a unique social experience with advanced AI technology.`
  };
};

// 地域のSEO設定を取得する関数を修正
const loadRegionSEOSettings = async (countryCode: string, regionName: string) => {
  const cacheKey = `seo_${countryCode}_${regionName}`;
  const cachedData = sessionStorage.getItem(cacheKey);
  
  if (cachedData) {
    return JSON.parse(cachedData);
  }

  try {
    // 地域情報の取得開始をログ
    console.log('🔍 Fetching region info:', { countryCode, regionName });

    const { data: regions, error: regionError } = await supabase
      .from('regions')
      .select('id, name')
      .eq('country_code', countryCode.toUpperCase())
      .or(`name.eq.${regionName},name.ilike.${regionName.replace(/-/g, ' ')}`);

    if (regionError) {
      console.error('❌ Error fetching region:', regionError);
      return null;
    }

    if (!regions?.[0]) {
      console.warn('⚠️ Region not found:', { countryCode, regionName });
      return null;
    }

    const regionId = regions[0].id;
    const regionDisplayName = regions[0].name;
    
    // 型安全な方法でcountryNameを取得
    const upperCountryCode = countryCode.toUpperCase() as keyof typeof SUPPORTED_COUNTRIES;
    const countryCode2 = SUPPORTED_COUNTRIES[upperCountryCode];
    
    // i18nから国名を取得する処理を追加
    const countryName = countryCode2 || countryCode.toUpperCase();

    // SEO設定の取得をログ
    console.log('🔍 Fetching SEO settings for region:', { regionId, regionDisplayName });

    const { data, error } = await supabase
      .from('region_seo_settings')
      .select('*')
      .eq('country_code', countryCode.toLowerCase())
      .eq('region_id', regionId)
      .single();

    if (error) {
      console.error('❌ Error fetching SEO settings:', error);
      return getDefaultRegionSEO(regionDisplayName, countryName);
    }

    // 取得したSEO設定をログ
    console.log('✅ Loaded region SEO settings:', data);

    const result = {
      title: data.title,
      description: data.description,
      ogTitle: data.og_title,
      ogDescription: data.og_description
    };
    
    // キャッシュに保存
    sessionStorage.setItem(cacheKey, JSON.stringify(result));
    return result;
  } catch (error) {
    console.error('❌ Unexpected error:', error);
    return null;
  }
};

export function SEO({ children }: SEOProps) {
  const [seoSettings, setSeoSettings] = useState<SEOSettings>({
    title: 'Doppi - AI Assistant',
    description: 'Experience natural conversations with AI assistants.',
    ogTitle: 'Doppi - Your AI Conversation Partner',
    ogDescription: 'Experience natural conversations with our AI assistants.'
  });
  const [ogImageUrl, setOgImageUrl] = useState<string>('');
  const location = useLocation();
  const countryCode = getCountryFromPath(location.pathname);
  const country = SUPPORTED_COUNTRIES[countryCode].toLowerCase();

  // URLから地域情報を取得
  const pathParts = location.pathname.split('/');
  const regionName = pathParts[2];

  // 国のSEO設定を読み込む関数を定義（useCallbackで最適化）
  const loadSEOSettings = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('seo_settings')
        .select('*')
        .eq('country_code', countryCode.toLowerCase())
        .single();

      if (error) {
        console.error('Error loading SEO settings:', error);
        return;
      }

      if (data) {
        console.log('Loaded SEO settings:', data);
        setSeoSettings({
          title: data.title,
          description: data.description,
          ogTitle: data.og_title,
          ogDescription: data.og_description
        });
      }
    } catch (error) {
      console.error('Error loading SEO settings:', error);
    }
  }, [countryCode]); // countryCodeが変更されたときのみ関数を再生成

  useEffect(() => {
    const loadSettings = async () => {
      try {
        if (regionName) {
          console.log('🔄 Loading settings for region:', regionName);
          const regionSEO = await loadRegionSEOSettings(countryCode, regionName);
          
          if (regionSEO) {
            console.log('✅ Applied region SEO settings');
            setSeoSettings(regionSEO);
          } else {
            console.log('⚠️ Falling back to country settings');
            await loadSEOSettings();
          }
        } else {
          console.log('🔄 Loading country settings');
          await loadSEOSettings();
        }
      } catch (error) {
        console.error('❌ Error in loadSettings:', error);
        setSeoSettings(DEFAULT_SEO_SETTINGS);
      }
    };

    loadSettings();
  }, [countryCode, regionName, loadSEOSettings]);

  useEffect(() => {
    loadOgImage();
  }, [countryCode]);

  const loadOgImage = async () => {
    try {
      const { data, error } = await supabase
        .from('seo_images')
        .select('image_url')
        .eq('country_code', country)
        .single();

      if (error) return;
      if (data) setOgImageUrl(data.image_url);
    } catch (error) {
      console.error('Error loading OG image:', error);
    }
  };

  console.log('Current SEO settings:', seoSettings);

  // 言語コードを取得
  const languageCode = getLanguageFromCountry(countryCode);

  useEffect(() => {
    // SEOタグが正しく適用されているか確認
    console.log('Current HTML lang attribute:', document.documentElement.lang);
    console.log('Current title:', document.title);
  }, [seoSettings, languageCode]);

  useEffect(() => {
    console.log('SEO Component rendered:', {
      countryCode,
      regionName,
      seoSettings
    });
  }, [countryCode, regionName, seoSettings]);

  return (
    <Helmet prioritizeSeoTags>
      <html lang={languageCode} />
      <title>{seoSettings.title}</title>
      <meta name="description" content={seoSettings.description} />
      
      {/* canonical URLの設定を修正 */}
      <link 
        rel="canonical" 
        href={`https://doppi.ai/${country}${regionName ? `/${regionName}` : ''}`} 
      />
      
      {/* hreflang タグの設定 */}
      {Object.entries(SUPPORTED_COUNTRIES).map(([code, path]) => (
        <link 
          key={code}
          rel="alternate" 
          hrefLang={getLanguageFromCountry(code)}
          href={`https://doppi.ai/${path}${regionName ? `/${regionName}` : ''}`}
        />
      ))}
      <link 
        rel="alternate" 
        hrefLang="x-default" 
        href={`https://doppi.ai/us${regionName ? `/${regionName}` : ''}`} 
      />
      
      {/* OGP */}
      <meta property="og:title" content={seoSettings.ogTitle} />
      <meta property="og:description" content={seoSettings.ogDescription} />
      <meta 
        property="og:url" 
        content={`https://doppi.ai/${country}${regionName ? `/${regionName}` : ''}`} 
      />
      <meta property="og:type" content="website" />
      {ogImageUrl && <meta property="og:image" content={ogImageUrl} />}
      
      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seoSettings.ogTitle} />
      <meta name="twitter:description" content={seoSettings.ogDescription} />
      {ogImageUrl && <meta name="twitter:image" content={ogImageUrl} />}
      
      {/* 追加のメタタグ */}
      <meta name="robots" content="index, follow" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <meta name="theme-color" content="#007AFF" />
      
      {/* 構造化データの追加 */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": seoSettings.title,
          "description": seoSettings.description,
          "image": ogImageUrl,
          "address": {
            "@type": "PostalAddress",
            "addressCountry": country.toUpperCase(),
            "addressRegion": regionName
          },
          "url": `https://doppi.ai/${country}${regionName ? `/${regionName}` : ''}`
        })}
      </script>
      
      {children}
    </Helmet>
  );
} 