import { useNavigate, useParams } from 'react-router-dom';
import './ConsolePage.scss';
import { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { getInstructions } from '../utils/conversation_config';
import { WavRenderer } from '../utils/wav_renderer';
import { supabase } from '../lib/supabase';
import { useLocation } from 'react-router-dom';
import { getAssistants } from '../api/assistants';
import { AssistantDataType } from '../types/Assistant';
import { User } from '../types/User';
import { useUser, useClerk } from '@clerk/clerk-react';
import { useTranslation } from '../hooks/useTranslation';
import i18n from '../i18n';
import { getCountryFlag, getCountryPath } from '../utils/country';
import { CountryProps } from '../types/props';
import { shortenId } from '../utils/id';
import React from 'react';
import { ReferralHistory } from '../types/Referral';
import { Heart } from 'react-feather';
import { getCountryFromIP } from '../utils/geolocation';
import { Link } from 'react-router-dom';

interface Conversation {
  role: string;
  text: string;
}

interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

interface ConversationMemory {
  id: number;
  content: string;
  created_at: string;
  timezone?: string;
}

interface Mode {
  id: string;
  code: string;
  name: string;
  description?: string;
  special: boolean;
  translations?: {
    [key: string]: {
      name: string;
      description?: string;
    }
  };
}

const countries = [
  { code: 'JP', name: 'Japan' },
  { code: 'US', name: 'United States' }
];

interface ConsolePageProps extends CountryProps {
  isTestMode?: boolean;
  isEnglishMode?: boolean;
  isNonVaccineMode?: boolean;
  isSokaMode?: boolean;
  isOzenMode?: boolean;
  isChristianMode?: boolean;
  isWelcomeMode?: boolean;
  isMiseMode?: boolean; // 追加
  directAssistantId?: boolean;
}

interface CoinTransaction {
  amount: number;
  transaction_type: string;
  description: string;
  created_at: string;
}

const cleanupResources = async (resources: {
  wavRecorder?: WavRecorder;
  wavStreamPlayer?: WavStreamPlayer;
  client?: RealtimeClient;
  audioRef?: React.RefObject<HTMLAudioElement>;
}) => {
  if (resources.wavRecorder && resources.wavRecorder.recording) {
    await resources.wavRecorder.end();
  }

  if (resources.wavStreamPlayer) {
    await resources.wavStreamPlayer.interrupt();
  }

  if (resources.client) {
    await resources.client.disconnect();
    resources.client.reset();
  }

  if (resources.audioRef?.current) {
    resources.audioRef.current.pause();
    resources.audioRef.current.src = '';
    resources.audioRef.current.load();
  }
};

export function ConsolePage({ 
  countryCode,
  directAssistantId = false,
  isTestMode = false,
  isEnglishMode = false,
  isNonVaccineMode = false,
  isSokaMode = false,
  isOzenMode = false,
  isChristianMode = false,
  isWelcomeMode = false,
  isMiseMode = false // 追加
}: ConsolePageProps) {
  const { assistantId, regionName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isLoaded: isUserLoaded } = useUser();
  const { signOut } = useClerk();
  const [userData, setUserData] = useState<User | null>(null);
  const [assistantData, setAssistantData] = useState<AssistantDataType | null>(
    location.state?.assistant || null
  );
  const [pastContent, setPastContent] = useState('');
  
  // modesを保持
  const [modes, setModes] = useState<Mode[]>([]);
  // 選択中のモード
  const [activeMode, setActiveMode] = useState<string | null>(null);
  
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  
  // 全アシスタントを初回にロードし、ス納す
  const [allAssistants, setAllAssistants] = useState<AssistantDataType[]>([]);
  // 現在表示中のフィルタリング済みアシスタント
  const [assistants, setAssistants] = useState<AssistantDataType[]>([]);

  const [countdown, setCountdown] = useState<number | null>(null);
  const [conversationHistory, setConversationHistory] = useState<Array<{
    role: string;
    text: string;
    timestamp: string;
  }>>([]);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { t } = useTranslation(user?.id);
  const [userCoins, setUserCoins] = useState<number>(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isMusicInitialized, setIsMusicInitialized] = useState(false);
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const apiKey = process.env.REACT_APP_OPENAI_API_KEY || '';
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient({
      apiKey: apiKey,
      dangerouslyAllowAPIKeyInBrowser: true,
    })
  );
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [deviceType, setDeviceType] = useState<'PC' | 'Mobile'>('PC');
  const [isPWA, setIsPWA] = useState(false);
  const conversationHistoryRef = useRef<Array<{
    role: string;
    text: string;
    timestamp: string;
  }>>([]);
  useEffect(() => {
    conversationHistoryRef.current = conversationHistory;
  }, [conversationHistory]);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);
  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);
  const [showCallAnimation, setShowCallAnimation] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  const currentMode = (() => {
    if (isTestMode) return 'test';
    if (isEnglishMode) return 'english';
    if (isWelcomeMode) return 'welcome';
    return 'normal';
  })();

  const [isAISpeaking, setIsAISpeaking] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isMypageOpen, setIsMypageOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showSplash, setShowSplash] = useState(true);
  const [showDateButton, setShowDateButton] = useState(false);
  const [memories, setMemories] = useState<ConversationMemory[]>([]);
  const [hasCalled, setHasCalled] = useState(false);
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingNickname, setEditingNickname] = useState('');
  const [editingLanguage, setEditingLanguage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [saveStatus, setSaveStatus] = useState<{
    type: 'success' | 'error';
    message: string;
  } | null>(null);

  const [referralCode, setReferralCode] = useState<string>('');
  const [inputReferralCode, setInputReferralCode] = useState('');
  const [referralHistory, setReferralHistory] = useState<ReferralHistory>({
    referrals_made: [],
    referred_by: undefined
  });
  const [referralError, setReferralError] = useState('');
  const [coinTransactions, setCoinTransactions] = useState<CoinTransaction[]>([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  
  // 回ロード時のみ使用
  const [isLoadingAssistants, setIsLoadingAssistants] = useState(true);
  
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteAssistantIds, setFavoriteAssistantIds] = useState<string[]>([]);
  
  let countdownTimerRef = useRef<NodeJS.Timeout | null>(null);
  const coinCheckTimerRef = useRef<NodeJS.Timeout | null>(null);

  const [conversationStartTime, setConversationStartTime] = useState<number | null>(null);
  const [elapsedSeconds, setElapsedSeconds] = useState<number>(0);
  const [displayCoins, setDisplayCoins] = useState<number>(userCoins);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  useEffect(() => {
    setDisplayCoins(userCoins);
  }, [userCoins]);

  const fetchUserCoins = async () => {
    try {
      if (!userData?.id) {
        return 0;
      }
      const { data, error } = await supabase
        .from('user_coins')
        .select('coins')
        .eq('user_id', userData.id)
        .single();

      if (error) {
        return 0;
      }
      const coins = data?.coins || 0;
      setUserCoins(coins);
      return coins;
    } catch {
      return 0;
    }
  };

  const calculateCoinUsage = (totalSec: number): number => {
    let coinCount = 0;
    let thresholds: number[] = [];

    for (let n = 0; n < 1000; n++) {
      const t10 = 10 + n * 60;
      const t40 = 40 + n * 60;
      if (t10 <= totalSec) {
        thresholds.push(t10);
      } else {
        break;
      }
      if (t40 <= totalSec) {
        thresholds.push(t40);
      } else {
      }
      if (t10 > totalSec && t40 > totalSec) {
        break;
      }
    }

    coinCount = thresholds.length;
    return coinCount;
  };

  const finalizeCoinUsage = async (totalSec: number) => {
    if (!userData?.id) return;
    const userId = userData.id;

    const coinsToConsume = calculateCoinUsage(totalSec);
    if (coinsToConsume <= 0) {
      return;
    }

    try {
      const { data: userCoinsData, error: fetchError } = await supabase
        .from('user_coins')
        .select('coins, expires_at')
        .eq('user_id', userId)
        .single();

      if (fetchError) throw fetchError;
      if (!userCoinsData) return;

      const now = new Date();
      let availableCoins = userCoinsData.coins;
      const hasExpiredCoins = userCoinsData.expires_at && new Date(userCoinsData.expires_at) < now;

      if (hasExpiredCoins) {
        await supabase.rpc('record_expired_coins', { user_id_input: userId });
        const { data: updatedCoinsData } = await supabase
          .from('user_coins')
          .select('coins')
          .eq('user_id', userId)
          .single();
        if (!updatedCoinsData) return;
        availableCoins = updatedCoinsData.coins;
      }

      if (availableCoins < coinsToConsume) {
        return;
      }

      const newCoinBalance = availableCoins - coinsToConsume;
      const { error: updateError } = await supabase
        .from('user_coins')
        .update({ coins: newCoinBalance })
        .eq('user_id', userId);
      if (updateError) throw updateError;

      const { error: transactionError } = await supabase
        .from('coin_transactions')
        .insert({
          user_id: userId,
          amount: -coinsToConsume,
          transaction_type: 'usage',
          description: `通話利用(${totalSec}秒)`
        });
      if (transactionError) console.error('Failed to record transaction:', transactionError);

      setUserCoins(newCoinBalance);

    } catch (err) {
      console.error('Error in finalizeCoinUsage:', err);
      return;
    }
  };

  useEffect(() => {
    const initAudio = async () => {
      if (audioRef.current) {
        const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        await audioContext.resume();
        audioRef.current.load();
      }
    };
    initAudio();
  }, []);

  const initializeBackgroundMusic = async () => {
    if (!audioRef.current) return;
    if (audioContext?.state === 'suspended') {
      await audioContext.resume();
    }
    audioRef.current.currentTime = 0;
    audioRef.current.volume = 0.3;
    audioRef.current.loop = true;
    audioRef.current.muted = false;
    await new Promise((resolve) => {
      audioRef.current!.addEventListener('canplaythrough', resolve, { once: true });
      audioRef.current!.load();
    });
    const playPromise = audioRef.current.play();
    if (playPromise !== undefined) {
      await playPromise;
    }
  };

  const [showMicDeniedPopup, setShowMicDeniedPopup] = useState(false);

  const checkMicrophonePermission = async (): Promise<boolean> => {
    console.log('マイクのアクセスを許可をしないと会話ができません。');
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      return true;
    } catch (err) {
      console.error('マイクアクセス拒否:', err);
      setShowMicDeniedPopup(true);
      
      // 特別モードの場合、対応するパスに戻る
      if (isSokaMode) {
        navigate(`/${countryCode.toLowerCase()}/soka`);
      } else if (isChristianMode) {
        navigate(`/${countryCode.toLowerCase()}/christian`);
      } else if (isOzenMode) {
        navigate(`/${countryCode.toLowerCase()}/ozen`);
      } else if (isMiseMode) {
        navigate(`/${countryCode.toLowerCase()}/mise`);
      }
      
      return false;
    }
    return true; // 追加: 明示的なreturn文
  };

  // 追加: 通話開始が既に行われたかを追跡するフラグ
  const conversationInitiatedRef = useRef(false);

  const connectConversation = async () => {
    if (isConnecting || conversationInitiatedRef.current) return;
    setIsConnecting(true);

    try {
      const hasMicPermission = await checkMicrophonePermission();
      if (!hasMicPermission) {
        setIsConnecting(false);
        setShowCallAnimation(false);
        return;
      }

      setShowCallAnimation(true);
      await wavRecorderRef.current.begin();
      await wavStreamPlayerRef.current.connect();
      await new Promise(resolve => setTimeout(resolve, 3000));
      setShowCallAnimation(false);
      
      if (currentMode === 'normal') {
        await initializeBackgroundMusic();
      }
      
      await clientRef.current.connect();
      setIsConnected(true);
      setConversationStartTime(Date.now());

      // 変更: 初回のみユーザーメッセージを送信
      if (!conversationInitiatedRef.current) {
        conversationInitiatedRef.current = true;
        await clientRef.current.sendUserMessageContent([
          {
            type: 'input_text',
            text: countryCode === 'JP' ? 'こんにちは' : 'hello',
          },
        ]);
      }

      if (clientRef.current.getTurnDetectionType() === 'server_vad') {
        await wavRecorderRef.current.record((data) => {
          if (
            clientRef.current.isConnected() &&
            !(currentMode === 'english' && isMutedRef.current)
          ) {
            clientRef.current.appendInputAudio(data.mono);
          }
        });
      }
    } catch (err) {
      console.error('Error in connectConversation:', err);
      setIsConnecting(false);
      setShowCallAnimation(false);
      alert('error');
    }
  };

  const handleSelectAssistant = (assistant: AssistantDataType) => {
    if (!assistant.country || !assistant.id) {
      return;
    }
    const countryPath = assistant.country.toLowerCase();
    const shortId = shortenId(assistant.id);
    navigate(`/${countryPath}/${shortId}`);
  };

  useEffect(() => {
    if (!isUserLoaded) return;
    if (!user?.id) {
      setUserData(null);
      return;
    }

    const fetchUserData = async () => {
      try {
        const { data, error } = await supabase
          .from('users')
          .select('*')
          .eq('clerk_id', user.id)
          .single();

        if (error) throw error;
        setUserData(data);
      } catch (err) {
        console.error('ユーザーデータの取得エラー:', err);
        setUserData(null);
      }
    };

    fetchUserData();
  }, [user, isUserLoaded]);

  const initializeVideoPlayback = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.play().catch(() => {});
    }
  }, []);

  useEffect(() => {
    const handleUserInteraction = () => {
      initializeVideoPlayback();
      document.removeEventListener('click', handleUserInteraction);
    };
    document.addEventListener('click', handleUserInteraction);
    return () => {
      document.removeEventListener('click', handleUserInteraction);
    };
  }, [initializeVideoPlayback]);

  const fetchMemories = async () => {
    if (!assistantData?.id) return;
    
    try {
      let query = supabase
        .from('memories')
        .select('*')
        .eq('assistant_id', assistantData.id)
        .order('created_at', { ascending: false });

      if (userData?.id) {
        query = query.eq('user_id', userData.id);
      } else {
        query = query.is('user_id', null);
      }

      const { data, error } = await query;

      if (error) {
        console.error('Error fetching memories:', error);
        setErrorMessage('Failed to load conversation history');
        return;
      }

      setMemories(data || []);
    } catch (error) {
      console.error('Error in fetchMemories:', error);
      setErrorMessage('An error occurred while loading conversation history');
    }
  };

  const toggleHistory = async () => {
    if (!isHistoryOpen) {
      await fetchMemories();
    }
    setIsHistoryOpen(prev => !prev);
  };

  const [errorMessage, setErrorMessage] = useState<string>('');

  const saveConversation = async (conversations: Array<{ role: string; text: string }>) => {
    if (!assistantData?.id) return;

    try {
      // 既存の会話を取得
      const { data: existingMemories } = await supabase
        .from('memories')
        .select('id')
        .eq('user_id', userData?.id || null)
        .eq('assistant_id', assistantData.id)
        .order('created_at', { ascending: false })
        .limit(1);

      // 既存の会話がある場合は削除
      if (existingMemories && existingMemories.length > 0) {
        await supabase
          .from('memories')
          .delete()
          .eq('id', existingMemories[0].id);
      }

      // 新しい会話を保存
      const { error } = await supabase
        .from('memories')
        .insert([
          {
            user_id: userData?.id || null,
            assistant_id: assistantData.id,
            content: JSON.stringify(conversations)
          }
        ]);

      if (error) {
        console.error('Error saving to memories:', error);
        setErrorMessage('会話の保存に失敗しました');
      }
    } catch (error) {
      console.error('Error in saveConversation:', error);
      setErrorMessage('会話の保存中にエラーが発生しました');
    }
  };

  const handleMypageClick = () => {
    navigate(`/${countryCode.toLowerCase()}/mypage`);
  };

  const handleCloseMypage = () => {
    setIsMypageOpen(false);
  };

  useEffect(() => {
    if (!showSplash) {
      setIsLoading(false);
    }
  }, [showSplash]);

  // 追加: 通話終了時にフラグをリセット
  const disconnectConversation = async () => {
    await cleanupResources({
      wavRecorder: wavRecorderRef.current,
      wavStreamPlayer: wavStreamPlayerRef.current,
      client: clientRef.current,
      audioRef
    });
    setIsConnected(false);
    setShowCallAnimation(false);
    setConversationHistory([]);
    setItems([]);
    setRealtimeEvents([]);
    setShowDateButton(false);
    setIsAISpeaking(false);
    setVideoUrl(null);
    setHasCalled(true);
    if (countdownTimerRef.current) {
      clearInterval(countdownTimerRef.current);
      countdownTimerRef.current = null;
      setCountdown(null);
    }

    // フラグをリセット
    conversationInitiatedRef.current = false;

    // 特別モードの場合、対応するパスに戻る
    if (isSokaMode) {
      navigate(`/${countryCode.toLowerCase()}/soka`);
    } else if (isChristianMode) {
      navigate(`/${countryCode.toLowerCase()}/christian`);
    } else if (isOzenMode) {
      navigate(`/${countryCode.toLowerCase()}/ozen`);
    } else if (isMiseMode) {
      navigate(`/${countryCode.toLowerCase()}/mise`);
    }

    return true;
  };

  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  useEffect(() => {
    let isLoaded = true;
    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;
    const render = () => {
      if (!isLoaded) return;
      if (clientCanvas) {
        if (!clientCanvas.width || !clientCanvas.height) {
          clientCanvas.width = clientCanvas.offsetWidth;
          clientCanvas.height = clientCanvas.offsetHeight;
        }
        clientCtx = clientCtx || clientCanvas.getContext('2d');
        if (clientCtx) {
          clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
          const values = wavRecorder.recording
            ? wavRecorder.getFrequencies('voice').values
            : new Float32Array([0]);
          WavRenderer.drawBars(
            clientCanvas,
            clientCtx,
            values,
            '#0099ff',
            10,
            0,
            8
          );
        }
      }
      if (serverCanvas) {
        if (!serverCanvas.width || !serverCanvas.height) {
          serverCanvas.width = serverCanvas.offsetWidth;
          serverCanvas.height = serverCanvas.offsetHeight;
        }
        serverCtx = serverCtx || serverCanvas.getContext('2d');
        if (serverCtx) {
          serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
          const values = wavStreamPlayer.analyser
            ? wavStreamPlayer.getFrequencies('voice').values
            : new Float32Array([0]);
          WavRenderer.drawBars(
            serverCanvas,
            serverCtx,
            values,
            '#009900',
            10,
            0,
            8
          );
        }
      }
      window.requestAnimationFrame(render);
    };
    render();
    return () => {
      isLoaded = false;
    };
  }, []);

  const instructions = useMemo(() => {
    if (!assistantData) return '';
    const baseInstruction = assistantData.instruction.includes('過去の会話:') 
      ? assistantData.instruction 
      : `${assistantData.instruction}\n過去の会話:\n${pastContent}`;
    const config = {
      instruction: baseInstruction,
      character_name: assistantData.display_name || 'アシスタント',
      user_name: userData?.nickname || 'ゲスト',
      personality: assistantData.personality || '',
      speaking_style: assistantData.speaking_style || '',
      past_content: pastContent
    };
    return getInstructions(config);
  }, [assistantData, userData, pastContent]);

  useEffect(() => {
    clientRef.current.updateSession({ instructions: instructions });
    clientRef.current.updateSession({ input_audio_transcription: { model: 'whisper-1' } });
    clientRef.current.updateSession({ turn_detection: { type: 'server_vad' } });
    clientRef.current.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
      setRealtimeEvents((realtimeEvents) => {
        const lastEvent = realtimeEvents[realtimeEvents.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          lastEvent.count = (lastEvent.count || 0) + 1;
          return realtimeEvents.slice(0, -1).concat(lastEvent);
        } else {
          return realtimeEvents.concat(realtimeEvent);
        }
      });
    });

    clientRef.current.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayerRef.current.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await clientRef.current.cancelResponse(trackId, offset);
      }
    });

    clientRef.current.on('conversation.updated', async ({ item, delta }: any) => {
      const items = clientRef.current.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayerRef.current.add16BitPCM(delta.audio, item.id);
      }
      
      // アシスタントの応答が完了した時に会話を保存
      if (item.status === 'completed' && 
          item.formatted?.audio?.length && 
          item.role === 'assistant') {
        const conversations = items
          .filter(item => item.formatted?.text || item.formatted?.transcript) // 空のメッセージを除外
          .map(item => ({
            role: item.role || '',
            text: item.formatted?.text || item.formatted?.transcript || ''
          }));

        if (conversations.length > 0) {
          await saveConversation(conversations);
        }
      }
      
      setItems(items);
    });

    setItems(clientRef.current.conversation.getItems());
    clientRef.current.updateSession({ 
      voice: (assistantData?.voice as "alloy" | "ash" | "ballad" | "coral" | "echo" | "sage" | "shimmer" | "verse") 
    });
    return () => {
      clientRef.current.reset();
    };
  }, [instructions]);

  useEffect(() => {
    return () => {
      cleanupResources({
        wavRecorder: wavRecorderRef.current,
        wavStreamPlayer: wavStreamPlayerRef.current,
        client: clientRef.current,
        audioRef
      });
      if (countdownTimerRef.current) {
        clearInterval(countdownTimerRef.current);
      }
      setItems([]);
      setRealtimeEvents([]);
      setConversationHistory([]);
    };
  }, []);

  useEffect(() => {
    const fetchPastConversationsFunc = async () => {
      if (!assistantData) return;
      if (isTestMode || isWelcomeMode) {
        setPastContent('');
        return;
      }
      if (userData?.id) {
        const result = await supabase
          .from('memories')
          .select('content')
          .eq('user_id', userData.id)
          .eq('assistant_id', assistantData.id)
          .order('created_at', { ascending: false })
          .limit(20);
        const data = result.data || [];
        const formattedContent = data
          .map(memory => {
            const conversations: Array<{ role: string; text: string }> = JSON.parse(memory.content);
            return conversations
              .map(conv => `${conv.role === 'assistant' ? assistantData.display_name : 'ユーザー'}: ${conv.text}`)
              .join('\n');
          })
          .join('\n\n');
        setPastContent(formattedContent);
      }
    };
    fetchPastConversationsFunc();
  }, [isTestMode, userData, assistantData]);

  const [isVideoAnimating, setIsVideoAnimating] = useState(false);
  const [error, setError] = useState<string>('');

  const fetchUserCoinsInit = async () => {
    if (user) {
      await fetchUserCoins();
    }
  };
  useEffect(() => {
    fetchUserCoinsInit();
  }, [user]);

  const handleEndCall = async () => {
    try {
      if (isConnected) {
        // 音声関連のリソースをクリーンアップ
        if (wavRecorderRef.current && wavRecorderRef.current.recording) {
          await wavRecorderRef.current.end();
        }

        if (wavStreamPlayerRef.current) {
          await wavStreamPlayerRef.current.interrupt();
        }

        // クライアントの切断
        if (clientRef.current) {
          await clientRef.current.disconnect();
          clientRef.current.reset();
        }

        // オーディオ要素のクリーンアップ
        if (audioRef?.current) {
          audioRef.current.pause();
          audioRef.current.src = '';
          audioRef.current.load();
        }

        // 会話時間に基づいてコインを消費
        if (conversationStartTime) {
          const totalSeconds = Math.floor((Date.now() - conversationStartTime) / 1000);
          await finalizeCoinUsage(totalSeconds);
        }

        // 状態のリセット
        setIsConnected(false);
        setShowCallAnimation(false);
        setConversationStartTime(null);
        setElapsedSeconds(0);
        setItems([]);
        setRealtimeEvents([]);
        setConversationHistory([]);

        // アシスタントのモードに基づいて遷移先を決定
        if (assistantData?.mode_id) {
          const mode = modes.find(m => m.id === assistantData.mode_id);
          if (mode?.code === 'soka') {
            navigate(`/${countryCode.toLowerCase()}/soka`);
            window.location.reload();
            return;
          } else if (mode?.code === 'mise') {
            navigate(`/${countryCode.toLowerCase()}/mise`);
            window.location.reload();
            return;
          }
        }

        // 通常の遷移処理
        if (location.state?.returnPath) {
          navigate(location.state.returnPath);
        }
        window.location.reload();
      }
    } catch (err) {
      console.error('Error in handleEndCall:', err);
      // エラーが発生しても強制的にリロード
      window.location.reload();
    }
  };

  const [voiceActivityCounter, setVoiceActivityCounter] = useState(0);

  useEffect(() => {
    if (!isConnected || !assistantData) return;

    const interval = setInterval(() => {
      if (!wavStreamPlayerRef.current) return;

      const frequencies = wavStreamPlayerRef.current.getFrequencies('voice').values;
      let sum = 0;
      for (let i = 0; i < frequencies.length; i++) {
        sum += frequencies[i] * frequencies[i];
      }
      const rms = Math.sqrt(sum / frequencies.length);
      const threshold = 0.05;

      if (rms > threshold) {
        setVoiceActivityCounter(prev => Math.min(prev + 1, 10));
      } else {
        setVoiceActivityCounter(prev => Math.max(prev - 1, 0));
      }

      const speakingThreshold = 3;
      const silentThreshold = 3;
      if (voiceActivityCounter >= speakingThreshold && !isAISpeaking) {
        setIsAISpeaking(true);
      } else if (voiceActivityCounter <= silentThreshold && isAISpeaking) {
        setIsAISpeaking(false);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [assistantData, isConnected, isAISpeaking, voiceActivityCounter]);

  useEffect(() => {
    const handleLanguageChange = (event: CustomEvent<{ language: string }>) => {
      const newLanguage = event.detail.language;
      i18n.changeLanguage(newLanguage);
      if (assistantData?.instruction && clientRef.current) {
        const updatedInstructions = getInstructions({
          instruction: assistantData.instruction,
          character_name: assistantData.display_name || 'アシスタント',
          user_name: userData?.nickname || 'ゲスト',
          personality: assistantData.personality || '',
          speaking_style: assistantData.speaking_style || '',
          language: newLanguage
        });
        clientRef.current.updateSession({ instructions: updatedInstructions });
        setForceUpdate((prev: boolean) => !prev);
        window.location.reload();
      }
    };
    window.addEventListener('languageChanged', handleLanguageChange as EventListener);
    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange as EventListener);
    };
  }, [assistantData, userData]);

  // modesをfetch
  useEffect(() => {
    const fetchModes = async () => {
      const { data: modesData, error } = await supabase
        .from('modes')
        .select(`
          *,
          translations:mode_translations(
            country_code,
            name,
            description
          )
        `)
        .order('name');

      if (error) {
        console.error('Failed to fetch modes:', error);
        return;
      }

      // 翻訳データを整形
      const formattedModes = modesData?.map(mode => ({
        ...mode,
        translations: mode.translations?.reduce((acc: any, trans: any) => {
          acc[trans.country_code] = {
            name: trans.name,
            description: trans.description
          };
          return acc;
        }, {})
      })) || [];

      setModes(formattedModes);
    };
    fetchModes();
  }, []);

  const sortedCountries = useMemo(() => {
    if (!userData?.country) return countries;
    return [
      ...countries.filter(country => country.code === userData.country),
      ...countries.filter(country => country.code !== userData.country)
    ];
  }, [userData?.country]);

  const handleCountrySelect = useCallback((selectedCountryCode: string) => {
    if (selectedCountry === selectedCountryCode) return;
    setSelectedCountry(selectedCountryCode);
    const newCountryPath = getCountryPath(selectedCountryCode);
    
    // 現在のURLからクエリパラメータを取得
    const currentUrl = new URL(window.location.href);
    const queryParams = currentUrl.search;
    
    // ユーザー状態を保持したまま遷移
    if (user) {
      navigate(`${newCountryPath}${queryParams}`);
    } else {
      window.location.href = `${newCountryPath}${queryParams}`;
    }
  }, [selectedCountry, user, navigate]);

  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdown = document.querySelector('.country-selector');
      if (dropdown && !dropdown.contains(event.target as Node)) {
        setIsCountryDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleAllModesClick = () => {
    setActiveMode(null);
    navigate(`/${countryCode.toLowerCase()}`);
  };

  const handleModeClick = (mode: Mode) => {
    setActiveMode(mode.id);
    navigate(`/${countryCode.toLowerCase()}/${mode.code.toLowerCase()}`);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };

  useEffect(() => {
    if (userData) {
      setEditingNickname(userData.nickname || '');
      setEditingLanguage(userData.language || 'ja');
    }
  }, [userData]);

  const handleUpdateProfile = async () => {
    if (!user?.id) return;
    setIsUpdating(true);
    await supabase
      .from('users')
      .update({ 
        nickname: editingNickname,
        language: editingLanguage,
        updated_at: new Date().toISOString()
      })
      .eq('clerk_id', user.id);
    setUserData(prev => ({
      ...prev!,
      nickname: editingNickname,
      language: editingLanguage
    }));
    setIsEditing(false);
    setIsUpdating(false);
  };

  useEffect(() => {
    if (saveStatus) {
      const timer = setTimeout(() => {
        setSaveStatus(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [saveStatus]);

  useEffect(() => {
    const locationState = location.state as { 
      assistant: AssistantDataType; 
      autoConnect: boolean;
      startCall: boolean;
    } | null;
    if (!locationState) return;
    const { assistant: assistantFromState, autoConnect, startCall } = locationState;
    const clearLocationState = () => {
      const currentPath = window.location.pathname;
      window.history.replaceState(null, '', currentPath);
    };
    clearLocationState();
    if (autoConnect && startCall && assistantFromState && !isConnected && !isConnecting) {
      const timer = setTimeout(async () => {
        setAssistantData(assistantFromState);
        await connectConversation();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, []);

  const fetchReferralCode = async (): Promise<void> => {
    if (!userData?.id) return;
    const { data: existingCode } = await supabase
      .from('referral_codes')
      .select('code')
      .eq('user_id', userData.id)
      .single();
    if (existingCode) {
      setReferralCode(existingCode.code);
    } else {
      const { data: newCode } = await supabase
        .rpc('create_referral_code', {
          user_id: userData.id
        });
      if (newCode) {
        setReferralCode(newCode);
      }
    }
  };

  useEffect(() => {
    const isIOSPWA = 
      ('standalone' in window.navigator) ||
      window.matchMedia('(display-mode: standalone)').matches;
    if (isIOSPWA && /iPad|iPhone|iPod/.test(navigator.userAgent)) {
      const element = document.querySelector('[data-component="ConsolePage"]');
      if (element) {
        element.classList.add('ios-pwa');
      }
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPageLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const fetchCoinTransactions = async () => {
    if (!user?.id) return;
    const { data: userDataObj } = await supabase
      .from('users')
      .select('id')
      .eq('clerk_id', user.id)
      .single();
    if (!userDataObj) return;
    const { data: transactions } = await supabase
      .from('coin_transactions')
      .select('amount, transaction_type, description, created_at')
      .eq('user_id', userDataObj.id)
      .order('created_at', { ascending: false })
      .limit(20);
    if (transactions) {
      setCoinTransactions(transactions);
    }
  };

  useEffect(() => {
    if (isMypageOpen && userData?.id) {
      fetchCoinTransactions();
    }
  }, [isMypageOpen, userData?.id]);

  // 1. ローディング状態の追加
  const [isAssistantsLoading, setIsAssistantsLoading] = useState(true);

  // ローディング状態を管理する変数を追加
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useEffect(() => {
    const fetchAssistants = async () => {
      setIsAssistantsLoading(true);
      setIsInitialLoading(true); // 初期ローディング状態を設定

      try {
        const assistantsData = await getAssistants(currentMode);
        
        // フィルタリング処理を行う前にデータを表示しない
        let filteredAssistants = [...assistantsData].filter(
          a => a.country?.toLowerCase() === countryCode.toLowerCase()
        );
        
        // 特別モードの場合
        if (isSokaMode || isChristianMode || isOzenMode || isMiseMode) {
          const modeCode = isSokaMode ? 'soka' 
            : isChristianMode ? 'christian' 
            : isOzenMode ? 'ozen' 
            : 'mise';
          
          const specialMode = modes.find(m => m.code === modeCode);
          if (specialMode) {
            filteredAssistants = filteredAssistants.filter(
              assistant => assistant.mode_id && assistant.mode_id === specialMode.id
            );
          }
        } else {
          // 通常モードの場合は、特別モードの��シスタントを除外
          const specialModeIds = modes
            .filter(m => m.special)
            .map(m => m.id);
          
          filteredAssistants = filteredAssistants.filter(
            assistant => assistant.mode_id && !specialModeIds.includes(assistant.mode_id)
          );

          if (activeMode) {
            filteredAssistants = filteredAssistants.filter(
              assistant => assistant.mode_id && assistant.mode_id === activeMode
            );
          }
        }

        // ユーザーの状態に基づくフィルタリング
        filteredAssistants = filteredAssistants.filter(assistant => {
          if (!user) {
            return assistant.role === 'welcome';
          }
          return assistant.role === 'normal';
        });

        // フィルタリング完了後にデータをセット
        setAllAssistants(filteredAssistants);
        setAssistants(filteredAssistants);
        
      } catch (error) {
        console.error('Failed to fetch assistants:', error);
        setAssistants([]);
      } finally {
        // 少し遅延を入れてローディング状態を解除
        setTimeout(() => {
          setIsAssistantsLoading(false);
          setIsInitialLoading(false);
        }, 1500); // 1.5秒の遅延を追加
      }
    };

    fetchAssistants();
  }, [isSokaMode, isChristianMode, isOzenMode, isMiseMode, currentMode, modes, activeMode, countryCode, user]);

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setDeviceType(isMobile ? 'Mobile' : 'PC');
    const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches 
      || (window.navigator as any).standalone 
      || document.referrer.includes('android-app://');
    setIsPWA(isInStandaloneMode);
  }, []);

  useEffect(() => {
    if (isConnected) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isConnected]);

  const handleStartCall = async () => {
    await connectConversation();
  };

  const checkFavoriteStatus = async () => {
    if (!userData?.id || !assistantData?.id) return;
    const { data } = await supabase
      .from('favorite_assistants')
      .select('id')
      .eq('user_id', userData.id)
      .eq('assistant_id', assistantData.id)
      .single();
    setIsFavorite(!!data);
  };

  const toggleFavorite = async () => {
    if (!userData?.id || !assistantData?.id) return;
    if (isFavorite) {
      await supabase
        .from('favorite_assistants')
        .delete()
        .eq('user_id', userData.id)
        .eq('assistant_id', assistantData.id);
      setIsFavorite(false);
    } else {
      await supabase
        .from('favorite_assistants')
        .insert([{
          user_id: userData.id,
          assistant_id: assistantData.id
        }]);
      setIsFavorite(true);
      if (clientRef.current && clientRef.current.isConnected()) {
        const message = {
          type: 'input_text' as const,
          text: 'お気に入り登録ありがとございます！とても嬉しいので、感情豊か喜びを現しながら礼を言ってください！なたの気持ちを全力で伝えね！'
        };
        await clientRef.current.sendUserMessageContent([message]);
      }
    }
  };

  const fetchFavoriteAssistants = useCallback(async () => {
    if (!user?.id) return;
    const { data: userDataObj } = await supabase
      .from('users')
      .select('id')
      .eq('clerk_id', user.id)
      .single();
    if (!userDataObj) return;
    const { data: favorites } = await supabase
      .from('favorite_assistants')
      .select('assistant_id')
      .eq('user_id', userDataObj.id);
    if (favorites) {
      setFavoriteAssistantIds(favorites.map(fav => fav.assistant_id));
    }
  }, [user?.id]);

  useEffect(() => {
    fetchFavoriteAssistants();
  }, [fetchFavoriteAssistants]);

  useEffect(() => {
    if (assistantData) {
      checkFavoriteStatus();
    }
  }, [assistantData, userData]);

  useEffect(() => {
    if (assistantData?.talkmovie_url && assistantData?.stopmovie_url) {
      const loadVideos = async () => {
        const currentVideo = isAISpeaking ? 
          assistantData.talkmovie_url : 
          assistantData.stopmovie_url;
        
        // currentVideoが存在する場合のみ処理を実行
        if (currentVideo) {
          const video = document.createElement('video');
          video.preload = 'metadata';
          video.src = currentVideo;  // currentVideoは必ず string 型
          video.addEventListener('loadedmetadata', () => {
            setIsVideoLoaded(true);
            video.src = '';
            video.load();
          }, { once: true });
          video.load();
        }
      };
      loadVideos();
    }
  }, [assistantData, isAISpeaking]);

  useEffect(() => {
    return () => {
      const videos = document.querySelectorAll('video');
      videos.forEach(video => {
        video.pause();
        video.src = '';
      });
      const images = document.querySelectorAll('img');
      images.forEach(img => {
        img.src = '';
      });
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.src = '';
        videoRef.current.load();
      }
    };
  }, []);

  useEffect(() => {
    const loadUserCoins = async () => {
      if (userData?.id) {
        const coins = await fetchUserCoins();
        setUserCoins(coins);
      }
    };
    loadUserCoins();
  }, [userData]);

  // 非ログインユーザーの通話時間を設定（秒）
  const GUEST_CALL_DURATION = 30;

  const calculateAvailableSeconds = (coins: number): number => {
    if (!user) {
      return GUEST_CALL_DURATION;
    }
    if (coins <= 0) return 0;
    return (coins * 30) + 10;
  };

  const formatRemainingTime = (seconds: number, coins: number) => {
    if (!user) {
      const remaining = Math.max(0, GUEST_CALL_DURATION - seconds);
      const mins = Math.floor(remaining / 60);
      const secs = remaining % 60;
      return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    }
    
    const totalAvailableSeconds = calculateAvailableSeconds(coins);
    const remainingSeconds = Math.max(0, totalAvailableSeconds - seconds);
    const mins = Math.floor(remainingSeconds / 60);
    const secs = remainingSeconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (!isConnected || !conversationStartTime) return;

    const timer = setInterval(() => {
      const now = Date.now();
      const elapsed = Math.floor((now - conversationStartTime) / 1000);
      setElapsedSeconds(elapsed);

      // 非ログインユーザ���の場合
      if (!user) {
        const remaining = GUEST_CALL_DURATION - elapsed;
        if (remaining <= 0) {
          clearInterval(timer);
          handleEndCall();
          window.location.reload();
          return;
        }
        // 残り10秒でプロンプトを表示
        if (remaining <= 10 && !showLoginPrompt) {
          setShowLoginPrompt(true);
        }
      } 
      // ログインユーザーの場合
      else {
        const totalAvailableSeconds = calculateAvailableSeconds(userCoins);
        if (elapsed >= totalAvailableSeconds) {
          clearInterval(timer);
          handleEndCall();
          window.location.reload();
        }
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [isConnected, conversationStartTime, user, userCoins, showLoginPrompt]);

  // モードタブの表示を制御
  const shouldShowModeTabs = !isSokaMode && !isChristianMode && !isOzenMode && !isMiseMode;

  // favoriteアイコンの色を変更
  const favoriteIconColor = '#4ECDC4';  // アクセントカラーと同じ色

  // 言に基いたボタンテキストを取得する関数
  const getAuthButtonText = (type: 'login' | 'signup') => {
    switch (countryCode) {
      case 'JP':
        return type === 'login' ? 'ログイン' : '新規登録';
      case 'KR':
        return type === 'login' ? '로그イン' : '회원가입';
      default:
        return type === 'login' ? 'Login' : 'Sign Up';
    }
  };

  // PWA状態の検出追加
  const [showPWAPrompt, setShowPWAPrompt] = useState(false);
  
  // PWA状モバイル判定を行effectを追加
  useEffect(() => {
    const checkPWAStatus = () => {
      const isStandalone = 
        window.matchMedia('(display-mode: standalone)').matches ||
        (window.navigator as any).standalone ||
        document.referrer.includes('ios-app://');
      
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      
      setShowPWAPrompt(isMobile && !isStandalone);
    };

    checkPWAStatus();
    
    // display-modeの変更を監視
    const mediaQuery = window.matchMedia('(display-mode: standalone)');
    const handler = () => checkPWAStatus();
    mediaQuery.addListener(handler);
    
    return () => mediaQuery.removeListener(handler);
  }, []);

  const [showInstallPopup, setShowInstallPopup] = useState(false);

  const getPWAPromptText = () => {
    switch (countryCode) {
      case 'JP':
        return {
          title: 'Bar Doppiのアプリをゲット',
          description: '音質が良くなったりサービスが快適なります',
          getButton: 'GET'
        };
      case 'KR':
        return {
          title: 'Bar Doppi 앱 다운로드',
          description: '음질이 향상되고 비스가 더욱 편리해집니다.',
          getButton: '받'
        };
      default:
        return {
          title: 'Get Bar Doppi App',
          description: 'Improved audio quality and better user experience.',
          getButton: 'GET'
        };
    }
  };

  const getInstallStepsText = () => {
    switch (countryCode) {
      case 'JP':
        return [
          '「共有」をタップしてださい',
          '「ホーム画面に追加」をタップしてください',
          'ホーム画面にBar Doppiのアプリが表示されます'
        ];
      case 'KR':
        return [
          '「공유」를 탭하세요',
          '「홈 화면에 추가」를 탭하세요',
          '홈 화면에 Bar Doppi 앱이 표시됩니다'
        ];
      default:
        return [
          'Tap "Share"',
          'Tap "Add to Home Screen"',
          'Bar Doppi app will appear on your home screen'
        ];
    }
  };

  const promptText = getPWAPromptText();
  const installSteps = getInstallStepsText();

  useEffect(() => {
    if (isConnected && !user && elapsedSeconds >= 30 && !showLoginPrompt) {
      setShowLoginPrompt(true);
    }
  }, [isConnected, user, elapsedSeconds, showLoginPrompt]);

  const handleLoginClick = async () => {
    try {
      // 通信を切断
      if (isConnected) {
        // 音声関連のリソースをクリーンアップ
        if (wavRecorderRef.current && wavRecorderRef.current.recording) {
          await wavRecorderRef.current.end();
        }

        if (wavStreamPlayerRef.current) {
          await wavStreamPlayerRef.current.interrupt();
        }

        // クライアントの切断
        if (clientRef.current) {
          await clientRef.current.disconnect();
          clientRef.current.reset();
        }

        // オーディオ要素のクリーンアップ
        if (audioRef?.current) {
          audioRef.current.pause();
          audioRef.current.src = '';
          audioRef.current.load();
        }

        // 状態のリセット
        setIsConnected(false);
        setShowCallAnimation(false);
        setConversationStartTime(null);
        setElapsedSeconds(0);
        setItems([]);
        setRealtimeEvents([]);
        setConversationHistory([]);
      }

      // 特別モードの場合は、そのモードのサインインページにリダイレクト
      let redirectPath;
      if (isSokaMode) {
        redirectPath = `/${countryCode.toLowerCase()}/soka/sign-in`;
      } else if (isChristianMode) {
        redirectPath = `/${countryCode.toLowerCase()}/christian/sign-in`;
      } else if (isOzenMode) {
        redirectPath = `/${countryCode.toLowerCase()}/ozen/sign-in`;
      } else if (isMiseMode) {
        redirectPath = `/${countryCode.toLowerCase()}/mise/sign-in`;
      } else {
        redirectPath = `/${countryCode.toLowerCase()}/sign-in`;
      }

      // 遷移とリロード
      navigate(redirectPath);
      window.location.reload();
    } catch (err) {
      console.error('Error in handleLoginClick:', err);
      // エラーが発生しても強制的にリロード
      window.location.reload();
    }
  };

  // 特別モードかどうかを判定する関数
  const isSpecialMode = () => {
    return isSokaMode || isChristianMode || isOzenMode || isMiseMode;
  };

  // 特別モードの場合のアシスタント表示用コンポーネントを修正
  const SpecialModeAssistant = ({ assistant }: { assistant: AssistantDataType }) => {
    const navigate = useNavigate();
    const handleStartChat = () => {
      if (!assistant.country || !assistant.id) {
        console.error('Assistant data is incomplete');
        return;
      }
      
      const countryPath = assistant.country.toLowerCase();
      const shortId = shortenId(assistant.id);
      navigate(`/${countryPath}/${shortId}`);
    };

    return (
      <>
        <div className="special-mode-assistant">
          <div className="assistant-content">
            <img 
              src={assistant.avatar_url} 
              alt={assistant.display_name} 
            />
            <div className="assistant-info">
              <h2 className="assistant-name">{assistant.display_name}</h2>
            </div>
          </div>
        </div>
        <button 
          className="floating-chat-button"
          onClick={handleStartChat}
        >
          話す
        </button>
      </>
    );
  };

  // タイムゾーン情報を保持するためのstate
  const [userTimezone, setUserTimezone] = useState<string>('');

  // コンポーネントマウント時にタイムゾーン情報を取得
  useEffect(() => {
    const detectTimezone = async () => {
      try {
        const { timezone } = await getCountryFromIP();
        setUserTimezone(timezone);
      } catch (error) {
        console.error('Error detecting timezone:', error);
        // デフォルトのタイムゾーンを設定
        setUserTimezone('America/New_York');
      }
    };

    detectTimezone();
  }, []);

  const saveMemory = async (content: string) => {
    if (!assistantData?.id) return;

    try {
      const { data, error } = await supabase
        .from('memories')
        .insert([
          {
            assistant_id: assistantData.id,
            user_id: userData?.id || null,
            content,
            timezone: userTimezone,
            created_at: new Date().toISOString()
          }
        ]);

      if (error) {
        console.error('Error saving to memories:', error);
        return;
      }

      // メモリーの保存が成功した場合、ローカルのメモリー一覧を更新
      if (data) {
        setMemories(prev => [...prev, data[0]]);
      }
    } catch (error) {
      console.error('Error in saveMemory:', error);
    }
  };

  // 例: ユーザーがメッセージを送信した際にメモリーを保存する部分
  const handleSendMessage = (message: string) => {
    // ... メッセージ送信ロジック ...
    
    // メモリーを保存
    saveMemory(message);
  };

  const getInitialInstructions = () => {
    if (!assistantData) return '';
    
    const baseInstruction = assistantData.instruction.includes('過去の会話:') 
      ? assistantData.instruction 
      : `${assistantData.instruction}\n過去の会話:\n${pastContent}`;

    return getInstructions({
      instruction: baseInstruction,
      character_name: assistantData.display_name || 'アシスタント',
      user_name: userData?.nickname || 'ゲスト',
      personality: assistantData.personality || '',
      speaking_style: assistantData.speaking_style || '',
      past_content: pastContent,
      language: userData?.language || 'ja',
      timezone: userTimezone
    });
  };

  // 通常モードへるためのハンドラーを追加
  const handleNormalModeClick = () => {
    navigate(`/${countryCode.toLowerCase()}`);
  };

  return (
    <>
      {isPageLoading && (
        <div className="page-loading-spinner">
          <div className="spinner" />
        </div>
      )}
      <div data-component="ConsolePage" className={`${isConnected ? 'call-active' : ''}`}>
        {showPWAPrompt && !isSpecialMode() && (
          <div className="pwa-prompt">
            <div className="pwa-prompt-content">
              <button 
                className="close-button"
                onClick={() => setShowPWAPrompt(false)}
                aria-label="閉じる"
              >
                <svg width="14" height="14" viewBox="0 0 14 14">
                  <path 
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              <div className="prompt-text">
                <span className="title">{promptText.title}</span>
                <span className="description">{promptText.description}</span>
              </div>
              <button 
                className="get-button"
                onClick={() => setShowInstallPopup(true)}
              >
                {promptText.getButton}
              </button>
            </div>
          </div>
        )}

        {showInstallPopup && (
          <div className="install-popup-overlay">
            <div className="install-popup">
              <button 
                className="close-button"
                onClick={() => setShowInstallPopup(false)}
                aria-label="閉じる"
              >
                <svg width="14" height="14" viewBox="0 0 14 14">
                  <path 
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              <div className="popup-content">
                <div className="install-steps">
                  {installSteps.map((step, index) => (
                    <div key={index} className="step">
                      <span className="number">{index + 1}</span>
                      <span className="text">
                        {index === 0 && (
                          <>
                            {step}
                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"/>
                              <polyline points="16 6 12 2 8 6"/>
                              <line x1="12" y1="2" x2="12" y2="15"/>
                            </svg>
                          </>
                        )}
                        {index !== 0 && step}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="arrow-pointer" />
              </div>
            </div>
          </div>
        )}
        {!isConnected && !isWelcomeMode && (
          <div className="content-top">
            <div className="content-title">
              {!isSpecialMode() && (
                <div className="logo">
                  Bar Doppi
                  <div className="subtitle">
                    {countryCode === 'JP' 
                      ? 'AIが接客する不思議なバー' 
                      : 'A mysterious, AI-tended bar'}
                  </div>
                </div>
              )}
              <div className="action-buttons">
                {!isSpecialMode() && (
                  <div className="country-selector">
                    <button 
                      className="current-country"
                      onClick={() => setIsCountryDropdownOpen(!isCountryDropdownOpen)}
                      aria-label="国を選択"
                    >
                      {getCountryFlag(selectedCountry || countryCode || 'JP')}
                      <span className={`arrow ${isCountryDropdownOpen ? 'open' : ''}`}>▼</span>
                    </button>
                    <div className={`country-dropdown ${isCountryDropdownOpen ? 'open' : ''}`}>
                      {sortedCountries.map(country => (
                        <div
                          key={country.code}
                          className={`country-option ${selectedCountry === country.code ? 'active' : ''}`}
                          onClick={() => {
                            handleCountrySelect(country.code);
                            setIsCountryDropdownOpen(false);
                          }}
                        >
                          <span className="country-flag">{getCountryFlag(country.code)}</span>
                          <span className="country-name">{country.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {isUserLoaded && (
                  user ? (
                    <button
                      className="mypage-button"
                      onClick={handleMypageClick}
                      aria-label="マページ"
                    >
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx="12" cy="7" r="4" />
                      </svg>
                    </button>
                  ) : (
                    <div className="auth-buttons">
                      <button 
                        className="sign-up-button"
                        onClick={handleLoginClick}  // 同じハンドラーを使用
                      >
                        {getAuthButtonText('signup')}
                      </button>
                      <button 
                        className="login-button"
                        onClick={handleLoginClick}  // 同じハンドラーを使用
                      >
                        {getAuthButtonText('login')}
                      </button>
                    </div>
                  )
                )}
              </div>
            </div>
            
            {/* モドタブ - 特別モードでない場合のみ表示 */}
            {!isSpecialMode() && (
              <div className="tabs-container">
                <div className="mode-tabs">
                  <button
                    className={`mode-tab ${activeMode === null ? 'active' : ''}`}
                    onClick={handleAllModesClick}
                  >
                    ALL
                  </button>
                  {modes
                    .filter(mode => !mode.special)
                    .map((mode) => {
                      const translation = mode.translations?.[countryCode.toUpperCase()];
                      const displayName = translation?.name || mode.name;
                      
                      return (
                        <button
                          key={mode.id}
                          className={`mode-tab ${activeMode === mode.id ? 'active' : ''}`}
                          onClick={() => handleModeClick(mode)}
                        >
                          {displayName}
                        </button>
                      );
                    })}
                </div>

                <div className="mode-description">
                  {countryCode === 'JP' 
                    ? '好きなAIを選んで会話を楽しもう！'
                    : 'Choose your favorite AI and enjoy the conversation!'}
                </div>
              </div>
            )}

            {/* 特別モードの場合の説明文 */}
            {isSpecialMode() && (
              <div className="mode-description special-mode">
                {isSokaMode && (
                  countryCode === 'JP' 
                    ? '創価カップルのAIカウンセラー'
                    : 'Special mode for Soka Gakkai members'
                )}
                {isChristianMode && (
                  countryCode === 'JP'
                    ? 'クリスチャンの方向けの特別なモードです'
                    : 'Special mode for Christians'
                )}
                {isOzenMode && (
                  countryCode === 'JP'
                    ? 'お禅の方向けの特別なモードです'
                    : 'Special mode for Zen practitioners'
                )}
                {isMiseMode && (
                  countryCode === 'JP'
                    ? '未接種カップルのAIカウンセラー'
                    : 'Special mode for unvaccinated users'
                )}
              </div>
            )}
          </div>
        )}
        <div className="content-wrapper">
          {!isConnected && assistantData?.avatar_url && !videoUrl && (
            <div className="avatar-video-container">
              <img
                src={`${assistantData.avatar_url}?quality=low&size=400`}
                className="avatar-image"
                loading="lazy"
              />
            </div>
          )}
          {!isConnected && (
            <div className={`avatar-selection ${isSpecialMode() ? 'special-mode' : ''}`}>
              {isInitialLoading ? (
                <div className="loading-assistants">
                  <div className="spinner" />
                </div>
              ) : (
                <>
                  {assistants.length === 0 && !isLoadingAssistants && (
                    <div className="no-assistant">
                      no assistant
                    </div>
                  )}
                  {isSpecialMode() ? (
                    <>
                      {assistants.length > 0 && (
                        <SpecialModeAssistant assistant={assistants[0]} />
                      )}
                      <button 
                        className="normal-mode-button"
                        onClick={handleNormalModeClick}
                      >
                        通常モード
                      </button>
                    </>
                  ) : (
                    // 常モードの場合は従来通りの表示
                    assistants.map((assistant) => {
                      if (!assistant.country || !assistant.id) {
                        return null;
                      }
                      const country = assistant.country as string;
                      const id = assistant.id as string;
                      return (
                        <div 
                          key={id}
                          className="avatar-item"
                          onClick={() => navigate(`/${country.toLowerCase()}/${shortenId(id)}`)}
                        >
                          <div className="avatar-content">
                            <img src={assistant.avatar_url} alt={assistant.display_name} />
                            <div className="assistant-info">
                              <div className="assistant-name">{assistant.display_name}</div>
                              {assistant.description && (
                                <div className="assistant-description">
                                  {assistant.description}
                                </div>
                              )}
                            </div>
                          </div>
                          {favoriteAssistantIds.includes(assistant.id) && (
                            <div className="favorite-icon" title="お気に入りから削除">
                              <Heart size={24} fill={favoriteIconColor} stroke="none" />
                            </div>
                          )}
                        </div>
                      );
                    })
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {showCallAnimation && (
          <div className="call-animation">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="phone-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" 
                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M22 16.92v3a2 2 0 0 1-2.18 2 
                  19.79 19.79 0 0 1-8.63-3.07 
                  19.5 19.5 0 0 1-6-6 
                  19.79 19.79 0 0 1-3.07-8.67
                  A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72
                  12.84 12.84 0 0 0 .7 2.81 
                  2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6
                  l1.27-1.27a2 2 0 0 1 2.11-.45 
                  12.84 12.84 0 0 0 2.81.7
                  A2 2 0 0 1 22 16.92z"/>
              </svg>
            </div>
          </div>
        )}
        <div className={`history-bottom-sheet ${isHistoryOpen ? 'open' : ''}`}>
          <div className="bottom-sheet-header">
            <h2>話履歴</h2>
            {!isWelcomeMode && !isConnected && !isTestMode && (
              <button 
                className="close-button"
                onClick={() => setIsHistoryOpen(false)}
                aria-label="閉じる"
              >
                {t('buttons.close')}
              </button>
            )}
          </div>
          <div className="history-content">
            {memories.map((memory) => {
              const conversations = JSON.parse(memory.content);
              return (
                <div key={memory.id} className="memory-group">
                  <div className="memory-timestamp">
                    {new Date(memory.created_at).toLocaleString('ja-JP')}
                  </div>
                  {conversations.map((conv: { role: string; text: string }, index: number) => (
                    <div 
                      key={index} 
                      className={`history-item ${conv.role}`}
                    >
                      <div className="speaker-name">
                        {conv.role === 'assistant' 
                          ? assistantData?.display_name || 'アシスント'
                          : 'なた'
                        }
                      </div>
                      <div className="message-text">{conv.text}</div>
                    </div>
                  ))}
                </div>
              );
            })}
            {memories.length === 0 && (
              <div className="no-history">
                {t('history.noHistory')}
              </div>
            )}
          </div>
        </div>
        {isConnected && !showCallAnimation && (
          <>
            <img 
              src="https://asmtyeakustwkqskvtkm.supabase.co/storage/v1/object/public/images/testtss%20(1).webp" 
              className="fullscreen-image" 
              alt="通話中の背景" 
            />
            {assistantData && (
              <div className="video-container">
                <div className="call-header">
                  <div className="call-controls">
                    <button
                      className="call-button end"
                      onClick={handleEndCall}
                      aria-label="通話を了"
                    />
                  </div>
                </div>
                <div className="video-wrapper">
                  <video
                    ref={videoRef}
                    className="video-element"
                    src={assistantData.talkmovie_url}
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                      opacity: isAISpeaking ? 1 : 0,
                      transition: 'opacity 0.3s ease'
                    }}
                  />
                  <video
                    className="video-element"
                    src={assistantData.stopmovie_url}
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                      opacity: !isAISpeaking ? 1 : 0,
                      transition: 'opacity 0.3s ease'
                    }}
                  />
                </div>
                <div className="favorite-footer">
                  <div className="assistant-info">
                    <h2>{assistantData.display_name}</h2>
                    <button 
                      className={`favorite-button ${isFavorite ? 'active' : ''}`}
                      onClick={toggleFavorite}
                      title={isFavorite ? 'お気に入りから削除' : 'お気に入りに追加'}
                    >
                      <Heart 
                        size={20} 
                        fill={isFavorite ? favoriteIconColor : 'none'} 
                        stroke={isFavorite ? favoriteIconColor : 'white'}
                      />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {saveStatus && (
          <div 
            className={`save-status-notification ${saveStatus.type}`}
          >
            {saveStatus.message}
          </div>
        )}
        {isConnected && (
          <div className="remaining-time">
            {formatRemainingTime(elapsedSeconds, displayCoins)}
          </div>
        )}
        {showMicDeniedPopup && (
          <div className="mic-denied-popup">
            <div className="popup-content">
              <p>
                {countryCode === 'JP'
                  ? 'マイクのアクセスを許可をしないと会話ができません。'
                  : countryCode === 'KR'
                  ? '마이크 권한이 없으면 대화할 수 없습니다.'
                  : 'Microphone permission is required to have a conversation.'}
              </p>
              <button onClick={() => window.location.reload()}>
                {countryCode === 'JP'
                  ? '閉じる'
                  : countryCode === 'KR'
                  ? '닫기'
                  : 'Close'}
              </button>
            </div>
          </div>
        )}
        {showLoginPrompt && !user && (
          <div className="login-prompt-bottom-sheet">
            <div className="bottom-sheet-content">
              <p className="prompt-message">
                {countryCode === 'JP'
                  ? '30秒以上話すに無料会員登録が必要です。'
                  : countryCode === 'KR'
                  ? '30초 이상 대화하려면 무료 회원가입이 필요합니다.'
                  : 'Free registration is required to talk for more than 30 seconds.'}
              </p>
              <div className="auth-buttons">
                <button 
                  className="login-button"
                  onClick={handleLoginClick}
                >
                  {getAuthButtonText('login')}
                </button>
                <button 
                  className="sign-up-button"
                  onClick={handleLoginClick}
                >
                  {getAuthButtonText('signup')}
                </button>
              </div>
            </div>
          </div>
        )}
        <footer className="console-footer">
          <div className="footer-content">
            <span className="company-name">doppi.inc</span>
            <div className="footer-links">
              <Link to={`/${countryCode.toLowerCase()}/tos`}>
                {countryCode === 'JP' ? '利用規約' : 'Terms of Service'}
              </Link>
              <Link to={`/${countryCode.toLowerCase()}/privacy`}>
                {countryCode === 'JP' ? 'プライバシーポリシー' : 'Privacy Policy'}
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}