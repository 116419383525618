import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';
import { ClerkProvider, SignedIn, SignedOut } from "@clerk/clerk-react";
import { ConsolePage } from './pages/ConsolePage';
import { NicknameSetupPage } from './pages/NicknameSetupPage';
import { CustomSignInPage } from './pages/CustomSignInPage';
import { MyPage } from './pages/MyPage';
import './App.scss';
import { getCountryPath, SUPPORTED_COUNTRIES, CountryCode } from './utils/country';
import { SEO } from './components/SEO';
import { SEOPage } from './pages/SEOPage';
import { UserProvider } from './context/UserContext';
import { HelmetProvider } from 'react-helmet-async';
import { getCountryFromIP } from './utils/geolocation';
import { CountryDisplay } from './components/CountryDisplay';
import { AssistantPage } from './pages/AssistantPage';
import AdminAssistantsPage from './pages/AdminAssistantsPage';
import { AdminRoute } from './components/AdminRoute';
import { supabase } from './lib/supabase';
import { TermsPage } from './pages/TermsPage';
import { PrivacyPage } from './pages/PrivacyPage';

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Clerk publishable key");
}

// リージョンパス判定
const isRegionPath = (path: string): boolean => {
  // リージョン名はハイフンが含まれたり、ある程度長めだったりすることを想定
  return path.includes('-') || path.length > 2; 
};

// アシスタントIDかどうかを判定
const isAssistantId = (path: string): boolean => {
  return /^[a-z0-9]{12}$/.test(path);
};

// 特別モードのパスを定義
const SPECIAL_MODES = ['soka', 'christian', 'ozen', 'mise'];

const isModeCode = async (path: string): Promise<boolean> => {
  if (SPECIAL_MODES.includes(path)) {
    return true;
  }
  
  const { data: modes } = await supabase
    .from('modes')
    .select('code')
    .eq('code', path)
    .single();
  
  return !!modes;
};

// RootRedirectコンポーネントを追加
function RootRedirect() {
  const navigate = useNavigate();
  
  useEffect(() => {
    const detectAndRedirect = async () => {
      try {
        const result = await getCountryFromIP();
        const path = getCountryPath(result.countryCode);
        console.log('Redirecting to:', path);
        navigate(path, { replace: true });
      } catch (error) {
        console.error('Error detecting country:', error);
        navigate('/us', { replace: true });
      }
    };

    detectAndRedirect();
  }, [navigate]);

  return null;
}

function App() {
  console.log("Clerk Publishable Key:", process.env.REACT_APP_CLERK_PUBLISHABLE_KEY);

  useEffect(() => {
    const isIOSPWA = 
      ('standalone' in window.navigator) ||
      window.matchMedia('(display-mode: standalone)').matches;
    
    if (isIOSPWA && /iPad|iPhone|iPod/.test(navigator.userAgent)) {
      document.body.classList.add('ios-pwa');
    }
  }, []);

  const detectUserCountrySync = (): CountryCode => {
    const browserLang = navigator.language;
    const countryCode = browserLang.split('-')[1]?.toUpperCase() || 'US';
    
    if (countryCode in SUPPORTED_COUNTRIES) {
      return countryCode as CountryCode;
    }
    
    return 'US';
  };

  const countryEntries = Object.entries(SUPPORTED_COUNTRIES) as [CountryCode, string][];

  return (
    <HelmetProvider>
      <ClerkProvider 
        publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY!}
        appearance={{
          elements: {
            formButtonPrimary: {
              backgroundColor: '#0099ff',
              '&:hover': { backgroundColor: '#0088ee' }
            }
          }
        }}
      >
        <UserProvider>
          <BrowserRouter>
            <AppContent 
              countryEntries={countryEntries} 
              detectUserCountrySync={detectUserCountrySync}
            />
          </BrowserRouter>
        </UserProvider>
      </ClerkProvider>
    </HelmetProvider>
  );
}

// 新しいコンポーネントを作成してRouterの中でuseNavigateを使用
function AppContent({ 
  countryEntries, 
  detectUserCountrySync 
}: { 
  countryEntries: [CountryCode, string][],
  detectUserCountrySync: () => CountryCode 
}) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const detectAndRedirect = async () => {
      if (location.pathname === '/') {
        try {
          const result = await getCountryFromIP();
          const path = getCountryPath(result.countryCode);
          console.log('Redirecting to:', path);
          navigate(path, { replace: true });
        } catch (error) {
          console.error('Error detecting country:', error);
          navigate('/us', { replace: true });
        }
      }
    };

    detectAndRedirect();
  }, [location.pathname, navigate]);

  return (
    <>
      <SEO />
      <div data-component="App">
        <Routes>
          {countryEntries.map(([code, path]) => (
            <Route
              key={`sign-in-${code}`}
              path={`/${path}/sign-in`}
              element={
                <CustomSignInPage countryCode={code} />
              }
            />
          ))}
          <Route
            path="/sign-up/*"
            element={
              <SignedOut>
                <CustomSignInPage countryCode={detectUserCountrySync()} />
              </SignedOut>
            }
          />
          <Route
            path="/setup-nickname"
            element={
              <>
                <SignedIn>
                  <NicknameSetupPage countryCode={detectUserCountrySync()} />
                </SignedIn>
                <SignedOut>
                  <Navigate to="/" replace />
                </SignedOut>
              </>
            }
          />
          <Route path="/seo" element={<SEOPage />} />

          {countryEntries.map(([code, path]) => (
            <Route
              key={`assistant-${code}`}
              path={`/${path}/:shortId`}
              element={
                <ConditionalRoute
                  element={<AssistantPage />}
                  condition={(params) => isAssistantId(params.shortId)}
                  fallback={(params) => 
                    isRegionPath(params.shortId) 
                      ? <ConsolePage countryCode={code} />
                      : <Navigate to={`/${path}`} replace />
                  }
                />
              }
            />
          ))}

          {countryEntries.map(([code, path]) => (
            <Route
              key={`region-${code}`}
              path={`/${path}/:regionName`}
              element={
                <ConditionalRoute
                  element={<ConsolePage countryCode={code} />}
                  condition={(params) => isRegionPath(params.regionName)}
                  fallback={() => <Navigate to={`/${path}`} replace />}
                />
              }
            />
          ))}

          {countryEntries.map(([code, path]) => (
            <Route
              key={`country-${code}`}
              path={`/${path}`}
              element={<ConsolePage countryCode={code} />}
            />
          ))}

          {countryEntries.map(([code, path]) => (
            <Route
              key={`mypage-${code}`}
              path={`/${path}/mypage`}
              element={<MyPage countryCode={code} />}
            />
          ))}

          <Route 
            path="/mypage" 
            element={<Navigate to={`/${detectUserCountrySync().toLowerCase()}/mypage`} replace />} 
          />
          
          <Route path="/" element={<RootRedirect />} />
          <Route path="*" element={<RootRedirect />} />
          <Route 
            path="/admin" 
            element={
              <AdminRoute>
                <AdminAssistantsPage />
              </AdminRoute>
            } 
          />

          {/* 特別モードのルート */}
          {countryEntries.map(([code, path]) => (
            SPECIAL_MODES.map(mode => (
              <Route
                key={`${mode}-${code}`}
                path={`/${path}/${mode}`}
                element={
                  <ConsolePage 
                    countryCode={code} 
                    isSokaMode={mode === 'soka'}
                    isChristianMode={mode === 'christian'}
                    isOzenMode={mode === 'ozen'}
                    isMiseMode={mode === 'mise'}
                  />
                }
              />
            ))
          ))}

          {/* 利用規約ページのルート追加 */}
          {countryEntries.map(([code, path]) => (
            <Route
              key={`terms-${code}`}
              path={`/${path}/tos`}
              element={<TermsPage countryCode={code} />}
            />
          ))}

          {/* プライバシーポリシーページのルート追加 */}
          {countryEntries.map(([code, path]) => (
            <Route
              key={`privacy-${code}`}
              path={`/${path}/privacy`}
              element={<PrivacyPage countryCode={code} />}
            />
          ))}

          {/* 特別モードのサインインルート */}
          {countryEntries.map(([code, path]) => (
            SPECIAL_MODES.map(mode => (
              <Route
                key={`sign-in-${mode}-${code}`}
                path={`/${path}/${mode}/sign-in`}
                element={
                  <CustomSignInPage 
                    countryCode={code} 
                    redirectUrl={`/${path}/${mode}`}
                  />
                }
              />
            ))
          ))}

          {/* 特別モードのニックネーム設定ルート */}
          {countryEntries.map(([code, path]) => (
            SPECIAL_MODES.map(mode => (
              <Route
                key={`setup-nickname-${mode}-${code}`}
                path={`/${path}/${mode}/setup-nickname`}
                element={
                  <NicknameSetupPage 
                    countryCode={code} 
                    returnPath={`/${path}/${mode}`}
                  />
                }
              />
            ))
          ))}
        </Routes>
      </div>
    </>
  );
}

interface ConditionalRouteProps {
  element: React.ReactElement;
  condition: (params: any) => boolean;
  fallback: (params: any) => React.ReactElement;
}

function ConditionalRoute({ element, condition, fallback }: ConditionalRouteProps) {
  const params = useParams();
  return condition(params) ? element : fallback(params);
}

export default App;
