import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser, useClerk } from '@clerk/clerk-react';
import { supabase } from '../lib/supabase';
import { useTranslation } from '../hooks/useTranslation';
import './MyPage.scss';
import { ReferralHistory, ReferralResponse } from '../types/Referral';
import { CoinTransaction } from '../types/Referral';
import { User } from '../types/User';
import { loadStripe } from '@stripe/stripe-js';
import type { StripeElementsOptions } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { CountryProps } from '../types/props';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);
const TRANSACTIONS_PER_PAGE = 10;

const STRIPE_BASE_URL = process.env.NODE_ENV === 'production'
  ? 'https://asmtyeakustwkqskvtkm.supabase.co/functions/v1'
  : 'http://localhost:3001';

const STRIPE_PAYMENT_INTENT_ENDPOINT = `${STRIPE_BASE_URL}/create-payment-intent`;

class ErrorBoundary extends React.Component<{
  children: React.ReactNode;
  fallback: React.ReactNode;
  onError: (error: Error) => void;
}, {
  hasError: boolean;
}> {
  constructor(props: {
    children: React.ReactNode;
    fallback: React.ReactNode;
    onError: (error: Error) => void;
  }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    this.props.onError(error);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export function MyPage({ countryCode }: CountryProps) {
  const navigate = useNavigate();
  const { user, isLoaded: isUserLoaded } = useUser();
  const { signOut } = useClerk();
  const { t } = useTranslation(user?.id);

  const [userData, setUserData] = useState<User | null>(null);
  const [userCoins, setUserCoins] = useState<number>(0);
  const [isCoinsLoading, setIsCoinsLoading] = useState(true);
  const [coinTransactions, setCoinTransactions] = useState<CoinTransaction[]>([]);
  const [referralCode, setReferralCode] = useState<string>('');
  const [referralHistory, setReferralHistory] = useState<ReferralHistory>({
    referrals_made: [],
    referred_by: undefined
  });
  const [inputReferralCode, setInputReferralCode] = useState('');
  const [referralError, setReferralError] = useState('');

  const [editingNickname, setEditingNickname] = useState('');
  const [editingLanguage, setEditingLanguage] = useState('ja');
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [saveStatus, setSaveStatus] = useState<{ type: 'success' | 'error'; message: string } | null>(null);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [saveCard, setSaveCard] = useState(true);

  const [displayedTransactions, setDisplayedTransactions] = useState<number>(TRANSACTIONS_PER_PAGE);
  const [hasMoreTransactions, setHasMoreTransactions] = useState<boolean>(true);

  const isJapanese = countryCode.toLowerCase() === 'jp';

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!user?.id) return;
      
      try {
        const { data: userDataObj, error: userError } = await supabase
          .from('users')
          .select('*')
          .eq('clerk_id', user.id)
          .single();

        if (userError) throw userError;

        if (userDataObj) {
          const newUserData: User = {
            id: userDataObj.id,
            clerk_id: userDataObj.clerk_id,
            nickname: userDataObj.nickname,
            country: userDataObj.country,
            language: userDataObj.language,
            created_at: userDataObj.created_at,
            updated_at: userDataObj.updated_at,
            is_admin: userDataObj.is_admin
          };
          setUserData(newUserData);
          setEditingNickname(newUserData.nickname || '');
          setEditingLanguage(newUserData.language || 'ja');

          const { data: coinData, error: coinError } = await supabase
            .from('user_coins')
            .select('coins')
            .eq('user_id', userDataObj.id)
            .single();

          if (coinError) throw coinError;
          setUserCoins(coinData?.coins || 0);
          setIsCoinsLoading(false);

          const { data: transactions, count } = await supabase
            .from('coin_transactions')
            .select('amount, transaction_type, description, created_at', { count: 'exact' })
            .eq('user_id', userDataObj.id)
            .order('created_at', { ascending: false })
            .limit(TRANSACTIONS_PER_PAGE);

          if (transactions) {
            setCoinTransactions(transactions);
            setHasMoreTransactions((count || 0) > transactions.length);
          }
        }
      } catch (err) {
        console.error('Error fetching initial data:', err);
        setIsCoinsLoading(false);
      }
    };

    fetchInitialData();
  }, [user?.id]);

  const fetchReferralData = async () => {
    if (!userData?.id) return;

    const { data: existingCode } = await supabase
      .from('referral_codes')
      .select('code')
      .eq('user_id', userData.id)
      .single();

    if (existingCode) {
      setReferralCode(existingCode.code);
    } else {
      const { data: newCode } = await supabase
        .rpc('create_referral_code', {
          user_id: userData.id
        });
      if (newCode) {
        setReferralCode(newCode);
      }
    }

    const { data: referralsMade } = await supabase
      .from('referral_relationships')
      .select(`
        referred_id,
        created_at,
        referred:users!referral_relationships_referred_id_fkey(nickname)
      `) as { data: ReferralResponse[] | null };

    const { data: referredBy } = await supabase
      .from('referral_relationships')
      .select(`
        referrer_id,
        created_at,
        referrer:users!referral_relationships_referrer_id_fkey(nickname)
      `)
      .eq('referred_id', userData.id)
      .single() as { data: ReferralResponse | null };

    setReferralHistory({
      referrals_made: (referralsMade?.map(r => ({
        nickname: r.referred?.nickname || '',
        created_at: r.created_at
      })) || []).filter(r => r.nickname),
      referred_by: referredBy ? {
        nickname: referredBy.referrer?.nickname || '',
        created_at: referredBy.created_at
      } : undefined
    });
  };

  useEffect(() => {
    if (userData?.id) {
      fetchReferralData();
    }
  }, [userData]);

  const handleUseReferralCode = async (): Promise<void> => {
    if (!userData?.id || !inputReferralCode) return;
    const { data: success } = await supabase
      .rpc('use_referral_code', {
        input_user_id: userData.id, 
        input_code: inputReferralCode
      });
    if (success) {
      setInputReferralCode('');
      await fetchReferralData();
      setSaveStatus({
        type: 'success',
        message: isJapanese ? 'コピーしました！' : 'Copied!'
      });
      setReferralError('');
    } else {
      setReferralError(isJapanese ? '無効な紹介コードです' : 'Invalid referral code');
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleUpdateProfile = async () => {
    if (!user?.id || !userData?.id) return;
    setIsUpdating(true);
    const { error } = await supabase
      .from('users')
      .update({ 
        nickname: editingNickname,
        language: editingLanguage,
        updated_at: new Date().toISOString()
      })
      .eq('id', userData.id);
    if (error) {
      console.error('Error updating user:', error);
    } else {
      setUserData(prev => prev ? { ...prev, nickname: editingNickname, language: editingLanguage } : null);
      setIsEditing(false);
    }
    setIsUpdating(false);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate(`/${countryCode.toLowerCase()}`);
    } catch (error) {
      console.error('Error signing out:', error);
      setSaveStatus({
        type: 'error',
        message: isJapanese ? 'ログアウトに失敗しました' : 'Failed to log out'
      });
    }
  };

  useEffect(() => {
    if (saveStatus) {
      const timer = setTimeout(() => {
        setSaveStatus(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [saveStatus]);

  const fetchCoinTransactions = async (limit: number) => {
    if (!user?.id) return;
    
    const { data: userDataObj } = await supabase
      .from('users')
      .select('id')
      .eq('clerk_id', user.id)
      .single();
      
    if (!userDataObj) return;
    
    const { data: transactions, count } = await supabase
      .from('coin_transactions')
      .select('amount, transaction_type, description, created_at', { count: 'exact' })
      .eq('user_id', userDataObj.id)
      .order('created_at', { ascending: false })
      .limit(limit);
      
    if (transactions) {
      setCoinTransactions(transactions);
      setHasMoreTransactions((count || 0) > transactions.length);
    }
  };

  const handleLoadMore = async () => {
    const newLimit = displayedTransactions + TRANSACTIONS_PER_PAGE;
    setDisplayedTransactions(newLimit);
    await fetchCoinTransactions(newLimit);
  };

  if (!user || !isUserLoaded) {
    return (
      <div className="mypage-container-wrapper">
        <div className="mypage-bottom-sheet">
          <div className="bottom-sheet-header">
            <h2>{isJapanese ? 'マイページ' : 'My Page'}</h2>
            <button 
              className="back-arrow"
              onClick={handleBack}
            >
              <svg width="24" height="24" fill="#333" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6z" />
              </svg>
            </button>
          </div>
          <div className="mypage-content unauthorized">
            <p className="login-message">
              {isJapanese 
                ? 'マイページを利用するにはログインが必要です'
                : 'Please log in to access My Page'}
            </p>
            <div className="action-buttons">
              <button 
                className="login-button"
                onClick={() => navigate(`/${countryCode.toLowerCase()}/sign-in`)}
              >
                {isJapanese ? 'ログイン' : 'Log In'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mypage-container-wrapper">
      <div className="mypage-bottom-sheet">
        <div className="bottom-sheet-header">
          <button 
            className="back-arrow"
            onClick={handleBack}
          >
            <svg width="24" height="24" fill="#333" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{display: 'block'}}>
              <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6z"/>
            </svg>
          </button>
          <h2>{isJapanese ? 'マイページ' : 'My Page'}</h2>
        </div>
        <div className="mypage-content">
          {user ? (
            <>
              <div className="coin-balance">
                <h3>{isJapanese ? '所持コイン' : 'Your Coins'}</h3>
                {isCoinsLoading ? (
                  <div className="coin-loading">
                    <div className="spinner" />
                  </div>
                ) : (
                  <>
                    <p className="coin-amount">{userCoins} {isJapanese ? 'コイン' : 'coins'}</p>
                    <p className="coin-description">
                      {isJapanese 
                        ? '1コイン=30秒通話\n毎日10コインプレゼント(有効期限24時間)'
                        : '1 coin = 30 seconds of conversation\n10 free coins daily (24h expiry)'}
                    </p>
                  </>
                )}
              </div>

              <div className="referral-section">
                <h3>{isJapanese ? '紹介コード' : 'Referral Code'}</h3>
                <div className="referral-code-display">
                  <span className="code">{referralCode}</span>
                  <button
                    className="copy-button"
                    onClick={() => {
                      navigator.clipboard.writeText(referralCode);
                      setSaveStatus({
                        type: 'success',
                        message: isJapanese ? 'コピーしました！' : 'Copied!'
                      });
                    }}
                  >
                    {isJapanese ? 'コピー' : 'Copy'}
                  </button>
                </div>
                <p className="referral-description">
                  {isJapanese 
                    ? '友達紹介でお互い100コインゲット！(有効期限なし)'
                    : 'Refer friends, both get 100 coins! (no expiry)'}
                </p>
              </div>

              {!referralHistory.referred_by && (
                <div className="referral-input-section">
                  <h3>{isJapanese ? '紹介コードを使用' : 'Use Referral Code'}</h3>
                  <div className="input-group">
                    <input
                      type="text"
                      value={inputReferralCode}
                      onChange={(e) => setInputReferralCode(e.target.value)}
                      placeholder={isJapanese ? '紹介コードを入力' : 'Enter code'}
                      maxLength={7}
                    />
                    <button
                      className="submit-button"
                      onClick={handleUseReferralCode}
                    >
                      {isJapanese ? '使用する' : 'Use'}
                    </button>
                  </div>
                  {referralError && (
                    <p className="error-message">
                      {referralError}
                    </p>
                  )}
                </div>
              )}

              <div className="referral-history-section">
                <h3>{isJapanese ? '紹介履歴' : 'Referral History'}</h3>
                {referralHistory.referred_by && (
                  <div className="referred-by">
                    <h4>{isJapanese ? '紹介してくれた人' : 'Referred By'}</h4>
                    <p>{referralHistory.referred_by.nickname}</p>
                    <span className="date">
                      {new Date(referralHistory.referred_by.created_at).toLocaleDateString(
                        isJapanese ? 'ja-JP' : 'en-US'
                      )}
                    </span>
                  </div>
                )}
                <div className="referrals-made">
                  <h4>
                    {isJapanese 
                      ? `紹介した人 (${referralHistory.referrals_made.length}人)`
                      : `People You Referred (${referralHistory.referrals_made.length})`}
                  </h4>
                  {referralHistory.referrals_made.length > 0 ? (
                    <ul>
                      {referralHistory.referrals_made.map((referral, index) => (
                        <li key={index}>
                          <span className="nickname">{referral.nickname}</span>
                          <span className="date">
                            {new Date(referral.created_at).toLocaleDateString(
                              isJapanese ? 'ja-JP' : 'en-US'
                            )}
                          </span>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="no-referrals">
                      {isJapanese 
                        ? 'まだ誰も紹介していません'
                        : 'No one referred yet'}
                    </p>
                  )}
                </div>
              </div>

              <div className="coin-transactions">
                <h3>{isJapanese ? 'コイン履歴' : 'Coin History'}</h3>
                <div className="transactions-list">
                  {coinTransactions.map((transaction, index) => (
                    <div key={index} className="transaction-item">
                      <div className="transaction-info">
                        <span className="transaction-description">
                          {transaction.description}
                        </span>
                      </div>
                      <div className="transaction-details">
                        <span className={`transaction-amount ${transaction.amount > 0 ? 'positive' : 'negative'}`}>
                          {transaction.amount > 0 ? '+' : ''}{transaction.amount}
                        </span>
                        <span className="transaction-date">
                          {new Date(transaction.created_at).toLocaleDateString(
                            isJapanese ? 'ja-JP' : 'en-US'
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                  {coinTransactions.length === 0 && (
                    <div className="no-transactions">
                      {isJapanese ? '取引履歴がありません' : 'No history'}
                    </div>
                  )}
                  {hasMoreTransactions && (
                    <button 
                      className="load-more-button"
                      onClick={handleLoadMore}
                    >
                      {isJapanese ? 'もっと見る' : 'Load More'}
                    </button>
                  )}
                </div>
              </div>

              <div className="edit-form">
                <div className="form-group">
                  <label>{isJapanese ? 'ニックネーム' : 'Nickname'}</label>
                  <input
                    type="text"
                    value={editingNickname}
                    onChange={(e) => setEditingNickname(e.target.value)}
                    maxLength={20}
                    disabled={isUpdating}
                    placeholder={isJapanese ? 'ニックネームを入力' : 'Enter nickname'}
                    onFocus={() => setIsEditing(true)}
                  />
                </div>
                {isEditing && (
                  <div className="form-buttons">
                    <button 
                      className="cancel-button"
                      onClick={() => {
                        setIsEditing(false);
                        setEditingNickname(userData?.nickname || '');
                        setEditingLanguage(userData?.language || 'ja');
                      }}
                      disabled={isUpdating}
                    >
                      {isJapanese ? 'キャンセル' : 'Cancel'}
                    </button>
                    <button 
                      className="save-button"
                      onClick={handleUpdateProfile}
                      disabled={isUpdating}
                    >
                      {isUpdating 
                        ? (isJapanese ? '更新中...' : 'Updating...')
                        : (isJapanese ? '保存' : 'Save')}
                    </button>
                  </div>
                )}
              </div>

              <div className="action-buttons">
                <button 
                  className="sign-out"
                  onClick={handleSignOut}
                >
                  {isJapanese ? 'ログアウト' : 'Sign Out'}
                </button>
              </div>
            </>
          ) : (
            <div className="mypage-content unauthorized">
              <p className="login-message">
                {isJapanese 
                  ? 'マイページを利用するにはログインが必要です'
                  : 'Please log in to access My Page'}
              </p>
              <div className="action-buttons">
                <button 
                  className="login-button"
                  onClick={() => navigate(`/${countryCode.toLowerCase()}/sign-in`)}
                >
                  {isJapanese ? 'ログイン' : 'Log In'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {saveStatus && (
        <div 
          className={`save-status-notification ${saveStatus.type}`}
        >
          {saveStatus.message}
        </div>
      )}
    </div>
  );
}
