export type TranslationKeys = {
  menu: {
    home: string;
    myPage: string;
    signOut: string;
  };
  auth: {
    phoneNumber: string;
    verificationCode: string;
    sendCode: string;
    verify: string;
    back: string;
    loginRequired: string;
    login: string;
    enterPhoneNumber: string;
    phoneNumberPlaceholder: string;
    enterVerificationCode: string;
    verificationCodeInstruction: string;
    privacyNote: string;
    invalidPhoneNumber: string;
    tooManyRequests: string;
    smsError: string;
    sessionError: string;
    secondFactorRequired: string;
    verificationFailed: string;
  };
  welcome: {
    title: string;
    description: string;
  };
  buttons: {
    call: string;
    date: string;
    close: string;
    save: string;
    inquiry: string;
    history: string;
    myPage: string;
    end: string;
    line: string;
  };
  inquiry: {
    message: string;
  };
  history: {
    noHistory: string;
  };
  messages: {
    quietPlace: string;
  };
};

type Translations = {
  [key in 'ar' | 'de' | 'en' | 'es' | 'fr' | 'hi' | 'id' | 'it' | 'ja' | 'ko' | 'nl' | 'pt' | 'ru' | 'th' | 'tr' | 'vi' | 'zh']: TranslationKeys;
};

export const translations: Translations = {
  ar: {
    menu: {
      home: 'الصفحة الرئيسية',
      myPage: 'صفحتي',
      signOut: 'تسجيل خروج'
    },
    auth: {
      phoneNumber: 'رقم الهاتف',
      verificationCode: 'رمز التحقق',
      sendCode: 'إرسال الرمز',
      verify: 'تحقق',
      back: 'رجوع',
      loginRequired: 'تسجيل الدخول مطلوب',
      login: 'تسجيل الدخول',
      enterPhoneNumber: 'أدخل رقم هاتفك',
      phoneNumberPlaceholder: 'أدخل رقم هاتفك',
      enterVerificationCode: 'أدخل رمز التحقق',
      verificationCodeInstruction: 'تم إرسال رمز التحقق إلى هاتفك',
      privacyNote: 'بالضغط على إرسال الرمز، أنت توافق على شروط الخدمة وسياسة الخصوصية',
      invalidPhoneNumber: 'رقم الهاتف غير صالح',
      tooManyRequests: 'محاولات كثيرة جداً. يرجى المحاولة لاحقاً',
      smsError: 'خطأ في إرسال الرسالة النصية',
      sessionError: 'خطأ في الجلسة',
      secondFactorRequired: 'مطلوب عامل تحقق ثانٍ',
      verificationFailed: 'فشل التحقق'
    },
    welcome: {
      title: 'مرحباً بك في Doppi Bar',
      description: 'دعنا نبدأ المحادثة'
    },
    buttons: {
      call: 'اتصال',
      date: 'موعد',
      close: 'إغلاق',
      save: 'حفظ',
      inquiry: 'استفسار',
      history: 'السجل',
      myPage: 'صفحتي',
      end: 'إنهاء',
      line: 'LINE سؤال'
    },
    inquiry: {
      message: 'لا تتردد في طرح أي أسئلة!'
    },
    history: {
      noHistory: 'لا يوجد سجل محادثات'
    },
    messages: {
      quietPlace: 'يرجى المحاولة في مكان هادئ لأن الذكاء الاصطناعي يلتقط الأصوات المحيطة'
    }
  },
  de: {
    menu: {
      home: 'Startseite',
      myPage: 'Meine Seite',
      signOut: 'Abmelden'
    },
    auth: {
      phoneNumber: 'Telefonnummer',
      verificationCode: 'Verifizierungscode',
      sendCode: 'Code senden',
      verify: 'Verifizieren',
      back: 'Zurück',
      loginRequired: 'Login erforderlich',
      login: 'Einloggen',
      enterPhoneNumber: 'Telefonnummer eingeben',
      phoneNumberPlaceholder: 'Telefonnummer eingeben',
      enterVerificationCode: 'Verifizierungscode eingeben',
      verificationCodeInstruction: 'Geben Sie den per SMS erhaltenen Code ein',
      privacyNote: 'Mit dem Senden des Codes stimmen Sie den Nutzungsbedingungen und der Datenschutzerklärung zu',
      invalidPhoneNumber: 'Ungültige Telefonnummer',
      tooManyRequests: 'Zu viele Versuche. Bitte später erneut versuchen',
      smsError: 'Fehler beim SMS-Versand',
      sessionError: 'Sitzungsfehler',
      secondFactorRequired: 'Zweiter Faktor erforderlich',
      verificationFailed: 'Verifizierung fehlgeschlagen'
    },
    welcome: {
      title: 'Willkommen bei Doppi Bar',
      description: 'Lass uns ein Gespräch beginnen'
    },
    buttons: {
      call: 'Anrufen',
      date: 'Verabredung',
      close: 'Schließen',
      save: 'Speichern',
      inquiry: 'Anfrage',
      history: 'Verlauf',
      myPage: 'Meine Seite',
      end: 'Beenden',
      line: 'Über LINE fragen'
    },
    inquiry: {
      message: 'Fragen Sie uns gerne alles!'
    },
    history: {
      noHistory: 'Keine Gesprächshistorie'
    },
    messages: {
      quietPlace: 'Bitte versuchen Sie es an einem ruhigen Ort, da die KI Umgebungsgeräusche aufnimmt'
    }
  },
  en: {
    menu: {
      home: 'Home',
      myPage: 'My Page',
      signOut: 'Sign Out'
    },
    auth: {
      phoneNumber: 'Phone Number',
      verificationCode: 'Verification Code',
      sendCode: 'Send Code',
      verify: 'Verify',
      back: 'Back',
      loginRequired: 'Login Required',
      login: 'Login',
      enterPhoneNumber: 'Enter your phone number',
      phoneNumberPlaceholder: 'Enter your phone number',
      enterVerificationCode: 'Enter verification code',
      verificationCodeInstruction: 'Verification code sent to your phone',
      privacyNote: 'By tapping Send Code, you agree to the terms of service and privacy policy',
      invalidPhoneNumber: 'Invalid phone number',
      tooManyRequests: 'Too many attempts. Please try again later',
      smsError: 'Error sending SMS',
      sessionError: 'Session error',
      secondFactorRequired: 'Second factor required',
      verificationFailed: 'Verification failed'
    },
    welcome: {
      title: 'Welcome to Doppi Bar',
      description: "Let's start a conversation"
    },
    buttons: {
      call: 'Call',
      date: 'Date',
      close: 'Close',
      save: 'Save',
      inquiry: 'Inquiry',
      history: 'History',
      myPage: 'My Page',
      end: 'End',
      line: 'Ask on LINE'
    },
    inquiry: {
      message: 'Feel free to ask any questions!'
    },
    history: {
      noHistory: 'No conversation history'
    },
    messages: {
      quietPlace: 'Please try in a quiet place as AI picks up ambient sounds'
    }
  },
  es: {
    menu: {
      home: 'Inicio',
      myPage: 'Mi Página',
      signOut: 'Cerrar Sesión'
    },
    auth: {
      phoneNumber: 'Número de teléfono',
      verificationCode: 'Código de verificación',
      sendCode: 'Enviar código',
      verify: 'Verificar',
      back: 'Regresar',
      loginRequired: 'Inicio de sesión requerido',
      login: 'Iniciar sesión',
      enterPhoneNumber: 'Ingrese su número de teléfono',
      phoneNumberPlaceholder: 'Ingrese su número de teléfono',
      enterVerificationCode: 'Ingrese el código de verificación',
      verificationCodeInstruction: 'Código de verificación enviado a su teléfono',
      privacyNote: 'Al tocar Enviar código, acepta los términos del servicio y la política de privacidad',
      invalidPhoneNumber: 'Número de teléfono no válido',
      tooManyRequests: 'Demasiados intentos. Por favor, inténtelo de nuevo más tarde',
      smsError: 'Error al enviar el mensaje de texto',
      sessionError: 'Error de sesión',
      secondFactorRequired: 'Factor de verificación adicional requerido',
      verificationFailed: 'Verificación fallida'
    },
    welcome: {
      title: 'Bienvenido a Doppi Bar',
      description: 'Comencemos una conversación'
    },
    buttons: {
      call: 'Llamar',
      date: 'Cita',
      close: 'Cerrar',
      save: 'Guardar',
      inquiry: 'Consulta',
      history: 'Historial',
      myPage: 'Mi Página',
      end: 'Finalizar',
      line: 'Preguntar en LINE'
    },
    inquiry: {
      message: '¡No dudes en hacer cualquier pregunta!'
    },
    history: {
      noHistory: 'No hay historial de conversación'
    },
    messages: {
      quietPlace: 'Por favor, intente en un lugar tranquilo ya que la IA capta sonidos ambientales'
    }
  },
  fr: {
    menu: {
      home: 'Accueil',
      myPage: 'Ma Page',
      signOut: 'Déconnexion'
    },
    auth: {
      phoneNumber: 'Numéro de téléphone',
      verificationCode: 'Code de vérification',
      sendCode: 'Envoyer le code',
      verify: 'Vérifier',
      back: 'Retour',
      loginRequired: 'Connexion requise',
      login: 'Se connecter',
      enterPhoneNumber: 'Entrez votre numéro de téléphone',
      phoneNumberPlaceholder: 'Entrez votre numéro de téléphone',
      enterVerificationCode: 'Entrez le code de vérification',
      verificationCodeInstruction: 'Code de vérification envoyé à votre téléphone',
      privacyNote: 'En tapant Envoyer le code, vous acceptez les termes du service et la politique de confidentialité',
      invalidPhoneNumber: 'Numéro de téléphone invalide',
      tooManyRequests: 'Trop d\'essais. Veuillez réessayer plus tard',
      smsError: 'Erreur lors de l\'envoi du SMS',
      sessionError: 'Erreur de session',
      secondFactorRequired: 'Second facteur requis',
      verificationFailed: 'Vérification échouée'
    },
    welcome: {
      title: 'Bienvenue sur Doppi Bar',
      description: 'Commençons une conversation'
    },
    buttons: {
      call: 'Appeler',
      date: 'Rendez-vous',
      close: 'Fermer',
      save: 'Enregistrer',
      inquiry: 'Demande',
      history: 'Historique',
      myPage: 'Ma Page',
      end: 'Terminer',
      line: 'Demander sur LINE'
    },
    inquiry: {
      message: "N'hésitez pas à poser des questions !"
    },
    history: {
      noHistory: 'Aucun historique de conversation'
    },
    messages: {
      quietPlace: "Veuillez essayer dans un endroit calme car l'IA capte les sons ambiants"
    }
  },
  hi: {
    menu: {
      home: 'होम',
      myPage: 'मेरा पेज',
      signOut: 'साइन आउट'
    },
    auth: {
      phoneNumber: 'फोन नंबर',
      verificationCode: 'प्रमाण कोड',
      sendCode: 'कोड भेजें',
      verify: 'सत्यापित करें',
      back: 'वापस',
      loginRequired: 'लॉगिन आवश्यक है',
      login: 'लॉगिन करें',
      enterPhoneNumber: 'अपना फोन नंबर दर्ज करें',
      phoneNumberPlaceholder: 'अपना फोन नंबर दर्ज करें',
      enterVerificationCode: 'प्रमाण कोड दर्ज करें',
      verificationCodeInstruction: 'प्रमाण कोड आपके फोन पर भेजा गया है',
      privacyNote: 'जब आप कोड भेजते हैं, आप सेवा के शर्तों और गोपनीयता नीति को स्वीकार करते हैं',
      invalidPhoneNumber: 'अवैध फोन नंबर',
      tooManyRequests: 'बहुत सारे प्रयास. कृपया बाद में पुनः प्रयास करें',
      smsError: 'एसएमएस संदेश भेजने में त्रुटि',
      sessionError: 'सेशन त्रुटि',
      secondFactorRequired: 'दूसरा कारक आवश्यक है',
      verificationFailed: 'सत्यापन विफल'
    },
    welcome: {
      title: 'Doppi में आपका स्वागत है',
      description: 'चलिए बातचीत शुरू रें'
    },
    buttons: {
      call: 'कॉल',
      date: 'डेट',
      close: 'बंद करें',
      save: 'सेव करें',
      inquiry: 'पूछताछ',
      history: 'इतिहास',
      myPage: 'मेरा पेज',
      end: 'समाप्त',
      line: 'LINE पर पूछें'
    },
    inquiry: {
      message: 'कोई भी सवाल पूछने में संकोच न करें!'
    },
    history: {
      noHistory: 'कोई बातचीत इतिहास नहं'
    },
    messages: {
      quietPlace: 'कृपया शांत जगह पर प्रयास करें क्योंकि AI आस-पास की आवाजें भी पकड़ लेता है'
    }
  },
  id: {
    menu: {
      home: 'Beranda',
      myPage: 'Halaman Saya',
      signOut: 'Keluar'
    },
    auth: {
      phoneNumber: 'Nomor Telepon',
      verificationCode: 'Kode Verifikasi',
      sendCode: 'Kirim Kode',
      verify: 'Verifikasi',
      back: 'Kembali',
      loginRequired: 'Login Diperlukan',
      login: 'Masuk',
      enterPhoneNumber: 'Masukkan nomor telepon Anda',
      phoneNumberPlaceholder: 'Masukkan nomor telepon Anda',
      enterVerificationCode: 'Masukkan kode verifikasi',
      verificationCodeInstruction: 'Kode verifikasi telah dikirim kepada Anda',
      privacyNote: 'Dengan mengetuk Kirim Kode, Anda setuju dengan Syarat & Kebijakan Privasi kami',
      invalidPhoneNumber: 'Nomor telepon tidak valid',
      tooManyRequests: 'Terlalu banyak percobaan. Silakan coba lagi nanti',
      smsError: 'Kesalahan saat mengirim SMS',
      sessionError: 'Kesalahan sesi',
      secondFactorRequired: 'Faktor kedua diperlukan',
      verificationFailed: 'Verifikasi gagal'
    },
    welcome: {
      title: 'Selamat datang di Doppi',
      description: 'Mari mulai percakapan'
    },
    buttons: {
      call: 'Panggil',
      date: 'Kencan',
      close: 'Tutup',
      save: 'Simpan',
      inquiry: 'Pertanyaan',
      history: 'Riwayat',
      myPage: 'Halaman Saya',
      end: 'Selesai',
      line: 'Tanya di LINE'
    },
    inquiry: {
      message: 'Jangan ragu untuk bertanya apa saja!'
    },
    history: {
      noHistory: 'Tidak ada riwayat percakapan'
    },
    messages: {
      quietPlace: 'Silakan coba di tempat yang tenang karena AI menangkap suara sekitar'
    }
  },
  it: {
    menu: {
      home: 'Home',
      myPage: 'La Mia Pagina',
      signOut: 'Esci'
    },
    auth: {
      phoneNumber: 'Numero di telefono',
      verificationCode: 'Codice di verifica',
      sendCode: 'Invia codice',
      verify: 'Verifica',
      back: 'Indietro',
      loginRequired: 'Login richiesto',
      login: 'Accedi',
      enterPhoneNumber: 'Inserisci il numero di telefono',
      phoneNumberPlaceholder: 'Inserisci il numero di telefono',
      enterVerificationCode: 'Inserisci il codice di verifica',
      verificationCodeInstruction: 'Codice di verifica inviato al tuo telefono',
      privacyNote: 'Toccare Invia codice per accettare i termini del servizio e la politica sulla privacy',
      invalidPhoneNumber: 'Numero di telefono non valido',
      tooManyRequests: 'Troppi tentativi. Per favore riprova più tardi',
      smsError: 'Errore nel inviare SMS',
      sessionError: 'Errore di sessione',
      secondFactorRequired: 'Secondo fattore richiesto',
      verificationFailed: 'Verifica fallita'
    },
    welcome: {
      title: 'Benvenuto su Doppi',
      description: 'Iniziamo una conversazione'
    },
    buttons: {
      call: 'Chiama',
      date: 'Appuntamento',
      close: 'Chiudi',
      save: 'Salva',
      inquiry: 'Richiesta',
      history: 'Cronologia',
      myPage: 'La Mia Pagina',
      end: 'Fine',
      line: 'Chiedi su LINE'
    },
    inquiry: {
      message: 'Non esitare a fare domande!'
    },
    history: {
      noHistory: 'Nessuna cronologia delle conversazioni'
    },
    messages: {
      quietPlace: "Si prega di provare in un luogo tranquillo poiché l'IA rileva i suoni ambientali"
    }
  },
  ja: {
    menu: {
      home: 'ホーム',
      myPage: 'マイページ',
      signOut: 'ログアウト'
    },
    auth: {
      phoneNumber: '電話番号',
      verificationCode: '確認コード',
      sendCode: 'コードを送信',
      verify: '確認',
      back: '戻る',
      loginRequired: 'ログインが必要',
      login: 'ログイン',
      enterPhoneNumber: '電話番号を入力',
      phoneNumberPlaceholder: '電話番号を入力',
      enterVerificationCode: '確認コードを入力',
      verificationCodeInstruction: '確認コードが電話に送信されました',
      privacyNote: '確認コードを送信することで、サービスの利用規約とプライバシーポリシーに同意します',
      invalidPhoneNumber: '無効な電話番号',
      tooManyRequests: '試行回数が多すぎます。後でもう一度試してください',
      smsError: 'SMS送信エラー',
      sessionError: 'セッションエラー',
      secondFactorRequired: '第二の要因が必要',
      verificationFailed: '確認に失敗'
    },
    welcome: {
      title: 'Doppi Barへようこそ',
      description: '会話を始めましょう'
    },
    buttons: {
      call: '指名する',
      date: 'デートする',
      close: '閉じる',
      save: '保存',
      inquiry: 'お問い合わせ',
      history: '会話履歴',
      myPage: 'マイページ',
      end: '終了',
      line: 'LINEで質問'
    },
    inquiry: {
      message: '利用方法や不明点などなんでも聞いてください！'
    },
    history: {
      noHistory: '会話履歴がありません'
    },
    messages: {
      quietPlace: 'AIが周りの音も拾ってしまうため静かな場所でお試しください'
    }
  },
  ko: {
    menu: {
      home: '홈',
      myPage: '마이페이지',
      signOut: '로그아웃'
    },
    auth: {
      phoneNumber: '전화번호',
      verificationCode: '인증코드',
      sendCode: '코드 보내기',
      verify: '인증',
      back: '돌아가기',
      loginRequired: '로그인이 필요합니다',
      login: '로그인',
      enterPhoneNumber: '전화번호 입력',
      phoneNumberPlaceholder: '전화번호 입력',
      enterVerificationCode: '인증코드 입력',
      verificationCodeInstruction: '인증코드가 전화로 보내졌습니다',
      privacyNote: '코드 보내기를 누르면 서비스 약관과 개인정보처리방침에 동의합니다',
      invalidPhoneNumber: '유효하지 않은 전화번호',
      tooManyRequests: '너무 많은 시도. 나중에 다시 시도해주세요',
      smsError: 'SMS 전송 오류',
      sessionError: '세션 오류',
      secondFactorRequired: '두 번째 요인이 필요합니다',
      verificationFailed: '인증 실패'
    },
    welcome: {
      title: 'Doppi Bar에 오신 것을 환영합니다',
      description: '대화를 시작해보세요'
    },
    buttons: {
      call: '통화하기',
      date: '데이트하기',
      close: '닫기',
      save: '저장',
      inquiry: '문의하기',
      history: '대화기록',
      myPage: '마이페이지',
      end: '종료',
      line: 'LINE으로 문의'
    },
    inquiry: {
      message: '궁금한 점이 있으시면 언제든 문의해주세요!'
    },
    history: {
      noHistory: '대화 기록이 없습니다'
    },
    messages: {
      quietPlace: 'AI가 주변 소리도 감지하므로 조용한 장소에서 시도해 주세요'
    }
  },
  nl: {
    menu: {
      home: 'Home',
      myPage: 'Mijn Pagina',
      signOut: 'Uitloggen'
    },
    auth: {
      phoneNumber: 'Telefoonnummer',
      verificationCode: 'Verificatiecode',
      sendCode: 'Code verzenden',
      verify: 'Verifiëren',
      back: 'Terug',
      loginRequired: 'Inloggen vereist',
      login: 'Inloggen',
      enterPhoneNumber: 'Telefoonnummer invoeren',
      phoneNumberPlaceholder: 'Telefoonnummer invoeren',
      enterVerificationCode: 'Verificatiecode invoeren',
      verificationCodeInstruction: 'Verificatiecode verzonden naar uw telefoon',
      privacyNote: 'Door op Verzenden van de code te tikken, stemt u in met de voorwaarden van de dienst en de privacyverklaring',
      invalidPhoneNumber: 'Ongeldig telefoonnummer',
      tooManyRequests: 'Te veel pogingen. Probeer het later opnieuw',
      smsError: 'Fout bij het verzenden van SMS',
      sessionError: 'Sessiefout',
      secondFactorRequired: 'Tweede factor vereist',
      verificationFailed: 'Verificatie mislukt'
    },
    welcome: {
      title: 'Welkom bij Doppi',
      description: 'Laten we een gesprek beginnen'
    },
    buttons: {
      call: 'Bellen',
      date: 'Afspraak',
      close: 'Sluiten',
      save: 'Opslaan',
      inquiry: 'Vraag',
      history: 'Geschiedenis',
      myPage: 'Mijn Pagina',
      end: 'Beëindigen',
      line: 'Vraag via LINE'
    },
    inquiry: {
      message: 'Stel gerust al je vragen!'
    },
    history: {
      noHistory: 'Geen gespreksgeschiedenis'
    },
    messages: {
      quietPlace: 'Probeer het op een rustige plek omdat AI omgevingsgeluiden oppikt'
    }
  },
  pt: {
    menu: {
      home: 'Início',
      myPage: 'Minha Página',
      signOut: 'Sair'
    },
    auth: {
      phoneNumber: 'Número de telefone',
      verificationCode: 'Código de verificação',
      sendCode: 'Enviar código',
      verify: 'Verificar',
      back: 'Voltar',
      loginRequired: 'Login necessário',
      login: 'Entrar',
      enterPhoneNumber: 'Digite seu número de telefone',
      phoneNumberPlaceholder: 'Digite seu número de telefone',
      enterVerificationCode: 'Digite o código de verificação',
      verificationCodeInstruction: 'Código de verificação enviado para o seu telefone',
      privacyNote: 'Ao tocar Enviar código, você concorda com os termos do serviço e a política de privacidade',
      invalidPhoneNumber: 'Número de telefone inválido',
      tooManyRequests: 'Muitas tentativas. Por favor, tente novamente mais tarde',
      smsError: 'Erro ao enviar SMS',
      sessionError: 'Erro de sessão',
      secondFactorRequired: 'Segundo fator necessário',
      verificationFailed: 'Verificação falhou'
    },
    welcome: {
      title: 'Bem-vindo ao Doppi',
      description: 'Vamos começar uma conversa'
    },
    buttons: {
      call: 'Ligar',
      date: 'Encontro',
      close: 'Fechar',
      save: 'Salvar',
      inquiry: 'Consulta',
      history: 'Histórico',
      myPage: 'Minha Página',
      end: 'Encerrar',
      line: 'Perguntar no LINE'
    },
    inquiry: {
      message: 'Sinta-se à vontade para fazer qualquer pergunta!'
    },
    history: {
      noHistory: 'Sem histórico de conversas'
    },
    messages: {
      quietPlace: 'Por favor, tente em um local silencioso pois a IA capta sons ambientes'
    }
  },
  ru: {
    menu: {
      home: 'Главная',
      myPage: 'Моя страница',
      signOut: 'Выйти'
    },
    auth: {
      phoneNumber: 'Номер телефона',
      verificationCode: 'Код подтверждения',
      sendCode: 'Отправить код',
      verify: 'Подтвердить',
      back: 'Назад',
      loginRequired: 'Вход необходим',
      login: 'Войти',
      enterPhoneNumber: 'Введите номер телефона',
      phoneNumberPlaceholder: 'Введите номер телефона',
      enterVerificationCode: 'Введите код подтверждения',
      verificationCodeInstruction: 'Код подтверждения отправлен на ваш телефон',
      privacyNote: 'Нажатием Отправить код вы соглашаетесь с условиями обслуживания и политикой конфиденциальности',
      invalidPhoneNumber: 'Неверный номер телефона',
      tooManyRequests: 'Много попыток. Пожалуйста, попробуйте позже',
      smsError: 'Ошибка при отправке SMS',
      sessionError: 'Ошибка сессии',
      secondFactorRequired: 'Необходим второй фактор',
      verificationFailed: 'Проверка не пройдена'
    },
    welcome: {
      title: 'Добро пожаловать в Doppi',
      description: 'Давайте начнем разговор'
    },
    buttons: {
      call: 'Позвонить',
      date: 'Свидание',
      close: 'Закрыть',
      save: 'Сохранить',
      inquiry: 'Запрос',
      history: 'История',
      myPage: 'Моя страница',
      end: 'Завершить',
      line: 'Спросить в LINE'
    },
    inquiry: {
      message: 'Не стесняйтесь задавать любые вопросы!'
    },
    history: {
      noHistory: 'Нет истории разговоров'
    },
    messages: {
      quietPlace: 'Пожалуйста, попробуйте в тихом месте, так как ИИ улавливает окружающие звуки'
    }
  },
  th: {
    menu: {
      home: 'หน้าแรก',
      myPage: 'หน้าของฉัน',
      signOut: 'ออกจากระบบ'
    },
    auth: {
      phoneNumber: 'เบอร์โทรศัพท์',
      verificationCode: 'โค้ดยืนยัน',
      sendCode: 'ส่งโค้ด',
      verify: 'ยืนยัน',
      back: 'ย้อนกลับ',
      loginRequired: 'การเข้าสู่ระบบจำเปียก',
      login: 'เข้าสู่ระบบ',
      enterPhoneNumber: 'กรอกเบอร์โทรศัพท์',
      phoneNumberPlaceholder: 'กรอกเบอร์โทรศัพท์',
      enterVerificationCode: 'กรอกโค้ดยืนยัน',
      verificationCodeInstruction: 'โค้ดยืนยันถูกส่งไปยังโทรศัพท์ของคุณ',
      privacyNote: 'โดยการกดส่งโค้ด คุณยอมรับเงื่อนไขการใช้บริการและนโยบายความเป็นส่วนตัวของเรา',
      invalidPhoneNumber: 'เบอร์โทรศัพท์ไม่ถูกต้อง',
      tooManyRequests: 'มีการลองมากมาย โปรดลองใหม่ภายหลัง',
      smsError: 'ข้อผิดพลาดในการส่ง SMS',
      sessionError: 'ข้อผิดพลาดของชุดการทำงาน',
      secondFactorRequired: 'ต้องมีปัจจัยการยืนยันอีกครั้ง',
      verificationFailed: 'การยืนยันล้มเหลว'
    },
    welcome: {
      title: 'ยินดีต้อนรับสู่ Doppi',
      description: 'มาเริ่มการสนทนากัน'
    },
    buttons: {
      call: 'โทร',
      date: 'นัด',
      close: 'ปิด',
      save: 'บันทึก',
      inquiry: 'สอบถาม',
      history: 'ประวัติ',
      myPage: 'หน้าของฉัน',
      end: 'จบ',
      line: 'ถามผ่าน LINE'
    },
    inquiry: {
      message: 'สอบถามได้ทุกเรื่องเลยนะ!'
    },
    history: {
      noHistory: 'ไม่มีประวัติการสนทนา'
    },
    messages: {
      quietPlace: 'โปรดลองในสถานที่เงียบเนื่องจาก AI จะรับเสียงรอบข้างด้วย'
    }
  },
  tr: {
    menu: {
      home: 'Ana Sayfa',
      myPage: 'Sayfam',
      signOut: 'Çıkış'
    },
    auth: {
      phoneNumber: 'Telefon numarası',
      verificationCode: 'Doğrulama kodu',
      sendCode: 'Kodu gönder',
      verify: 'Doğrula',
      back: 'Geri',
      loginRequired: 'Giriş yapılması gerekiyor',
      login: 'Giriş yap',
      enterPhoneNumber: 'Telefon numarasını girin',
      phoneNumberPlaceholder: 'Telefon numarasını girin',
      enterVerificationCode: 'Doğrulama kodunu girin',
      verificationCodeInstruction: 'Doğrulama kodu telefonunuza gönderildi',
      privacyNote: 'Kodu göndermek için, hizmet koşullarını ve gizlilik politikasını kabul ediyorsunuz',
      invalidPhoneNumber: 'Geçersiz telefon numarası',
      tooManyRequests: 'Çok fazla deneme. Lütfen daha sonra tekrar deneyin',
      smsError: 'SMS gönderme hatası',
      sessionError: 'Oturum hatası',
      secondFactorRequired: 'İkinci faktör gerekiyor',
      verificationFailed: 'Doğrulama başarısız'
    },
    welcome: {
      title: "Doppi'ye Hoş Geldiniz",
      description: 'Hadi bir sohbet başlatalım'
    },
    buttons: {
      call: 'Ara',
      date: 'Randevu',
      close: 'Kapat',
      save: 'Kaydet',
      inquiry: 'Soru',
      history: 'Geçmiş',
      myPage: 'Sayfam',
      end: 'Bitir',
      line: "LINE'da Sor"
    },
    inquiry: {
      message: 'Her türlü sorunuzu sorabilirsiniz!'
    },
    history: {
      noHistory: 'Sohbet geçmişi yok'
    },
    messages: {
      quietPlace: 'Yapay zeka ortam seslerini algıladığı için lütfen sessiz bir yerde deneyin'
    }
  },
  vi: {
    menu: {
      home: 'Trang chủ',
      myPage: 'Trang của tôi',
      signOut: 'Đăng xuất'
    },
    auth: {
      phoneNumber: 'Số điện thoại',
      verificationCode: 'Mã xác nhận',
      sendCode: 'Gửi mã',
      verify: 'Xác nhận',
      back: 'Quay lại',
      loginRequired: 'Cần đăng nhập',
      login: 'Đăng nhập',
      enterPhoneNumber: 'Nhập số điện thoại của bạn',
      phoneNumberPlaceholder: 'Nhập số điện thoại của bạn',
      enterVerificationCode: 'Nhập mã xác nhận',
      verificationCodeInstruction: 'Mã xác nhận đã được gửi đến điện thoại của bạn',
      privacyNote: 'Bằng cách nhấp Gửi mã, bạn đồng ý với điều khoản dịch vụ và chính sách bảo mật của chúng tôi',
      invalidPhoneNumber: 'Số điện thoại không hợp lệ',
      tooManyRequests: 'Quá nhiều lần thử. Vui lòng thử lại sau',
      smsError: 'Lỗi gửi tin nhắn SMS',
      sessionError: 'Lỗi phiên làm việc',
      secondFactorRequired: 'Cần có yếu tố xác nhận thứ hai',
      verificationFailed: 'Xác nhận thất bại'
    },
    welcome: {
      title: 'Chào mừng đến với Doppi',
      description: 'Hãy bắt đầu cuộc trò chuyện'
    },
    buttons: {
      call: 'Gọi',
      date: 'Hẹn hò',
      close: 'Đóng',
      save: 'Lưu',
      inquiry: 'Hỏi đáp',
      history: 'Lịch sử',
      myPage: 'Trang của tôi',
      end: 'Kết thúc',
      line: 'Hỏi trên LINE'
    },
    inquiry: {
      message: 'Đừng ngại đặt câu hỏi nhé!'
    },
    history: {
      noHistory: 'Không có lịch sử trò chuyện'
    },
    messages: {
      quietPlace: 'Vui lòng thử ở nơi yên tĩnh vì AI sẽ thu âm thanh xung quanh'
    }
  },
  zh: {
    menu: {
      home: '首页',
      myPage: '我的页面',
      signOut: '退出登录'
    },
    auth: {
      phoneNumber: '电话号码',
      verificationCode: '验证码',
      sendCode: '发送验证码',
      verify: '验证',
      back: '返回',
      loginRequired: '需要登录',
      login: '登录',
      enterPhoneNumber: '输入您的电话号码',
      phoneNumberPlaceholder: '输入您的电话号码',
      enterVerificationCode: '输入验证码',
      verificationCodeInstruction: '验证码已发送至您的手机',
      privacyNote: '点击发送验证码，即表示您同意服务条款和隐私政策',
      invalidPhoneNumber: '无效的手机号码',
      tooManyRequests: '尝试次数过多。请稍后再试',
      smsError: '发送短信错误',
      sessionError: '会话错误',
      secondFactorRequired: '需要第二个验证因素',
      verificationFailed: '验证失败'
    },
    welcome: {
      title: '欢迎来到Doppi',
      description: '让我们开始对话吧'
    },
    buttons: {
      call: '通话',
      date: '约会',
      close: '关闭',
      save: '保存',
      inquiry: '咨询',
      history: '历史记录',
      myPage: '我的页面',
      end: '结束',
      line: '在LINE上询问'
    },
    inquiry: {
      message: '请随时提出任何问题！'
    },
    history: {
      noHistory: '没有对话历史'
    },
    messages: {
      quietPlace: '由于AI会捕捉环境声音，请在安静的地方尝试'
    }
  }
}; 