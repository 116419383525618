import { supabase } from '../lib/supabase';
import { AssistantDataType } from '../types/Assistant';

export async function getAssistants(mode: string): Promise<AssistantDataType[]> {
    try {
        console.log('Fetching assistants for mode:', mode);

        const { data: assistants, error } = await supabase
            .from('assistants')
            .select('*, mode_id');

        if (error) {
            console.error('Error fetching assistants:', error);
            throw error;
        }

        console.log('Fetched assistants:', assistants);

        const typedAssistants: AssistantDataType[] = (assistants || []).map(a => ({
            ...a,
            voice: a.voice as "alloy" | "echo" | "shimmer" | undefined
        }));

        return typedAssistants;
    } catch (error) {
        console.error('Unexpected error:', error);
        throw error;
    }
} 