export function getCountryFlag(countryCode: string): string {
  if (!countryCode) return '';
  
  // 国コードを地域指示子に変換（アルファベット2文字を地域の Unicode に変換）
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt(0));
  
  // Unicode の文字列に変換
  return String.fromCodePoint(...codePoints);
}

export const SUPPORTED_COUNTRIES = {
  AR: 'ar',
  AU: 'au',
  BR: 'br',
  CA: 'ca',
  CH: 'ch',
  CN: 'cn',
  DE: 'de',
  DK: 'dk',
  ES: 'es',
  FI: 'fi',
  FR: 'fr',
  GB: 'gb',
  ID: 'id',
  IE: 'ie',
  IL: 'il',
  IN: 'in',
  IT: 'it',
  JP: 'jp',
  KR: 'kr',
  MX: 'mx',
  MY: 'my',
  NO: 'no',
  NZ: 'nz',
  PH: 'ph',
  RU: 'ru',
  SA: 'sa',
  SE: 'se',
  SG: 'sg',
  TH: 'th',
  TR: 'tr',
  US: 'us',
  VN: 'vn',
  ZA: 'za'
} as const;

export type CountryCode = keyof typeof SUPPORTED_COUNTRIES;

export function getCountryPath(countryCode: string): string {
  const normalizedCode = countryCode.toUpperCase();
  if (normalizedCode in SUPPORTED_COUNTRIES) {
    return `/${SUPPORTED_COUNTRIES[normalizedCode as CountryCode]}`;
  }
  return '/us'; // デフォルトは US
}

export function getCountryFromPath(path: string): CountryCode {
  const countryPath = path.split('/')[1]; // パスの最初のセグメントを取得
  const countryEntry = Object.entries(SUPPORTED_COUNTRIES).find(
    ([_, value]) => value === countryPath
  );
  return (countryEntry?.[0] || 'US') as CountryCode;
}

export function getLanguageFromCountry(countryCode: string): string {
  const languageMapping: { [key: string]: string } = {
    'AR': 'es',
    'AU': 'en',
    'BR': 'pt',
    'CA': 'en',
    'CH': 'de',
    'CN': 'zh',
    'DE': 'de',
    'DK': 'en',
    'FI': 'en',
    'FR': 'fr',
    'GB': 'en',
    'ID': 'id',
    'IE': 'en',
    'IL': 'ar',
    'IN': 'en',
    'IT': 'it',
    'JP': 'ja',
    'KR': 'ko',
    'MX': 'es',
    'MY': 'en',
    'NO': 'en',
    'NZ': 'en',
    'PH': 'en',
    'RU': 'ru',
    'SA': 'ar',
    'SE': 'en',
    'SG': 'en',
    'TH': 'th',
    'TR': 'tr',
    'US': 'en',
    'ZA': 'en'
  };

  return languageMapping[countryCode.toUpperCase()] || 'en';
} 