import { useEffect, useState } from 'react';
import { translations } from '../i18n/translations';
import { supabase } from '../utils/supabaseClient';

type Language = 'ar' | 'de' | 'en' | 'es' | 'fr' | 'hi' | 'id' | 'it' | 'ja' | 'ko' | 'nl' | 'pt' | 'ru' | 'th' | 'tr' | 'vi' | 'zh';

type TranslationValue = string | {
  [key: string]: TranslationValue;
};

export function useTranslation(userId: string | undefined) {
  const [language, setLanguage] = useState<Language>('en');

  useEffect(() => {
    if (!userId) return;

    const fetchUserLanguage = async () => {
      const { data, error } = await supabase
        .from('users')
        .select('language')
        .eq('clerk_id', userId)
        .single();

      if (!error && data?.language) {
        setLanguage(data.language as Language);
      }
    };

    fetchUserLanguage();
  }, [userId]);

  const t = (key: string): string => {
    const keys = key.split('.');
    const lang = translations[language] ?? translations.en ?? {};
    
    let value: TranslationValue = lang as TranslationValue;
    
    for (const k of keys) {
      if (typeof value === 'object' && k in value) {
        value = value[k];
      } else {
        const fallback = translations.en ?? {};
        let fallbackValue: TranslationValue = fallback as TranslationValue;
        
        for (const fk of keys) {
          if (typeof fallbackValue === 'object' && fk in fallbackValue) {
            fallbackValue = fallbackValue[fk];
          } else {
            return key;
          }
        }
        
        return typeof fallbackValue === 'string' ? fallbackValue : key;
      }
    }
    
    return typeof value === 'string' ? value : key;
  };

  return { t, language };
} 