import { useState, useEffect } from 'react';
import { useSignIn, useSignUp } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import './CustomSignInPage.scss';
import ReactGA from 'react-ga4';
import { CountryProps } from '../types/props';
import { supabase } from '../lib/supabase';
import { getCountryFlag } from '../utils/country';
import { CountryCode } from '../types/country';
import { getCountryFromIP } from '../utils/geolocation';
import { useTranslation } from 'react-i18next';
import { TranslationKeys } from '../i18n/translations';

interface CustomSignInPageProps extends CountryProps {
  redirectUrl?: string;
}

// 国コードと電話番号の国際コードのマッピング
const COUNTRY_PHONE_CODES: { [K in CountryCode]: string } = {
  AR: '54',
  AU: '61',
  BR: '55',
  CA: '1',
  CH: '41',
  CN: '86',
  DE: '49',
  DK: '45',
  ES: '34',
  FI: '358',
  FR: '33',
  GB: '44',
  ID: '62',
  IE: '353',
  IL: '972',
  IN: '91',
  IT: '39',
  JP: '81',
  KR: '82',
  MX: '52',
  MY: '60',
  NO: '47',
  NZ: '64',
  PH: '63',
  RU: '7',
  SA: '966',
  SE: '46',
  SG: '65',
  TH: '66',
  TR: '90',
  TW: '886',
  US: '1',
  VN: '84',
  ZA: '27'
};

// 特別モードの定義を追加
const SPECIAL_MODES = ['soka', 'christian', 'ozen', 'mise'];

export function CustomSignInPage({ countryCode: initialCountryCode, redirectUrl }: CustomSignInPageProps) {
  const { isLoaded: isSignInLoaded, signIn, setActive } = useSignIn();
  const { isLoaded: isSignUpLoaded, signUp } = useSignUp();
  const [verifying, setVerifying] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isSignUpFlow, setIsSignUpFlow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  
  // 国コードの初期値を props から設定
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>(
    initialCountryCode in COUNTRY_PHONE_CODES ? initialCountryCode : 'US'
  );
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);

  const isLoaded = isSignInLoaded && isSignUpLoaded;

  const { i18n, t } = useTranslation();

  const getTranslatedError = (key: keyof TranslationKeys['auth']): string => {
    const translationKey = `auth.${key}` as const;
    const translated = t(translationKey);
    return typeof translated === 'string' ? translated : '';
  };

  useEffect(() => {
    const initializeCountry = async () => {
      try {
        if (initialCountryCode && initialCountryCode in COUNTRY_PHONE_CODES) {
          setSelectedCountry(initialCountryCode as CountryCode);
          return;
        }

        const result = await getCountryFromIP();
        if (result.countryCode in COUNTRY_PHONE_CODES) {
          setSelectedCountry(result.countryCode as CountryCode);
        }
      } catch (error) {
        console.error('Error detecting country:', error);
      }
    };

    initializeCountry();
  }, [initialCountryCode]);

  useEffect(() => {
    // 国コードに基づいて言語を設定
    const languageMap: Record<CountryCode, string> = {
      JP: 'ja',
      US: 'en',
      GB: 'en',
      KR: 'ko',
      CN: 'zh',
      SG: 'en',
      AU: 'en',
      CA: 'en',
      FR: 'fr',
      DE: 'de',
      IT: 'it',
      BR: 'pt',
      IN: 'en',
      AR: 'es',
      CH: 'de',
      DK: 'da',
      FI: 'fi',
      ID: 'id',
      IE: 'en',
      IL: 'he',
      MX: 'es',
      MY: 'ms',
      NO: 'no',
      NZ: 'en',
      PH: 'en',
      RU: 'ru',
      SA: 'ar',
      SE: 'sv',
      TH: 'th',
      TR: 'tr',
      VN: 'vi',
      ZA: 'en',
      ES: 'es',
      TW: 'zh'
    };

    const language = languageMap[initialCountryCode] || 'en';
    i18n.changeLanguage(language);
  }, [initialCountryCode, i18n]);

  const handlePhoneSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isLoaded || !signIn || !signUp) return;

    try {
      ReactGA.event({
        category: 'Auth',
        action: 'Phone Submit',
        label: 'Phone Verification Request'
      });

      const cleanedNumber = phoneNumber.replace(/[^0-9]/g, '');
      const formattedNumber = cleanedNumber.replace(/^0+/, '');
      
      if (formattedNumber.length < 8 || formattedNumber.length > 15) {
        setError(getTranslatedError('invalidPhoneNumber'));
        return;
      }
      
      const fullPhoneNumber = `+${COUNTRY_PHONE_CODES[selectedCountry]}${formattedNumber}`;
      
      try {
        // まずサインイン
        const { supportedFirstFactors } = await signIn.create({
          identifier: fullPhoneNumber,
        });

        const phoneCodeFactor = supportedFirstFactors.find(
          factor => factor.strategy === "phone_code"
        );

        if (phoneCodeFactor && 'phoneNumberId' in phoneCodeFactor) {
          await signIn.prepareFirstFactor({
            strategy: "phone_code",
            phoneNumberId: phoneCodeFactor.phoneNumberId
          });

          setVerifying(true);
          setIsSignUpFlow(false);
          setError("");
        } else {
          setError("この電話番号ではサインインできません");
        }
      } catch (signInErr: any) {
        const errorCode = signInErr.errors?.[0]?.code;
        const errorMessage = signInErr.errors?.[0]?.message || "サインインに失敗しました";

        console.error("SignIn Error:", signInErr);

        if (errorCode === 'too_many_requests') {
          setError(getTranslatedError('tooManyRequests'));
          return;
        }

        if (errorCode === 'sms_provider_error') {
          setError(getTranslatedError('smsError'));
          return;
        }

        // form_identifier_not_found -> 未登録 -> サインアップ
        if (errorCode === 'form_identifier_not_found') {
          try {
            await signUp.create({
              phoneNumber: fullPhoneNumber
            });

            await signUp.preparePhoneNumberVerification();
            setVerifying(true);
            setIsSignUpFlow(true);
            setError("");
          } catch (signUpErr: any) {
            console.error("SignUp Error:", signUpErr);

            const signUpErrorCode = signUpErr.errors?.[0]?.code;
            const signUpErrorMessage = signUpErr.errors?.[0]?.message || "サインアップに失敗しました";

            if (signUpErrorCode === 'too_many_requests') {
              setError("アクセスが集中しています。しばらくお待ちください");
            } else if (signUpErrorCode === 'form_identifier_exists') {
              setError("この電話番号は既に登録されています");
            } else if (signUpErrorCode === 'form_param_format_invalid') {
              setError("電話番号の形式が正しくありません");
            } else {
              setError(`エラーが発生しました: ${signUpErrorMessage}`);
            }
          }
        } else {
          setError(`エラーが発生しました: ${errorMessage}`);
        }
      }
    } catch (err: any) {
      console.error("General Error:", err);
      const generalErrorMessage = err.errors?.[0]?.message || "予期せぬエラーが発生しました。もう一度お試しください";
      setError(generalErrorMessage);
    }
  };

  const handleCodeSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isLoaded || !signIn || !signUp) return;

    try {
      ReactGA.event({
        category: 'Auth',
        action: 'Code Submit',
        label: isSignUpFlow ? 'SignUp' : 'SignIn'
      });

      let sessionId: string | null = null;

      if (!isSignUpFlow) {
        // サインイン
        const result = await signIn.attemptFirstFactor({
          strategy: "phone_code",
          code,
        });

        if (result.status === "complete") {
          if (!result.createdSessionId) {
            setError(t('auth.sessionError'));
            return;
          }
          await setActive({ session: result.createdSessionId });
          sessionId = result.createdSessionId;
        } else if (result.status === "needs_second_factor") {
          setError(t('auth.secondFactorRequired'));
          return;
        } else {
          setError(t('auth.verificationFailed'));
          return;
        }
      } else {
        // サインアップ
        const completeSignUp = await signUp.attemptPhoneNumberVerification({
          code,
        });

        if (completeSignUp.status === "complete") {
          if (!completeSignUp.createdSessionId) {
            setError("セッションの作成に失敗しました");
            return;
          }
          await setActive({ session: completeSignUp.createdSessionId });
          sessionId = completeSignUp.createdSessionId;
        } else {
          setError("認証に失敗しました。もう一度お試しください");
          return;
        }
      }

      if (!sessionId) {
        setError("セッションの作成に失敗しました");
        return;
      }

      // 現在のパスから特別モードを判断
      const pathParts = window.location.pathname.split('/');
      const isSpecialMode = SPECIAL_MODES.includes(pathParts[2]);

      // サインアップの場合のみニックネーム設定へ
      if (isSignUpFlow) {
        if (isSpecialMode) {
          navigate(`/${initialCountryCode.toLowerCase()}/${pathParts[2]}/setup-nickname`);
        } else {
          navigate(`/${initialCountryCode.toLowerCase()}/setup-nickname`);
        }
      } else {
        // ログインの場合は直接リダイレクト
        if (isSpecialMode) {
          navigate(`/${initialCountryCode.toLowerCase()}/${pathParts[2]}`);
        } else {
          navigate(`/${initialCountryCode.toLowerCase()}`);
        }
      }

    } catch (err: any) {
      console.error("Error:", err);

      const errorCode = err.errors?.[0]?.code;
      const errorMessage = err.errors?.[0]?.message || "認証に失敗しました";

      if (errorCode === 'code_incorrect' || errorCode === 'code_expired') {
        setError("認証コードが正しくありません");
      } else if (errorCode === 'too_many_requests') {
        setError("しばらくお待ちください");
      } else {
        setError(`エラーが発生しました: ${errorMessage}`);
      }
    }
  };

  const handleBack = () => {
    if (verifying) {
      setVerifying(false);
      setCode("");
      setError("");
    } else {
      navigate(-1);
    }

    ReactGA.event({
      category: 'Navigation',
      action: 'Back Button',
      label: verifying ? 'From Verification' : 'From Phone Input'
    });
  };

  const renderCountrySelector = () => (
    <div className="country-selector">
      <button
        type="button"
        className="country-select-button"
        onClick={() => setIsCountryDropdownOpen(!isCountryDropdownOpen)}
      >
        <span className="flag">{getCountryFlag(selectedCountry)}</span>
        <span className="code">+{COUNTRY_PHONE_CODES[selectedCountry]}</span>
        <span className="arrow">▼</span>
      </button>
      {isCountryDropdownOpen && (
        <div className="country-dropdown">
          {(Object.entries(COUNTRY_PHONE_CODES) as [CountryCode, string][]).map(([code, phoneCode]) => (
            <button
              key={code}
              type="button"
              className={`country-option ${selectedCountry === code ? 'selected' : ''}`}
              onClick={() => {
                setSelectedCountry(code);
                setIsCountryDropdownOpen(false);
              }}
            >
              <span className="flag">{getCountryFlag(code)}</span>
              <span className="name">{code}</span>
              <span className="code">+{phoneCode}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );

  const handleLoginClick = () => {
    // 現在のパスから特別モードを判断
    const pathParts = window.location.pathname.split('/');
    const isSpecialMode = SPECIAL_MODES.includes(pathParts[2]);
    
    // 特別モードの場合は、そのモードのサインインページにリダイレクト
    if (isSpecialMode) {
      navigate(`/${initialCountryCode.toLowerCase()}/${pathParts[2]}/sign-in`);
    } else {
      navigate(`/${initialCountryCode.toLowerCase()}/sign-in`);
    }
  };

  if (!isLoaded) {
    return null; 
  }

  return (
    <div className="custom-sign-in-page">
      <button onClick={handleBack} className="back-button" aria-label={t('auth.back')}>
        ←
      </button>
      {!verifying ? (
        <form onSubmit={handlePhoneSubmit} className="phone-form">
          <h2>{t('auth.enterPhoneNumber')}</h2>
          <div className="phone-input-container">
            <div className="phone-input-row">
              {renderCountrySelector()}
              <div className="phone-input-wrapper">
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder={t('auth.phoneNumberPlaceholder') || "Phone Number"}
                  className="phone-input"
                  aria-label={t('auth.enterPhoneNumber')}
                />
              </div>
            </div>
          </div>
          <button type="submit" className="submit-button">{t('auth.sendCode')}</button>
          {error && <div className="error" role="alert">{error}</div>}
          <p className="privacy-note small-text">
            {t('auth.privacyNote')}
          </p>
        </form>
      ) : (
        <form onSubmit={handleCodeSubmit} className="code-form">
          <h2>{t('auth.verificationCode')}</h2>
          <p>{t('auth.verificationCodeInstruction')}</p>
          <input
            type="text"
            maxLength={6}
            value={code}
            onChange={(e) => setCode(e.target.value.replace(/\D/g, ''))}
            placeholder="123456"
            aria-label={t('auth.verificationCode')}
          />
          <button type="submit" className="submit-button">{t('auth.verify')}</button>
          {error && <div className="error" role="alert">{error}</div>}
        </form>
      )}
    </div>
  );
}
