import { useEffect } from 'react';
import '../styles/TermsPage.scss';

export function TermsPage({ countryCode }: { countryCode: string }) {
  const isJapanese = countryCode.toLowerCase() === 'jp';

  useEffect(() => {
    document.body.style.background = ''; // 他のページの背景スタイルをリセット
  }, []);

  return (
    <div className="terms-container">
      <div className="terms-content">
        <h1>{isJapanese ? '利用規約' : 'Terms of Service'}</h1>

        {isJapanese ? (
          // 日本語の利用規約全文
          <>
            <h2>Bar Doppi - 利用規約</h2>
            <p>最終更新日: 2024年12月13日</p>

            <h3>1. はじめに</h3>
            <p>
              Bar Doppi（以下、「当社」または「当社サービス」といいます）へようこそ。当社は、ユーザーがAIコンパニオンとリアルタイムで音声・ビデオを通じて交流できる「バーチャルバー」サービス（以下、「本サービス」）を提供します。
              本サービスにアクセスまたは利用することで、あなた（以下、「ユーザー」）は本利用規約（以下、「本規約」）に同意したものとみなされます。同意いただけない場合は、本サービスを利用しないでください。
            </p>

            <h3>2. 利用資格</h3>
            <p>
              ユーザーは本サービスを利用するにあたり、16歳以上である必要があります。18歳未満の場合は、保護者または法定代理人の許可を得てください。
            </p>

            <h3>3. アカウント登録およびセキュリティ</h3>
            <p>
              アカウント登録の際は正確な情報を提供してください。アカウント下で行われた一切の行為については、ユーザーが責任を負います。ログイン情報は第三者へ開示せず、不正利用が疑われる場合は速やかに当社へ連絡してください。
            </p>

            <h3>4. サービスの利用方法</h3>
            <p>
              (a) 個人利用：本サービスは、個人的かつ非商業的利用を目的としています。<br />
              (b) 品行遵守：本サービスを利用して、ハラスメント、ヘイトスピーチ、違法行為、有害または不快なコンテンツの送信を行ってはなりません。<br />
              (c) 法令遵守：本サービス利用において、適用される法令・規制を遵守してください。
            </p>

            <h3>5. 料金および支払い</h3>
            <p>
              1日あたり3分までは本サービスを無料で利用できます。それ以降は有料となる場合があります。支払いは返金不可です。当社は料金体系を予告なく変更できるものとします。
            </p>

            <h3>6. 知的財産権</h3>
            <p>
              本サービスおよび関連するすべての知的財産権は、当社（または当社へライセンスを許諾している者）に帰属します。ユーザーは本サービスのリバースエンジニアリングや不正利用を行ってはなりません。ユーザーが作成または提供したコンテンツに関する権利はユーザーに留保されますが、本サービス運営・改善・宣伝のため、当社はそのコンテンツを世界的・非独占的・ロイヤリティフリーで利用（複製、改変、表示、配布など）する権利を取得します。
            </p>

            <h3>7. プライバシー</h3>
            <p>
              当社は、ユーザーのプライバシーを尊重します。個人情報の取り扱いについては、プライバシーポリシーをご覧ください。本サービスを利用することで、ユーザーは当社のデータ取扱い方法に同意したものとみなされます。
            </p>

            <h3>8. 第三者サービス</h3>
            <p>
              本サービスには、第三者ウェブサイトやサービスへのリンクが含まれる場合があります。当社はそれらのコンテンツ、方針、慣行について責任を負いません。利用は自己責任で行ってください。
            </p>

            <h3>9. 保証の否認および責任制限</h3>
            <p>
              本サービスは「現状有姿」で提供され、いかなる保証も伴いません。当社は、本サービスの利用に起因または関連して生じる間接的、付随的、結果的損害について責任を負いません。当社の責任の最大限度額は、ユーザーが当社に支払った額または100米ドルのいずれか大きい方を上限とします。
            </p>

            <h3>10. 利用停止・終了</h3>
            <p>
              当社は、理由の如何を問わず、いつでもユーザーのアカウントや本サービスへのアクセスを停止または終了することができます。終了時点までに発生した料金支払義務は継続します。
            </p>

            <h3>11. 規約の変更</h3>
            <p>
              当社は、本規約を随時変更することができます。変更後に本サービスを利用し続けることで、ユーザーは更新後の本規約に同意したものとみなされます。
            </p>

            <h3>12. 準拠法および紛争解決</h3>
            <p>
              本規約は、カリフォルニア州法に準拠し、同法に従って解釈されます（抵触法原則は適用しません）。本規約または本サービス利用に関連して生じる紛争は、カリフォルニア州内での個別的な拘束力ある仲裁によって解決されるものとします。ユーザーは集団訴訟または集団仲裁への参加権を放棄します。
            </p>

            <h3>13. お問い合わせ</h3>
            <p>
              本サービスに関するご質問・ご不明点は、下記までご連絡ください：<br />
              <a href="mailto:keiji@doppi.ai">keiji@doppi.ai</a>
            </p>
          </>
        ) : (
          // 英語の利用規約全文
          <>
            <h2>Bar Doppi - Terms of Service</h2>
            <p>Last updated: December 13, 2024</p>

            <h3>1. Introduction</h3>
            <p>
              Welcome to Bar Doppi (“we,” “us,” or “our”). By accessing or using our virtual bar service (“Service”), where you can communicate with AI companions in real-time via audio or video, you agree to these Terms of Service (“Terms”). If you do not agree, please do not use the Service.
            </p>

            <h3>2. Eligibility</h3>
            <p>
              You must be at least 16 years old to use the Service. If you are under 18, you must have permission from a parent or guardian.
            </p>

            <h3>3. Account Registration & Security</h3>
            <p>
              You must provide accurate information when creating an account. You are responsible for all activity under your account. Keep your login credentials confidential and notify us immediately if you suspect unauthorized use.
            </p>

            <h3>4. Use of the Service</h3>
            <p>
              (a) Personal Use Only: The Service is for personal, non-commercial use.<br />
              (b) Respectful Conduct: Do not use the Service for harassment, hate speech, illegal activities, or to distribute harmful or objectionable content.<br />
              (c) Compliance with Law: You agree to follow all applicable laws and regulations.
            </p>

            <h3>5. Payment & Fees</h3>
            <p>
              You may use the Service free for up to 3 minutes per day. After that, a fee may apply. All fees are non-refundable. We may adjust our pricing at any time.
            </p>

            <h3>6. Intellectual Property</h3>
            <p>
              We (and our licensors) own all intellectual property rights related to the Service. You agree not to reverse engineer or misuse any portion of the Service. You retain ownership of any content you create or provide. By using the Service, you grant us a non-exclusive, worldwide, royalty-free license to use your content for operating, improving, and promoting the Service.
            </p>

            <h3>7. Privacy</h3>
            <p>
              Please review our Privacy Policy to understand how we handle your information. By using the Service, you consent to our data practices.
            </p>

            <h3>8. Third-Party Services</h3>
            <p>
              Our Service may link to third-party sites or services. We are not responsible for their content, policies, or practices. Use them at your own risk.
            </p>

            <h3>9. Disclaimer of Warranties & Limitation of Liability</h3>
            <p>
              The Service is provided “as is” without warranties of any kind. We are not liable for indirect, incidental, or consequential damages arising from your use of the Service. Our maximum liability is limited to the amount you paid us, or US$100, whichever is less.
            </p>

            <h3>10. Termination</h3>
            <p>
              We may suspend or terminate your account or access to the Service at any time, for any reason. Termination does not release you from any payment obligations accrued before the termination date.
            </p>

            <h3>11. Changes to the Terms</h3>
            <p>
              We may update these Terms at any time. By continuing to use the Service after changes become effective, you accept the revised Terms.
            </p>

            <h3>12. Governing Law & Dispute Resolution</h3>
            <p>
              These Terms are governed by and interpreted according to the laws of the State of California, U.S.A., without regard to its conflict of law principles. You agree that any disputes arising from these Terms or your use of the Service will be resolved through binding arbitration on an individual basis in California. You waive any right to participate in class actions or class arbitration.
            </p>

            <h3>13. Contact Us</h3>
            <p>
              If you have any questions or concerns, please contact us at:<br />
              <a href="mailto:keiji@doppi.ai">keiji@doppi.ai</a>
            </p>
          </>
        )}
      </div>
    </div>
  );
}
