import i18n from 'i18next';
import { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

// リソースの型定義
export type TranslationKeys = {
  auth: {
    phoneNumber: string;
    verificationCode: string;
    sendCode: string;
    verify: string;
    back: string;
    loginRequired: string;
    login: string;
    enterPhoneNumber: string;
    phoneNumberPlaceholder: string;
    enterVerificationCode: string;
    verificationCodeInstruction: string;
    privacyNote: string;
    invalidPhoneNumber: string;
    tooManyRequests: string;
    smsError: string;
    sessionError: string;
    secondFactorRequired: string;
    verificationFailed: string;
  };
  buttons: {
    call: string;
    end: string;
    close: string;
    history: string;
    inquiry: string;
    myPage: string;
    line: string;
    date: string;
    signUp: string;
    signIn: string;
  };
  inquiry: {
    message: string;
  };
  history: {
    noHistory: string;
  };
  messages: {
    quietPlace: string;
  };
};

interface Resources {
  [key: string]: {
    translation: TranslationKeys;
  };
}

// 訳リソース
const resources: Resources = {
  ar: {
    translation: {
      auth: {
        phoneNumber: 'رقم الهاتف',
        verificationCode: 'رمز التحقق',
        sendCode: 'إرسال رمز التحقق',
        verify: 'تحقق',
        back: 'رجوع',
        loginRequired: 'تسجيل الدخول مطلوب',
        login: 'تسجيل الدخول',
        enterPhoneNumber: 'أدخل رقم الهاتف',
        phoneNumberPlaceholder: 'أدخل رقم الهاتف',
        enterVerificationCode: 'أدخل رمز التحقق',
        verificationCodeInstruction: 'أدخل الرمز المكون من 6 أرقام المرسل إليك عبر الرسائل النصية',
        privacyNote: 'رقم هاتفك سيستخدم فقط للتحقق من هويتك وحماية حسابك.',
        invalidPhoneNumber: 'الرجاء إدخال رقم هاتف صحيح',
        tooManyRequests: 'الرجاء المحاولة مرة أخرى بعد فترة',
        smsError: 'فشل إرسال الرسالة النصية. الرجاء المحاولة مرة أخرى بعد فترة',
        sessionError: 'فشل إنشاء الجلسة',
        secondFactorRequired: 'مطلوب تحقق إضافي',
        verificationFailed: 'فشل التحقق. الرجاء المحاولة مرة أخر'
      },
      buttons: {
        call: 'اختيار',
        end: 'إنهاء المكالمة',
        close: 'إغلاق',
        history: 'السجل',
        inquiry: 'الاستفسار',
        myPage: 'صفحتي',
        line: 'الاتصال عبر LINE',
        date: 'طلب موعد',
        signUp: 'تسجيل جديد',
        signIn: 'تسجيل الدخول'
      },
      inquiry: {
        message: 'إذا كان لديك أي مشاكل أو طلبات، يرجى الاتصال بنا عبر LINE.'
      },
      history: {
        noHistory: 'لا يوجد سجل محادثات'
      },
      messages: {
        quietPlace: 'يرجى المحاولة في مكان هادئ لأن الذكاء الاصطناعي يلتقط الأصوات المحيطة'
      }
    }
  },
  de: {
    translation: {
      auth: {
        phoneNumber: 'Telefonnummer',
        verificationCode: 'Verifizierungscode',
        sendCode: 'Verifizierungscode senden',
        verify: 'Verifizieren',
        back: 'Zurück',
        loginRequired: 'Anmeldung erforderlich',
        login: 'Anmelden',
        enterPhoneNumber: 'Telefonnummer eingeben',
        phoneNumberPlaceholder: 'Telefonnummer eingeben',
        enterVerificationCode: 'Verifizierungscode eingeben',
        verificationCodeInstruction: 'Geben Sie den 6-stelligen Code ein, der Ihnen per SMS gesendet wurde',
        privacyNote: 'Ihre Telefonnummer wird nur zur Identitätsüberprüfung und zum Schutz Ihres Kontos verwendet.',
        invalidPhoneNumber: 'Bitte geben Sie eine gültige Telefonnummer ein',
        tooManyRequests: 'Bitte versuchen Sie es später noch einmal',
        smsError: 'SMS-Versand fehlgeschlagen. Bitte versuchen Sie es später noch einmal',
        sessionError: 'Sitzungserstellung fehlgeschlagen',
        secondFactorRequired: 'Zusätzliche Verifizierung erforderlich',
        verificationFailed: 'Verifizierung fehlgeschlagen. Bitte versuchen Sie es erneut'
      },
      buttons: {
        call: 'Auswählen',
        end: 'Anruf beenden',
        close: 'Schließen',
        history: 'Verlauf',
        inquiry: 'Anfrage',
        myPage: 'Meine Seite',
        line: 'Über LINE kontaktieren',
        date: 'Nach einem Date fragen',
        signUp: 'Neues Konto erstellen',
        signIn: 'Anmelden'
      },
      inquiry: {
        message: 'Bei Problemen oder Wünschen kontaktieren Sie uns bitte über LINE.'
      },
      history: {
        noHistory: 'Keine Gesprächshistorie'
      },
      messages: {
        quietPlace: 'Bitte versuchen Sie es an einem ruhigen Ort, da die KI auch Umgebungsgeräusche aufnimmt'
      }
    }
  },
  en: {
    translation: {
      auth: {
        phoneNumber: 'Phone number',
        verificationCode: 'Verification code',
        sendCode: 'Send verification code',
        verify: 'Verify',
        back: 'Back',
        loginRequired: 'Login required',
        login: 'Login',
        enterPhoneNumber: 'Enter phone number',
        phoneNumberPlaceholder: 'Enter phone number',
        enterVerificationCode: 'Enter verification code',
        verificationCodeInstruction: 'Enter the 6-digit code sent to you via SMS',
        privacyNote: 'Your phone number will be used only for identity verification and account protection.',
        invalidPhoneNumber: 'Please enter a valid phone number',
        tooManyRequests: 'Please try again later',
        smsError: 'SMS sending failed. Please try again later',
        sessionError: 'Session creation failed',
        secondFactorRequired: 'Additional verification required',
        verificationFailed: 'Verification failed. Please try again'
      },
      buttons: {
        call: 'Select',
        end: 'End Call',
        close: 'Close',
        history: 'History',
        inquiry: 'Contact',
        myPage: 'My Page',
        line: 'Contact via LINE',
        date: 'Ask for a Date',
        signUp: 'Sign Up',
        signIn: 'Sign In'
      },
      inquiry: {
        message: 'If you have any issues or requests, please contact us via LINE.'
      },
      history: {
        noHistory: 'No conversation history'
      },
      messages: {
        quietPlace: 'Please try in a quiet place as AI picks up surrounding sounds'
      }
    }
  },
  es: {
    translation: {
      auth: {
        phoneNumber: 'Número de teléfono',
        verificationCode: 'Código de verificación',
        sendCode: 'Enviar código de verificación',
        verify: 'Verificar',
        back: 'Atrás',
        loginRequired: 'Inicio de sesión requerido',
        login: 'Iniciar sesión',
        enterPhoneNumber: 'Ingrese el número de teléfono',
        phoneNumberPlaceholder: 'Ingrese el número de teléfono',
        enterVerificationCode: 'Ingrese el código de verificación',
        verificationCodeInstruction: 'Ingrese el código de 6 dígitos enviado a su teléfono',
        privacyNote: 'Su número de teléfono se utilizará solo para la verificación de identidad y la protección de su cuenta.',
        invalidPhoneNumber: 'Por favor, ingrese un número de teléfono válido',
        tooManyRequests: 'Por favor, inténtelo de nuevo más tarde',
        smsError: 'El envío de SMS falló. Por favor, inténtelo de nuevo más tarde',
        sessionError: 'Error al crear la sesión',
        secondFactorRequired: 'Se requiere verificación adicional',
        verificationFailed: 'La verificación falló. Por favor, inténtelo de nuevo'
      },
      buttons: {
        call: 'Seleccionar',
        end: 'Finalizar llamada',
        close: 'Cerrar',
        history: 'Historial',
        inquiry: 'Contacto',
        myPage: 'Mi página',
        line: 'Contactar por LINE',
        date: 'Pedir una cita',
        signUp: 'Registrarse',
        signIn: 'Iniciar sesión'
      },
      inquiry: {
        message: 'Si tiene algún problema o solicitud, contáctenos a través de LINE.'
      },
      history: {
        noHistory: 'No hay historial de conversación'
      },
      messages: {
        quietPlace: 'Por favor, intente en un lugar tranquilo ya que la IA capta los sonidos del entorno'
      }
    }
  },
  fr: {
    translation: {
      auth: {
        phoneNumber: 'Numéro de téléphone',
        verificationCode: 'Code de vérification',
        sendCode: 'Envoyer le code de vérification',
        verify: 'Vérifier',
        back: 'Retour',
        loginRequired: 'Connexion requise',
        login: 'Se connecter',
        enterPhoneNumber: 'Entrez le numéro de téléphone',
        phoneNumberPlaceholder: 'Entrez le numéro de téléphone',
        enterVerificationCode: 'Entrez le code de vérification',
        verificationCodeInstruction: 'Entrez le code à 6 chiffres envoyé par SMS',
        privacyNote: 'Votre numéro de téléphone sera utilisé uniquement pour la vérification d\'identité et la protection de votre compte.',
        invalidPhoneNumber: 'Veuillez entrer un numéro de téléphone valide',
        tooManyRequests: 'Veuillez réessayer plus tard',
        smsError: 'L\'envoi du SMS a échoué. Veuillez réessayer plus tard',
        sessionError: 'Échec de la création de la session',
        secondFactorRequired: 'Vérification supplémentaire requise',
        verificationFailed: 'La vérification a échoué. Veuillez réessayer'
      },
      buttons: {
        call: 'Sélectionner',
        end: 'Terminer l\'appel',
        close: 'Fermer',
        history: 'Historique',
        inquiry: 'Contact',
        myPage: 'Ma page',
        line: 'Contacter via LINE',
        date: 'Demander un rendez-vous',
        signUp: 'S\'inscrire',
        signIn: 'Se connecter'
      },
      inquiry: {
        message: 'Si vous avez des problèmes ou des demandes, veuillez nous contacter via LINE.'
      },
      history: {
        noHistory: 'Aucun historique de conversation'
      },
      messages: {
        quietPlace: 'Veuillez essayer dans un endroit calme car l\'IA capte les sons environnants'
      }
    }
  },
  hi: {
    translation: {
      auth: {
        phoneNumber: 'फ़ोन नंबर',
        verificationCode: 'सत्यापन कोड',
        sendCode: 'सत्यापन कोड भेजें',
        verify: 'सत्यापित करें',
        back: 'वापस',
        loginRequired: 'लॉगिन की आवश्यकता है',
        login: 'लॉग इन करें',
        enterPhoneNumber: 'फ़ोन नंबर दर्ज करें',
        phoneNumberPlaceholder: 'फ़ोन नंबर दर्ज करें',
        enterVerificationCode: 'सत्यापन कोड दर्ज करें',
        verificationCodeInstruction: 'SMS द्वारा भेजे गए 6 अंकों का कोड दर्ज करें',
        privacyNote: 'आपका फ़ोन नंबर केवल पहचान सत्यापन और अकाउंट सुरक्षा के लिए ही उपयोग किया जाएगा।',
        invalidPhoneNumber: 'कृपया एक मान्य फ़ोन नंबर दर्ज करें',
        tooManyRequests: 'कृपया बाद में पुन: प्रयास करें',
        smsError: 'SMS भेजने में विफल. कपया बाद में पुन: प्रयास करें',
        sessionError: 'सत्र बनाने में विफल',
        secondFactorRequired: 'अतिरिक्त सत्यापन आवश्यक',
        verificationFailed: 'सत्यापन विफल. कृपया पुन: प्रयास करें'
      },
      buttons: {
        call: 'अयन करें',
        end: 'कॉल समाप्त करें',
        close: 'बंद करें',
        history: 'इतिहास',
        inquiry: 'संपर्क',
        myPage: 'मेरा पेज',
        line: 'LINE के माध्यम से संपर्क करें',
        date: 'डेट के लिए पूछें',
        signUp: 'नया खाता बनाएं',
        signIn: 'साइन इन करें'
      },
      inquiry: {
        message: 'यदि आपको कोई समस्या या अनुरोध है, तो कृपया LINE के माध्यम से हमसे संपर्क करें।'
      },
      history: {
        noHistory: 'कोई वार्तालाप इतिहास नही'
      },
      messages: {
        quietPlace: 'कृपया शांत जगह प��� प्रयास करें क्योंकि AI आसपास की आवाजें भी पकड़ लेता है'
      }
    }
  },
  id: {
    translation: {
      auth: {
        phoneNumber: 'Nomor telepon',
        verificationCode: 'Kode verifikasi',
        sendCode: 'Kirim kode verifikasi',
        verify: 'Verifikasi',
        back: 'Kembali',
        loginRequired: 'Login diperlukan',
        login: 'Masuk',
        enterPhoneNumber: 'Masukkan nomor telepon',
        phoneNumberPlaceholder: 'Masukkan nomor telepon',
        enterVerificationCode: 'Masukkan kode verifikasi',
        verificationCodeInstruction: 'Masukkan kode 6 digit yang dikirim ke telepon Anda',
        privacyNote: 'Nomor telepon Anda hanya akan digunakan untuk verifikasi identitas dan keamanan akun.',
        invalidPhoneNumber: 'Masukkan nomor telepon yang valid',
        tooManyRequests: 'Coba lagi nanti',
        smsError: 'Pengiriman SMS gagal. Coba lagi nanti',
        sessionError: 'Pembuatan sesi gagal',
        secondFactorRequired: 'Verifikasi tambahan diperlukan',
        verificationFailed: 'Verifikasi gagal. Coba lagi'
      },
      buttons: {
        call: 'Pilih',
        end: 'Akhiri Panggilan',
        close: 'Tutup',
        history: 'Riwayat',
        inquiry: 'Kontak',
        myPage: 'Halaman Saya',
        line: 'Hubungi melalui LINE',
        date: 'Minta Kencan',
        signUp: 'Daftar Baru',
        signIn: 'Masuk'
      },
      inquiry: {
        message: 'Jika Anda memiliki masalah atau permintaan, silakan hubungi kami melalui LINE.'
      },
      history: {
        noHistory: 'Tidak ada riwayat percakapan'
      },
      messages: {
        quietPlace: 'Silakan coba di tempat yang tenang karena AI menangkap suara sekitar'
      }
    }
  },
  it: {
    translation: {
      auth: {
        phoneNumber: 'Numero di telefono',
        verificationCode: 'Codice di verifica',
        sendCode: 'Invia codice di verifica',
        verify: 'Verifica',
        back: 'Indietro',
        loginRequired: 'Accesso richiesto',
        login: 'Accedi',
        enterPhoneNumber: 'Inserisci il numero di telefono',
        phoneNumberPlaceholder: 'Inserisci il numero di telefono',
        enterVerificationCode: 'Inserisci il codice di verifica',
        verificationCodeInstruction: 'Inserisci il codice a 6 cifre inviato via SMS',
        privacyNote: 'Il tuo numero di telefono verrà utilizzato solo per la verifica dell\'identità e la protezione del tuo account.',
        invalidPhoneNumber: 'Inserisci un numero di telefono valido',
        tooManyRequests: 'Riprova più tardi',
        smsError: 'Invio SMS fallito. Riprova più tardi',
        sessionError: 'Creazione sessione fallita',
        secondFactorRequired: 'Verifica aggiuntiva richiesta',
        verificationFailed: 'Verifica fallita. Riprova'
      },
      buttons: {
        call: 'Selezionare',
        end: 'Termina Chiamata',
        close: 'Chiudi',
        history: 'Cronologia',
        inquiry: 'Contatto',
        myPage: 'La mia pagina',
        line: 'Contatta via LINE',
        date: 'Chiedi un appuntamento',
        signUp: 'Registrati',
        signIn: 'Accedi'
      },
      inquiry: {
        message: 'Se hai problemi o richieste, contattaci tramite LINE.'
      },
      history: {
        noHistory: 'Nessuna cronologia delle conversazioni'
      },
      messages: {
        quietPlace: 'Prova in un luogo tranquillo poiché l\'IA rileva i suoni circostanti'
      }
    }
  },
  ja: {
    translation: {
      auth: {
        phoneNumber: '電話番号',
        verificationCode: '認証コード',
        sendCode: '認証コードを送信',
        verify: '認証する',
        back: '戻る',
        loginRequired: 'ログインが必要です',
        login: 'ログイン',
        enterPhoneNumber: '電話番号を入力',
        phoneNumberPlaceholder: '電話番号を入力',
        enterVerificationCode: '認証コードを入力',
        verificationCodeInstruction: 'SMSに送信された6桁のコードを入力してください',
        privacyNote: 'お客様の電話番号は、本人確認やアカウント保護のみに使用されます。',
        invalidPhoneNumber: '正しい電話番号の形式で入力してください',
        tooManyRequests: 'しばらく時間をおいてから再度お試しください',
        smsError: 'SMSの送信に失敗しました。しばらく時間をおいてから再度お試しください',
        sessionError: 'セッションの作成に失敗しました',
        secondFactorRequired: '追加の認証が必要です',
        verificationFailed: '認証に失敗しました。もう一度お試しください'
      },
      buttons: {
        call: '指名する',
        end: '通話を終了',
        close: '閉じる',
        history: '履歴',
        inquiry: 'お問い合わせ',
        myPage: 'マイページ',
        line: 'LINEで問い合わせる',
        date: 'デートに誘う',
        signUp: '新規登録',
        signIn: 'ログイン'
      },
      inquiry: {
        message: '不具合やご要望がございましたら、LINEにてお問い合わせください。'
      },
      history: {
        noHistory: '会話履歴がありません'
      },
      messages: {
        quietPlace: 'AIが周りの音も拾ってしまうため静かな場所でお試しください'
      }
    }
  },
  ko: {
    translation: {
      auth: {
        phoneNumber: '전화번호',
        verificationCode: '인증 코드',
        sendCode: '인증 코드 보내기',
        verify: '인증하기',
        back: '뒤로',
        loginRequired: '로그인 필요',
        login: '로그인',
        enterPhoneNumber: '전화번호 입력',
        phoneNumberPlaceholder: '전화번호 입력',
        enterVerificationCode: '인증 코드 입력',
        verificationCodeInstruction: 'SMS로 전송된 6자리 코드를 입력하세요',
        privacyNote: '전화번호는 본인 확인 및 계정 보호를 위해서만 사용됩니다.',
        invalidPhoneNumber: '유효한 전화번호를 입력하세요',
        tooManyRequests: '잠시 후 다시 시도하세요',
        smsError: 'SMS 전송에 실패했습니다. 잠시 후 다시 시도하세요',
        sessionError: '세션 생성에 실패했습니다',
        secondFactorRequired: '추가 인증이 필요합니다',
        verificationFailed: '인증에 실패했습니다. 다시 시도하세요'
      },
      buttons: {
        call: '지하기',
        end: '통화 종료',
        close: '닫기',
        history: '기록',
        inquiry: '문의',
        myPage: '마이페이지',
        line: 'LINE으로 문의하기',
        date: '데이트 신청',
        signUp: '회원가입',
        signIn: '로그인'
      },
      inquiry: {
        message: '문제나 요청사항이 있으시면 LINE으로 문의해 주세요.'
      },
      history: {
        noHistory: '대화 기록이 없습니다'
      },
      messages: {
        quietPlace: 'AI가 주변 소리도 감지하므로 조용한 장소에서 시도해 주세요'
      }
    }
  },
  nl: {
    translation: {
      auth: {
        phoneNumber: 'Telefoonnummer',
        verificationCode: 'Verificatiecode',
        sendCode: 'Code versturen',
        verify: 'Verifiëren',
        back: 'Terug',
        loginRequired: 'Inloggen vereist',
        login: 'Inloggen',
        enterPhoneNumber: 'Voer telefoonnummer in',
        phoneNumberPlaceholder: 'Voer telefoonnummer in',
        enterVerificationCode: 'Voer verificatiecode in',
        verificationCodeInstruction: 'Voer de 6-cijferige code in die via SMS is verzonden',
        privacyNote: 'Uw telefoonnummer wordt alleen gebruikt voor verificatie en accountbeveiliging.',
        invalidPhoneNumber: 'Voer een geldig telefoonnummer in',
        tooManyRequests: 'Probeer het later opnieuw',
        smsError: 'SMS verzenden mislukt. Probeer het later opnieuw',
        sessionError: 'Sessie aanmaken mislukt',
        secondFactorRequired: 'Extra verificatie vereist',
        verificationFailed: 'Verificatie mislukt. Probeer het opnieuw'
      },
      buttons: {
        call: 'Selecteren',
        end: 'Beëindig Gesprek',
        close: 'Sluiten',
        history: 'Geschiedenis',
        inquiry: 'Contact',
        myPage: 'Mijn Pagina',
        line: 'Contact via LINE',
        date: 'Vraag om een Date',
        signUp: 'Registreren',
        signIn: 'Inloggen'
      },
      inquiry: {
        message: 'Als u problemen of verzoeken heeft, neem dan contact met ons op via LINE.'
      },
      history: {
        noHistory: 'Geen gespreksgeschiedenis'
      },
      messages: {
        quietPlace: 'Probeer het op een rustige plek omdat AI omgevingsgeluiden oppikt'
      }
    }
  },
  pt: {
    translation: {
      auth: {
        phoneNumber: 'Telefoonnummer',
        verificationCode: 'Verificatiecode',
        sendCode: 'Verstuur code',
        verify: 'Verifiëren',
        back: 'Terug',
        loginRequired: 'Inloggen vereist',
        login: 'Inloggen',
        enterPhoneNumber: 'Voer telefoonnummer in',
        phoneNumberPlaceholder: 'Voer telefoonnummer in',
        enterVerificationCode: 'Voer verificatiecode in',
        verificationCodeInstruction: 'Voer de 6-cijferige code in die via SMS is verzonden',
        privacyNote: 'Uw telefoonnummer wordt alleen gebruikt voor identiteitsverificatie en accountbeveiliging.',
        invalidPhoneNumber: 'Voer een geldig telefoonnummer in',
        tooManyRequests: 'Probeer het later opnieuw',
        smsError: 'SMS verzenden mislukt. Probeer het later opnieuw',
        sessionError: 'Sessie aanmaken mislukt',
        secondFactorRequired: 'Extra verificatie vereist',
        verificationFailed: 'Verificatie mislukt. Probeer het opnieuw'
      },
      buttons: {
        call: 'Selecteren',
        end: 'Beëindig Gesprek',
        close: 'Sluiten',
        history: 'Geschiedenis',
        inquiry: 'Contact',
        myPage: 'Mijn Pagina',
        line: 'Contact via LINE',
        date: 'Vraag om een Date',
        signUp: 'Registreren',
        signIn: 'Inloggen'
      },
      inquiry: {
        message: 'Als u problemen of verzoeken heeft, neem dan contact met ons op via LINE.'
      },
      history: {
        noHistory: 'Geen gespreksgeschiedenis'
      },
      messages: {
        quietPlace: 'Probeer het op een rustige plek omdat AI omgevingsgeluiden oppikt'
      }
    }
  },
  ru: {
    translation: {
      auth: {
        phoneNumber: 'Выбрать',
        verificationCode: 'Verificatiecode',
        sendCode: 'Verstuur code',
        verify: 'Verifiëren',
        back: 'Terug',
        loginRequired: 'Inloggen vereist',
        login: 'Inloggen',
        enterPhoneNumber: 'Voer telefoonnummer in',
        phoneNumberPlaceholder: 'Voer telefoonnummer in',
        enterVerificationCode: 'Voer verificatiecode in',
        verificationCodeInstruction: 'Voer de 6-cijferige code in die via SMS is verzonden',
        privacyNote: 'Uw telefoonnummer wordt alleen gebruikt voor identiteitsverificatie en accountbeveiliging.',
        invalidPhoneNumber: 'Voer een geldig telefoonnummer in',
        tooManyRequests: 'Probeer het later opnieuw',
        smsError: 'SMS verzenden mislukt. Probeer het later opnieuw',
        sessionError: 'Sessie aanmaken mislukt',
        secondFactorRequired: 'Extra verificatie vereist',
        verificationFailed: 'Verificatie mislukt. Probeer het opnieuw'
      },
      buttons: {
        call: 'Выбрать',
        end: 'Завершить звонок',
        close: 'Закрыть',
        history: 'История',
        inquiry: 'Связаться',
        myPage: 'Личный кабинет',
        line: 'Связаться через LINE',
        date: 'Предложить встречу',
        signUp: 'Зарегистрироваться',
        signIn: 'Войти'
      },
      inquiry: {
        message: 'Если у вас есть вопросы или пожелания, пожалуйста, свяжитесь с нами через LINE.'
      },
      history: {
        noHistory: 'История разговоров отсутствует'
      },
      messages: {
        quietPlace: 'Пожалуйста, попробуйте в тихом месте, так как ИИ улавливает окружающие звуки'
      }
    }
  },
  th: {
    translation: {
      auth: {
        phoneNumber: 'โือก',
        verificationCode: 'รหัสยืนยัน',
        sendCode: 'ส่งรหัสยืนยัน',
        verify: 'ยืนยัน',
        back: 'ย้อนกลับ',
        loginRequired: 'การเข้าสู่ระบบจำเปิด',
        login: 'เข้าสู่ระบบ',
        enterPhoneNumber: 'กรอกหมายเลขโทรศัพท์',
        phoneNumberPlaceholder: 'กรอกหมายเลขโทรศัพท์',
        enterVerificationCode: 'กรอกรหัสยืนยัน',
        verificationCodeInstruction: 'กรอกรหัสยืนยันที่ถูกส่งไปยังหมายเลข���ทรศัพท์ของคุณ',
        privacyNote: 'หมายเลขโทรศัพท์ของคุณจะใช้เพื่อยืนยันตัวตนและการป้องกันบัญชีของคุณเท่านั้น',
        invalidPhoneNumber: 'กรอกหมายเลขโทรศัพท์ที่ถูกต้อง',
        tooManyRequests: 'กรุณาลองใหม่ภายหลัง',
        smsError: 'การส่ง SMS ล้มเหลว. กรุณาลองใหม่ภายหลัง',
        sessionError: 'การสร้างช่องสัญญาณล้มเหลว',
        secondFactorRequired: 'การยืนยันเพิ่มจำเปิด',
        verificationFailed: 'ยืนยันล้มเหลว. กรุณาลองใหม่อีกครั้ง'
      },
      buttons: {
        call: 'โือก',
        end: 'จบการโทร',
        close: 'ปิด',
        history: 'ประวัติ',
        inquiry: 'ติดต่อ',
        myPage: 'หน้าของฉัน',
        line: 'ติดต่อผ่าน LINE',
        date: 'ขอนัด',
        signUp: 'ลงทะเบียน',
        signIn: 'เ��้าสู่ระบบ'
      },
      inquiry: {
        message: 'หากคุณมีปัญหาหรือคำขอใดๆ กรุณาติดต่อเราผ่าน LINE'
      },
      history: {
        noHistory: 'ไม่มีประวัติการสนทนา'
      },
      messages: {
        quietPlace: 'กรุณาลองในสถานที่เงียบเนื่องจาก AI จะรับเสียงรอบข้างด้วย'
      }
    }
  },
  tr: {
    translation: {
      auth: {
        phoneNumber: 'Seçmek',
        verificationCode: 'Doğrulama kodu',
        sendCode: 'Doğrulama kodu göndermek',
        verify: 'Doğrulamak',
        back: 'Geri',
        loginRequired: 'Giriş yapmanız gerekiyor',
        login: 'Giriş yap',
        enterPhoneNumber: 'Telefon numarasını girin',
        phoneNumberPlaceholder: 'Telefon numarasını girin',
        enterVerificationCode: 'Doğrulama kodunu girin',
        verificationCodeInstruction: 'SMS olarak gönderilen 6 rakamını girin',
        privacyNote: 'Telefon numaranız, kimlik doğrulama ve hesap koruma için kullanılacaktır.',
        invalidPhoneNumber: 'Lütfen geçerli bir telefon numarası girin',
        tooManyRequests: 'Lütfen daha sonra tekrar deneyin',
        smsError: 'SMS göndermek başarısız oldu. Lütfen daha sonra tekrar deneyin',
        sessionError: 'Oturum oluşturma başarısız oldu',
        secondFactorRequired: 'Ek doğrulama gerekiyor',
        verificationFailed: 'Doğrulama başarısız oldu. Lütfen tekrar deneyin'
      },
      buttons: {
        call: 'Seçmek',
        end: 'Aramayı Sonlandır',
        close: 'Kapat',
        history: 'Geçmiş',
        inquiry: 'İletişim',
        myPage: 'Sayfam',
        line: 'LINE üzerinden iletişim',
        date: 'Randevu İste',
        signUp: 'Kayıt Ol',
        signIn: 'Giriş Yap'
      },
      inquiry: {
        message: 'Herhangi bir sorun veya isteğiniz varsa, lütfen LINE üzerinden bizimle iletişime geçin.'
      },
      history: {
        noHistory: 'Konuşma geçmişi yok'
      },
      messages: {
        quietPlace: 'Lütfen sessiz bir yerde deneyin, çünkü AI çevredeki sesleri de alır'
      }
    }
  },
  vi: {
    translation: {
      auth: {
        phoneNumber: 'Chọn',
        verificationCode: 'Mã xác thực',
        sendCode: 'Gửi mã xác thực',
        verify: 'Xác thực',
        back: 'Quay lại',
        loginRequired: 'Bạn phải đăng nhập',
        login: 'Đăng nhập',
        enterPhoneNumber: 'Nhập số điện thoại',
        phoneNumberPlaceholder: 'Nhập số điện thoại',
        enterVerificationCode: 'Nhập mã xác thực',
        verificationCodeInstruction: 'Nhập mã 6 chữ số được gửi đến điện thoại của bạn',
        privacyNote: 'Số điện thoại của bạn sẽ được sử dụng để xác thực danh tính và bảo mật tài khoản của bạn.',
        invalidPhoneNumber: 'Vui lòng nhập số điện thoại hợp lệ',
        tooManyRequests: 'Vui lòng thử lại sau',
        smsError: 'Gửi SMS thất bại. Vui lòng thử lại sau',
        sessionError: 'Tạo phiên bắt đầu thất bại',
        secondFactorRequired: 'Xác thực bổ sung cần thiết',
        verificationFailed: 'Xác thực thất bại. Vui lòng thử lại'
      },
      buttons: {
        call: 'Chọn',
        end: 'Kết thúc Cuộc gọi',
        close: 'Đóng',
        history: 'Lịch sử',
        inquiry: 'Liên hệ',
        myPage: 'Trang của tôi',
        line: 'Liên hệ qua LINE',
        date: 'Yêu cầu Hẹn hò',
        signUp: 'Đăng ký',
        signIn: 'Đăng nhập'
      },
      inquiry: {
        message: 'Nếu bạn có vấn đề hoặc yêu cầu, vui lòng liên hệ với chúng tôi qua LINE.'
      },
      history: {
        noHistory: 'Không có lịch s��� trò chuyện'
      },
      messages: {
        quietPlace: 'Vui lòng thử ở nơi yên tĩnh vì AI cũng thu âm thanh xung quanh'
      }
    }
  },
  zh: {
    translation: {
      auth: {
        phoneNumber: '指定',
        verificationCode: '验证码',
        sendCode: '发送验证码',
        verify: '验证',
        back: '返回',
        loginRequired: '需要登录',
        login: '登录',
        enterPhoneNumber: '输入手机号码',
        phoneNumberPlaceholder: '输入手机号码',
        enterVerificationCode: '输入验证码',
        verificationCodeInstruction: '输入发送给您的6位验证码',
        privacyNote: '您的手机号码仅用于身份验证和账户保护。',
        invalidPhoneNumber: '请输入有效的手机号码',
        tooManyRequests: '请稍后再试',
        smsError: '发送短信失败。请稍后再试',
        sessionError: '创建会话失败',
        secondFactorRequired: '需要额外验证',
        verificationFailed: '验证失败。请再试一次'
      },
      buttons: {
        call: '指定',
        end: '结束通话',
        close: '关闭',
        history: '历史记录',
        inquiry: '联系',
        myPage: '我的页面',
        line: '通过LINE联系',
        date: '约会请求',
        signUp: '注册',
        signIn: '登录'
      },
      inquiry: {
        message: '如果您有任何问题或请求，请通过LINE与我们联系。'
      },
      history: {
        noHistory: '没有对话历史'
      },
      messages: {
        quietPlace: '由于AI会捕捉周围的声音，请在安静的地方尝试'
      }
    }
  }
};

const i18nConfig: InitOptions = {
  resources,
  lng: 'ja', // デフォルト言語
  fallbackLng: 'ja',
  interpolation: {
    escapeValue: false
  },
  supportedLngs: ['ar', 'de', 'en', 'es', 'fr', 'hi', 'id', 'it', 'ja', 'ko', 'nl', 'pt', 'ru', 'th', 'tr', 'vi', 'zh'],
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'navigator'],
    caches: ['localStorage', 'cookie']
  }
};

i18n
  .use(initReactI18next)
  .init(i18nConfig);

export default i18n; 