import { createClient, SupabaseClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL || '';
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY || '';

// データベースの型定義
interface Database {
  public: {
    Tables: {
      users: {
        Row: {
          id: string;
          clerk_id: string;
          nickname: string | null;
          country: string | null;
          language: string;
          created_at: string | null;
          updated_at: string | null;
          is_admin: boolean;
        };
        Insert: {
          id?: string;
          clerk_id: string;
          nickname?: string | null;
          country?: string | null;
          language?: string;
          created_at?: string | null;
          updated_at?: string | null;
          is_admin?: boolean;
        };
        Update: {
          id?: string;
          clerk_id?: string;
          nickname?: string | null;
          country?: string | null;
          language?: string;
          created_at?: string | null;
          updated_at?: string | null;
          is_admin?: boolean;
        };
      };
    };
  };
}

export const supabase = createClient<Database>(supabaseUrl, supabaseKey); 