import { Navigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';

interface AdminRouteProps {
  children: React.ReactNode;
}

export function AdminRoute({ children }: AdminRouteProps) {
  // 認証チェックを一時的に無効化し、すべてのユーザーにアクセスを許可
  return <>{children}</>;
} 