import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { Upload, Image as ImageIcon } from 'react-feather';
import { getCountryFlag, SUPPORTED_COUNTRIES } from '../utils/country';
import './SEOPage.scss';

// countryOptionsの型を定義
type CountryOptions = {
  [key: string]: string;
};

// countryOptionsの定義を修正
const countryOptions: CountryOptions = {
  AR: 'Argentina',
  AU: 'Australia', 
  BR: 'Brazil',
  CA: 'Canada',
  CH: 'Switzerland',
  CN: 'China',
  DE: 'Germany',
  DK: 'Denmark',
  FI: 'Finland',
  FR: 'France',
  GB: 'United Kingdom',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IN: 'India',
  IT: 'Italy',
  JP: 'Japan',
  KR: 'South Korea',
  MX: 'Mexico',
  MY: 'Malaysia',
  NO: 'Norway',
  NZ: 'New Zealand',
  PH: 'Philippines',
  RU: 'Russia',
  SA: 'Saudi Arabia',
  SE: 'Sweden',
  SG: 'Singapore',
  TH: 'Thailand',
  TR: 'Turkey',
  US: 'United States',
  ZA: 'South Africa'
};

interface Region {
  id: string;
  name: string;
  country_code: string;
}

interface SEOSettings {
  title: string;
  description: string;
  ogTitle: string;
  ogDescription: string;
}

export function SEOPage() {
  const [settings, setSettings] = useState<Record<string, SEOSettings>>({
    jp: {
      title: `Doppi - AI Assistant for ${countryOptions['JP']}`,
      description: `Experience natural conversations with AI assistants in ${countryOptions['JP']}.`,
      ogTitle: 'Doppi - Your AI Conversation Partner',
      ogDescription: 'Experience natural conversations with our AI assistants.'
    }
  });
  const [selectedCountry, setSelectedCountry] = useState('jp');
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [ogImageUrl, setOgImageUrl] = useState<string>('');
  const [regions, setRegions] = useState<Region[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null);
  const [regionSettings, setRegionSettings] = useState<Record<string, SEOSettings>>({});
  const [previewType, setPreviewType] = useState<'search' | 'social'>('search');

  const isRegionSettingsLoaded = Boolean(selectedRegion && regionSettings[selectedRegion]);

  useEffect(() => {
    loadSEOSettings();
  }, []);

  useEffect(() => {
    loadOgImage();
  }, [selectedCountry]);

  useEffect(() => {
    loadRegions();
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedRegion) {
      loadRegionSEOSettings();
    }
  }, [selectedRegion]);

  const getDefaultSettings = (countryCode: string): SEOSettings => ({
    title: `Doppi - AI Assistant for ${countryOptions[countryCode.toUpperCase()]}`,
    description: `Experience natural conversations with AI assistants in ${countryOptions[countryCode.toUpperCase()]}.`,
    ogTitle: 'Doppi - Your AI Conversation Partner',
    ogDescription: 'Experience natural conversations with our AI assistants.'
  });

  const loadSEOSettings = async () => {
    try {
      const { data, error } = await supabase
        .from('seo_settings')
        .select('*');

      if (error) throw error;

      const loadedSettings: Record<string, SEOSettings> = {};
      
      // デフォルト設定を先に追加
      Object.keys(countryOptions).forEach(code => {
        const lowerCode = code.toLowerCase();
        loadedSettings[lowerCode] = getDefaultSettings(lowerCode);
      });

      // データベースの設定で上書き
      if (data) {
        data.forEach(setting => {
          loadedSettings[setting.country_code] = {
            title: setting.title,
            description: setting.description,
            ogTitle: setting.og_title,
            ogDescription: setting.og_description
          };
        });
      }

      setSettings(loadedSettings);
    } catch (error) {
      console.error('Error loading SEO settings:', error);
    }
  };

  const loadOgImage = async () => {
    try {
      const { data: existingImage } = await supabase
        .from('seo_images')
        .select('image_url')
        .eq('country_code', selectedCountry)
        .single();

      if (existingImage) {
        setOgImageUrl(existingImage.image_url);
      } else {
        setOgImageUrl('');
      }
    } catch (error) {
      console.error('Error loading OG image:', error);
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setUploading(true);
      const file = event.target.files?.[0];
      if (!file) return;

      // ファイル名を生成
      const fileExt = file.name.split('.').pop();
      const fileName = `seo/${selectedCountry}/og-image-${Date.now()}.${fileExt}`;

      // Supabaseにアップロード
      const { error: uploadError, data } = await supabase.storage
        .from('images')
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: true
        });

      if (uploadError) throw uploadError;

      // 公開URLを取得
      const { data: { publicUrl } } = supabase.storage
        .from('images')
        .getPublicUrl(fileName);

      // データベースに保存または更新
      const { error: dbError } = await supabase
        .from('seo_images')
        .upsert({
          country_code: selectedCountry,
          image_url: publicUrl
        }, {
          onConflict: 'country_code'
        });

      if (dbError) throw dbError;

      setOgImageUrl(publicUrl);
      setMessage('OG画像を更新しました');
    } catch (error) {
      console.error('Error uploading image:', error);
      setMessage('画像のアップロードに失敗しました');
    } finally {
      setUploading(false);
    }
  };

  const handleChange = (
    field: 'title' | 'description' | 'ogTitle' | 'ogDescription',
    value: string
  ) => {
    setSettings(prev => ({
      ...prev,
      [selectedCountry]: {
        ...prev[selectedCountry],
        [field]: value
      }
    }));
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const seoSettings = Object.entries(settings).map(([countryCode, setting]) => ({
        country_code: countryCode,
        title: setting.title,
        description: setting.description,
        og_title: setting.ogTitle,
        og_description: setting.ogDescription
      }));

      const { error } = await supabase
        .from('seo_settings')
        .upsert(seoSettings, {
          onConflict: 'country_code'
        });

      if (error) throw error;

      setMessage('設定を保存しました');
      setTimeout(() => setMessage(''), 3000);
    } catch (error) {
      console.error('Error saving SEO settings:', error);
      setMessage('保存に失敗しました');
    } finally {
      setIsSaving(false);
    }
  };

  const loadRegions = async () => {
    try {
      const { data, error } = await supabase
        .from('regions')
        .select('*')
        .eq('country_code', selectedCountry.toUpperCase());

      if (error) throw error;
      setRegions(data || []);
    } catch (error) {
      console.error('Error loading regions:', error);
    }
  };

  const getDefaultRegionSettings = (regionName: string, countryCode: string): SEOSettings => ({
    title: `Doppi - AI Assistant in ${regionName}, ${countryOptions[countryCode.toUpperCase()]}`,
    description: `Experience natural conversations with AI assistants in ${regionName}, ${countryOptions[countryCode.toUpperCase()]}.`,
    ogTitle: `Doppi - Your AI Conversation Partner in ${regionName}`,
    ogDescription: `Experience natural conversations with our AI assistants in ${regionName}.`
  });

  const loadRegionSEOSettings = async () => {
    if (!selectedRegion) return;

    try {
      const region = regions.find(r => r.id === selectedRegion);
      if (!region) return;

      const { data, error } = await supabase
        .from('region_seo_settings')
        .select('*')
        .eq('country_code', selectedCountry)
        .eq('region_id', selectedRegion)
        .single();

      if (error && error.code !== 'PGRST116') throw error;

      setRegionSettings(prev => ({
        ...prev,
        [selectedRegion]: data || getDefaultRegionSettings(region.name, selectedCountry)
      }));
    } catch (error) {
      console.error('Error loading region SEO settings:', error);
    }
  };

  const handleRegionSave = async () => {
    if (!selectedRegion) return;

    setIsSaving(true);
    try {
      const { error } = await supabase
        .from('region_seo_settings')
        .upsert({
          country_code: selectedCountry,
          region_id: selectedRegion,
          title: regionSettings[selectedRegion].title,
          description: regionSettings[selectedRegion].description,
          og_title: regionSettings[selectedRegion].ogTitle,
          og_description: regionSettings[selectedRegion].ogDescription
        }, {
          onConflict: 'country_code,region_id'
        });

      if (error) throw error;

      setMessage('地域のSEO設定を保存しました');
      setTimeout(() => setMessage(''), 3000);
    } catch (error) {
      console.error('Error saving region SEO settings:', error);
      setMessage('地域のSEO設定の保存に失敗しました');
    } finally {
      setIsSaving(false);
    }
  };

  const handleRegionChange = (regionId: string) => {
    setSelectedRegion(regionId || null);
  };

  const handleRegionSettingChange = (
    field: keyof SEOSettings,
    value: string
  ) => {
    if (!selectedRegion) return;

    setRegionSettings(prev => ({
      ...prev,
      [selectedRegion]: {
        ...prev[selectedRegion],
        [field]: value
      }
    }));
  };

  if (!settings[selectedCountry]) {
    return (
      <div className="seo-page">
        <div className="loading">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="seo-page">
      <div className="seo-header">
        <h1>SEO設定</h1>
        <div className="tabs">
          <button 
            className={`tab ${!selectedRegion ? 'active' : ''}`}
            onClick={() => setSelectedRegion(null)}
          >
            国別設定
          </button>
          <button 
            className={`tab ${selectedRegion ? 'active' : ''}`}
            onClick={() => regions.length > 0 && handleRegionChange(regions[0].id)}
          >
            地域別設定
          </button>
        </div>
      </div>

      <div className="settings-container">
        <div className="selector-section">
          <div className="country-selector">
            <label>国を選択:</label>
            <select
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
              className="select-input"
            >
              {Object.entries(countryOptions).map(([code, name]) => (
                <option key={code.toLowerCase()} value={code.toLowerCase()}>
                  {getCountryFlag(code)} {name}
                </option>
              ))}
            </select>
          </div>

          {selectedRegion && (
            <div className="region-selector">
              <label>地域を選択:</label>
              <select
                value={selectedRegion}
                onChange={(e) => handleRegionChange(e.target.value)}
                className="select-input"
              >
                {regions.map(region => (
                  <option key={region.id} value={region.id}>
                    {region.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        {!selectedRegion ? (
          // 国別設定フォーム
          <div className="seo-form">
            <div className="form-section">
              <h3>検索エンジン設定</h3>
              <div className="form-group">
                <label>タイトル:</label>
                <input
                  type="text"
                  value={settings[selectedCountry].title}
                  onChange={(e) => handleChange('title', e.target.value)}
                  placeholder="ページのタイトルを入力"
                />
                <span className="char-count">
                  {settings[selectedCountry].title.length}/60
                </span>
              </div>

              <div className="form-group">
                <label>説明:</label>
                <textarea
                  value={settings[selectedCountry].description}
                  onChange={(e) => handleChange('description', e.target.value)}
                  placeholder="ページの説明を入力"
                />
                <span className="char-count">
                  {settings[selectedCountry].description.length}/160
                </span>
              </div>
            </div>

            <div className="form-section">
              <h3>SNS設定</h3>
              <div className="form-group">
                <label>OGタイトル:</label>
                <input
                  type="text"
                  value={settings[selectedCountry].ogTitle}
                  onChange={(e) => handleChange('ogTitle', e.target.value)}
                  placeholder="SNSでのタイトルを入力"
                />
              </div>

              <div className="form-group">
                <label>OG説明:</label>
                <textarea
                  value={settings[selectedCountry].ogDescription}
                  onChange={(e) => handleChange('ogDescription', e.target.value)}
                  placeholder="SNSでの説明を入力"
                />
              </div>

              <div className="form-group">
                <label>OG画像:</label>
                <div className="og-image-upload">
                  {ogImageUrl ? (
                    <div className="image-preview">
                      <img src={ogImageUrl} alt="OG画像プレビュー" />
                      <button 
                        className="remove-image"
                        onClick={() => setOgImageUrl('')}
                      >
                        削除
                      </button>
                    </div>
                  ) : (
                    <div className="no-image">
                      <ImageIcon size={48} />
                      <p>画像がありません</p>
                    </div>
                  )}
                  <label className="upload-button">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      disabled={uploading}
                    />
                    <Upload size={16} />
                    {uploading ? 'アップロード中...' : '画像をアップロード'}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ) : isRegionSettingsLoaded ? (
          // 地域別設定フォーム（データがロードされている場合のみ表示）
          <div className="region-seo-form">
            <div className="form-group">
              <label>地域別タイトル:</label>
              <input
                type="text"
                value={regionSettings[selectedRegion].title}
                onChange={(e) => handleRegionSettingChange('title', e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>地域別説明:</label>
              <textarea
                value={regionSettings[selectedRegion].description}
                onChange={(e) => handleRegionSettingChange('description', e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>地域別OGタイトル:</label>
              <input
                type="text"
                value={regionSettings[selectedRegion].ogTitle}
                onChange={(e) => handleRegionSettingChange('ogTitle', e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>地域別OG説明:</label>
              <textarea
                value={regionSettings[selectedRegion].ogDescription}
                onChange={(e) => handleRegionSettingChange('ogDescription', e.target.value)}
              />
            </div>

            <div className="preview-section">
              <h3>地域別プレビュー</h3>
              <div className="preview-box">
                <h4>検索結果での表示</h4>
                <div className="search-preview">
                  <div className="preview-title">{regionSettings[selectedRegion].title}</div>
                  <div className="preview-url">https://yourdomain.com/{selectedCountry}/{regions.find(r => r.id === selectedRegion)?.name.toLowerCase().replace(/ /g, '-')}</div>
                  <div className="preview-description">{regionSettings[selectedRegion].description}</div>
                </div>
                
                <h4>SNSでの表示</h4>
                <div className="social-preview">
                  <div className="preview-og-title">{regionSettings[selectedRegion].ogTitle}</div>
                  <div className="preview-og-description">{regionSettings[selectedRegion].ogDescription}</div>
                </div>
              </div>
            </div>

            <div className="form-actions">
              <button 
                onClick={handleRegionSave}
                disabled={isSaving}
                className="save-button"
              >
                {isSaving ? '保存中...' : '地域別設定を保存'}
              </button>
            </div>
          </div>
        ) : (
          // 地域別設定のローディング表示
          <div className="loading">
            <p>地域の設定を読み込み中...</p>
          </div>
        )}

        <div className="preview-section">
          <h3>プレビュー</h3>
          <div className="preview-tabs">
            <button 
              className={`preview-tab ${previewType === 'search' ? 'active' : ''}`}
              onClick={() => setPreviewType('search')}
            >
              検索結果
            </button>
            <button 
              className={`preview-tab ${previewType === 'social' ? 'active' : ''}`}
              onClick={() => setPreviewType('social')}
            >
              SNS
            </button>
          </div>

          <div className="preview-content">
            {previewType === 'search' ? (
              <div className="search-preview">
                <div className="preview-title">
                  {selectedRegion 
                    ? (isRegionSettingsLoaded ? regionSettings[selectedRegion].title : 'Loading...')
                    : settings[selectedCountry].title}
                </div>
                <div className="preview-url">
                  {`https://doppi.ai/${selectedCountry}${
                    selectedRegion 
                      ? `/${regions.find(r => r.id === selectedRegion)?.name.toLowerCase().replace(/ /g, '-')}` 
                      : ''
                  }`}
                </div>
                <div className="preview-description">
                  {selectedRegion 
                    ? (isRegionSettingsLoaded ? regionSettings[selectedRegion].description : 'Loading...')
                    : settings[selectedCountry].description}
                </div>
              </div>
            ) : (
              <div className="social-preview">
                {ogImageUrl && (
                  <div className="preview-og-image">
                    <img src={ogImageUrl} alt="OG画像プレビュー" />
                  </div>
                )}
                <div className="preview-og-title">
                  {selectedRegion 
                    ? (isRegionSettingsLoaded ? regionSettings[selectedRegion].ogTitle : 'Loading...')
                    : settings[selectedCountry].ogTitle}
                </div>
                <div className="preview-og-description">
                  {selectedRegion 
                    ? (isRegionSettingsLoaded ? regionSettings[selectedRegion].ogDescription : 'Loading...')
                    : settings[selectedCountry].ogDescription}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="form-actions">
          <button 
            onClick={selectedRegion ? handleRegionSave : handleSave}
            disabled={Boolean(isSaving || (selectedRegion && !isRegionSettingsLoaded))}
            className="save-button"
          >
            {isSaving ? '保存中...' : '保存'}
          </button>
        </div>

        {message && (
          <div className="message">
            {message}
          </div>
        )}
      </div>
    </div>
  );
} 