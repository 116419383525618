interface InstructionsParams {
  instruction: string;
  character_name: string;
  user_name: string;
  personality?: string;
  speaking_style?: string;
  past_content?: string;
  language?: string;
  timezone?: string;
}

export function getInstructions({
  instruction,
  character_name,
  user_name,
  personality = '',
  speaking_style = '',
  past_content = '',
  language = 'ja',
  timezone = 'America/New_York'
}: InstructionsParams): string {
  // ユーザーのローカル時間を取得
  const userLocalTime = new Date().toLocaleString('ja-JP', {
    timeZone: timezone,
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });

  // 言語に応じて指示を調整
  let languageInstruction = '';
  
  // usersテーブルのlanguageカラムは2文字のコードを使用
  switch (language) {
    case 'ja':
      languageInstruction = '日本語で応答してください。';
      break;
    case 'hi':
      languageInstruction = 'कृपया हिंदी में जवाब दें।';
      break;
    case 'ar':
      languageInstruction = 'الرجاء الرد باللغة العربية.';
      break;
    case 'ko':
      languageInstruction = '한국어로 응답해 주세요.';
      break;
    case 'zh':
      languageInstruction = '请用中文回答。';
      break;
    case 'ru':
      languageInstruction = 'Пожалуйста, отвечайте на русском языке.';
      break;
    case 'es':
      languageInstruction = 'Por favor, responda en español.';
      break;
    case 'fr':
      languageInstruction = 'Veuillez répondre en français.';
      break;
    case 'de':
      languageInstruction = 'Bitte antworten Sie auf Deutsch.';
      break;
    case 'it':
      languageInstruction = 'Per favore, risponda in italiano.';
      break;
    case 'pt':
      languageInstruction = 'Por favor, responda em português.';
      break;
    case 'nl':
      languageInstruction = 'Gelieve in het Nederlands te antwoorden.';
      break;
    case 'th':
      languageInstruction = 'กรุณาตอบเป็นภาษาไทย';
      break;
    case 'vi':
      languageInstruction = 'Vui lòng trả lời bằng tiếng Việt.';
      break;
    case 'tr':
      languageInstruction = 'Lütfen Türkçe olarak yanıtlayın.';
      break;
    case 'id':
      languageInstruction = 'Mohon jawab dalam bahasa Indonesia.';
      break;
    default:
      languageInstruction = 'Please respond in English.';
  }

  // 過去の会話がある場合の追加指示
  const pastContentInstruction = past_content ? `
    Previous conversation history for context:
    ---
    ${past_content}
    ---
    Please continue the conversation while maintaining consistency with the above context.
  ` : '';

  // 基本指示と過去の会話を組み合わせる
  return `
    ${instruction}

    Base Configuration:
    - Character Name: ${character_name}
    - User Name: ${user_name}
    - Current Local Time: ${userLocalTime}
    - User Timezone: ${timezone}
    
    ${languageInstruction}

    ${pastContentInstruction}

    Important Notes:
    - Always refer to the conversation history to maintain consistency
    - Reference previously shared information naturally in the conversation flow
    - Ensure responses align with the character's established personality and previous statements
    - When mentioning time, use the user's local time zone (${timezone})
  `.trim();
} 