import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../lib/supabase';
import { AssistantDataType } from '../types/Assistant';
import { Search, Save, Settings, AlertCircle, Edit2, Check, X, Upload, Share2, Trash2, Download, Copy } from 'react-feather';
import './AdminAssistantsPage.scss';
import { Country } from '../types/Location'; 
import { shortenId } from '../utils/id';
import { Link } from 'react-router-dom';

const VOICE_OPTIONS = [
  { id: 'alloy', label: 'Alloy', description: '中性的な声質' },
  { id: 'echo', label: 'Echo', description: '男性的な声質' },
  { id: 'shimmer', label: 'Shimmer', description: '女性的な声質' },
  { id: 'ash', label: 'Ash', description: 'より自然な抑揚と感情表現' },
  { id: 'ballad', label: 'Ballad', description: '物語の朗読に適している' },
  { id: 'coral', label: 'Coral', description: '温かみのある声質' },
  { id: 'sage', label: 'Sage', description: '落ち着いた声質' },
  { id: 'verse', label: 'Verse', description: '活気のある声質' }
];

const ROLE_OPTIONS = [
  { value: 'normal', label: 'ノーマル', description: 'ログインユーザー専用' },
  { value: 'welcome', label: 'ウェルカム', description: '非ログインユーザー専用' },
  { value: 'premium', label: 'プレミアム', description: 'プレミアム会員専用' },
  { value: 'special', label: 'スペシャル', description: '特別なイベント用' }
] as const;

interface UploadingState {
  id: string;
  type: 'avatar' | 'talk' | 'stop';
}

interface Mode {
  id: string;
  code: string;
  name: string;
  description?: string;
}

interface DeleteModalState {
  assistant: AssistantDataType | null;
  isOpen: boolean;
}

function AdminAssistantsPage() {
  const [assistants, setAssistants] = useState<AssistantDataType[]>([]);
  const [originalAssistants, setOriginalAssistants] = useState<AssistantDataType[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCountryFilter, setSelectedCountryFilter] = useState<string>('');
  const [sortBy, setSortBy] = useState<'name' | 'country'>('name');
  const [saving, setSaving] = useState<string | null>(null);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingDisplayNameId, setEditingDisplayNameId] = useState<string | null>(null);
  const [tempInstruction, setTempInstruction] = useState('');
  const [tempDisplayName, setTempDisplayName] = useState('');
  const [tempDescription, setTempDescription] = useState('');
  const [expandedInstructions, setExpandedInstructions] = useState<string[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [uploading, setUploading] = useState<UploadingState | null>(null);
  const [isAddingAssistant, setIsAddingAssistant] = useState(false);

  const [modes, setModes] = useState<Mode[]>([]);
  
  const [newAssistant, setNewAssistant] = useState<Partial<AssistantDataType>>({
    name: '',
    display_name: '',
    country: '',
    mode_id: '',
    instruction: '',
    voice: undefined,
    role: 'normal'
  });

  const [shareModalAssistant, setShareModalAssistant] = useState<AssistantDataType | null>(null);
  const [deleteModal, setDeleteModal] = useState<DeleteModalState>({
    assistant: null,
    isOpen: false
  });

  const [editingDisplayName, setEditingDisplayName] = useState<string | null>(null);

  useEffect(() => {
    fetchAssistants();
  }, []);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const { data: countriesData, error: countriesError } = await supabase
          .from('countries')
          .select('*')
          .order('name');

        if (countriesError) throw countriesError;
        setCountries(countriesData || []);

      } catch (err) {
        console.error('Error fetching location data:', err);
        setError('国データの取得に失敗しました');
      }
    };

    fetchLocationData();
  }, []);

  useEffect(() => {
    const fetchModes = async () => {
      try {
        const { data: modesData, error: modesError } = await supabase
          .from('modes')
          .select('*')
          .order('name');

        if (modesError) throw modesError;
        setModes(modesData || []);
      } catch (err) {
        console.error('Error fetching modes:', err);
        setError('モードデータの取得に失敗しました');
      }
    };
    fetchModes();
  }, []);

  const fetchAssistants = async () => {
    try {
      const { data, error } = await supabase
        .from('assistants')
        .select('*')
        .order('display_name');

      if (error) throw error;
      setAssistants(data || []);
      setOriginalAssistants(data || []);
    } catch (err) {
      console.error('Error fetching assistants:', err);
      setError('アシスタントの取得に失敗しました');
    }
  };

  const updateAssistant = async (assistant: AssistantDataType) => {
    setSaving(assistant.id);
    try {
      const { error } = await supabase
        .from('assistants')
        .update({
          instruction: assistant.instruction,
          display_name: assistant.display_name,
          type_flag: assistant.type_flag,
          voice: assistant.voice,
          country: assistant.country,
          mode_id: assistant.mode_id,
          description: assistant.description,
          role: assistant.role
        })
        .eq('id', assistant.id);

      if (error) throw error;
      
      const notification = document.createElement('div');
      notification.className = 'save-notification';
      notification.textContent = '保存しました';
      document.body.appendChild(notification);
      
      setTimeout(() => {
        document.body.removeChild(notification);
      }, 2000);
      
    } catch (err) {
      console.error('Error updating assistant:', err);
      setError('更新に失敗しました');
    } finally {
      setSaving(null);
    }
  };

  const handleChange = (id: string, field: keyof AssistantDataType, value: any) => {
    setAssistants(prev => {
      const newAssistants = prev.map(assistant => {
        if (assistant.id === id) {
          return {
            ...assistant,
            [field]: value
          };
        }
        return assistant;
      });
      
      const hasChanges = JSON.stringify(newAssistants) !== JSON.stringify(originalAssistants);
      setHasChanges(hasChanges);
      
      return newAssistants;
    });
  };

  const handleVoiceChange = (assistantId: string, voice: AssistantDataType['voice']) => {
    setAssistants(prev =>
      prev.map(assistant => {
        if (assistant.id === assistantId) {
          const updatedAssistant = { ...assistant, voice };
          updateAssistant(updatedAssistant);
          return updatedAssistant;
        }
        return assistant;
      })
    );
  };

  const sortAndFilterAssistants = (assistants: AssistantDataType[]) => {
    let filtered = assistants;
    
    if (searchTerm) {
      filtered = filtered.filter(assistant =>
        assistant.display_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (selectedCountryFilter) {
      filtered = filtered.filter(assistant => assistant.country === selectedCountryFilter);
    }

    return filtered.sort((a, b) => {
      if (sortBy === 'country') {
        const countryA = countries.find(c => c.code === a.country)?.name || '';
        const countryB = countries.find(c => c.code === b.country)?.name || '';
        return countryA.localeCompare(countryB);
      }
      return a.display_name.localeCompare(b.display_name);
    });
  };

  const startEditing = (assistant: AssistantDataType) => {
    setEditingId(assistant.id);
    setTempInstruction(assistant.instruction || '');
    setTempDisplayName(assistant.display_name || '');
    setTempDescription(assistant.description || '');
  };

  const cancelEditing = () => {
    setEditingId(null);
    setTempInstruction('');
    setTempDisplayName('');
    setTempDescription('');
  };

  const handleSaveAll = async () => {
    try {
      for (const assistant of assistants) {
        await updateAssistant(assistant);
      }
      setOriginalAssistants(assistants);
      setHasChanges(false);
      
      const notification = document.createElement('div');
      notification.className = 'save-notification';
      notification.textContent = '全ての変更を保存しました';
      document.body.appendChild(notification);
      
      setTimeout(() => {
        document.body.removeChild(notification);
      }, 2000);
    } catch (err) {
      console.error('Error saving all changes:', err);
      setError('変更の保存に失敗しました');
    }
  };

  const handleCancel = () => {
    setAssistants(originalAssistants);
    setHasChanges(false);
  };

  const toggleInstruction = (id: string) => {
    setExpandedInstructions(prev => 
      prev.includes(id) 
        ? prev.filter(i => i !== id)
        : [...prev, id]
    );
  };

  const handleFileUpload = async (
    assistantId: string, 
    file: File, 
    type: 'avatar' | 'talk' | 'stop'
  ) => {
    setUploading({ id: assistantId, type });
    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `assistants/${assistantId}_${type}.${fileExt}`;
      const filePath = fileName;

      const { error: uploadError } = await supabase.storage
        .from('images')
        .upload(filePath, file, { 
          cacheControl: '3600',
          upsert: true 
        });

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('images')
        .getPublicUrl(filePath);

      const updateData = type === 'avatar' 
        ? { avatar_url: publicUrl }
        : type === 'talk'
        ? { talkmovie_url: publicUrl }
        : { stopmovie_url: publicUrl };

      const { error: updateError } = await supabase
        .from('assistants')
        .update(updateData)
        .eq('id', assistantId);

      if (updateError) throw updateError;

      setAssistants(prev =>
        prev.map(assistant => {
          if (assistant.id === assistantId) {
            return { ...assistant, ...updateData };
          }
          return assistant;
        })
      );

      const notification = document.createElement('div');
      notification.className = 'save-notification';
      notification.textContent = 'アップロードが完了しました';
      document.body.appendChild(notification);
      
      setTimeout(() => {
        document.body.removeChild(notification);
      }, 2000);

    } catch (error) {
      console.error('Error uploading file:', error);
      setError('ファイルのアップロードに失敗しました');
    } finally {
      setUploading(null);
    }
  };

  const handleAddAssistant = async () => {
    try {
      const { data: assistant, error } = await supabase
        .from('assistants')
        .insert([{
          ...newAssistant,
          mode_id: newAssistant.mode_id || null
        }])
        .select()
        .single();

      if (error) throw error;

      const mediaUploads = await Promise.all([
        uploadMediaFile('avatar'),
        uploadMediaFile('talk'),
        uploadMediaFile('stop')
      ]);

      const [avatarUrl, talkMovieUrl, stopMovieUrl] = mediaUploads;
      
      const { error: updateError } = await supabase
        .from('assistants')
        .update({
          avatar_url: avatarUrl,
          talkmovie_url: talkMovieUrl,
          stopmovie_url: stopMovieUrl
        })
        .eq('id', assistant.id);

      if (updateError) throw updateError;

      setAssistants(prev => [...prev, {
        ...assistant,
        avatar_url: avatarUrl,
        talkmovie_url: talkMovieUrl,
        stopmovie_url: stopMovieUrl
      }]);
      
      setIsAddingAssistant(false);
      resetForm();

      const notification = document.createElement('div');
      notification.className = 'save-notification';
      notification.textContent = 'アシスタントを追加しました';
      document.body.appendChild(notification);
      
      setTimeout(() => {
        document.body.removeChild(notification);
      }, 2000);

    } catch (err) {
      console.error('Error adding assistant:', err);
      setError('アシスタントの追加に失敗しました');
    }
  };

  const uploadMediaFile = async (type: 'avatar' | 'talk' | 'stop') => {
    const fileInput = document.querySelector(`input[name="${type}-file"]`) as HTMLInputElement;
    const file = fileInput?.files?.[0];
    
    if (!file) return null;

    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `assistants/${Date.now()}_${type}.${fileExt}`;
      const filePath = fileName;

      const { error: uploadError } = await supabase.storage
        .from('images')
        .upload(filePath, file, { 
          cacheControl: '3600',
          upsert: true 
        });

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('images')
        .getPublicUrl(filePath);

      return publicUrl;
    } catch (error) {
      console.error(`Error uploading ${type} file:`, error);
      return null;
    }
  };

  const resetForm = () => {
    setNewAssistant({
      name: '',
      display_name: '',
      country: '',
      mode_id: '',
      instruction: '',
      voice: undefined,
      role: 'normal'
    });
    
    const fileInputs = document.querySelectorAll('input[type="file"]');
    fileInputs.forEach(input => {
      (input as HTMLInputElement).value = '';
    });
  };

  const startEditingDisplayName = (assistant: AssistantDataType) => {
    setEditingDisplayName(assistant.id);
    setTempDisplayName(assistant.display_name);
  };

  const saveDisplayName = async (assistant: AssistantDataType) => {
    try {
      await updateAssistant({
        ...assistant,
        display_name: tempDisplayName
      });
      
      setAssistants(prev =>
        prev.map(a =>
          a.id === assistant.id ? { ...a, display_name: tempDisplayName } : a
        )
      );
      
      setEditingDisplayName(null);
      
      const notification = document.createElement('div');
      notification.className = 'save-notification';
      notification.textContent = '表示名を保存しました';
      document.body.appendChild(notification);
      
      setTimeout(() => {
        document.body.removeChild(notification);
      }, 2000);
      
    } catch (err) {
      console.error('Error saving display name:', err);
      setError('表示名の保存に失敗しました');
    }
  };

  const getShareUrl = (assistant: AssistantDataType) => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/${assistant.country}/${shortenId(assistant.id)}`;
  };

  const handleShare = (assistant: AssistantDataType) => {
    setShareModalAssistant(assistant);
  };

  const closeShareModal = () => {
    setShareModalAssistant(null);
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      const notification = document.createElement('div');
      notification.className = 'save-notification';
      notification.textContent = 'URLをコピーしました';
      document.body.appendChild(notification);
      
      setTimeout(() => {
        document.body.removeChild(notification);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleCountryChange = (assistantId: string, countryCode: string) => {
    handleChange(assistantId, 'country', countryCode);
  };

  const handleDelete = async (assistant: AssistantDataType) => {
    setDeleteModal({ assistant, isOpen: true });
  };

  const confirmDelete = async () => {
    if (!deleteModal.assistant) return;

    try {
      const { error } = await supabase
        .from('assistants')
        .delete()
        .eq('id', deleteModal.assistant.id);

      if (error) throw error;

      // アバター画像、動画の削除
      const filesToDelete = [
        `assistants/${deleteModal.assistant.id}_avatar`,
        `assistants/${deleteModal.assistant.id}_talk`,
        `assistants/${deleteModal.assistant.id}_stop`
      ];

      for (const file of filesToDelete) {
        await supabase.storage
          .from('images')
          .remove([file]);
      }

      setAssistants(prev => 
        prev.filter(a => a.id !== deleteModal.assistant?.id)
      );
      setOriginalAssistants(prev => 
        prev.filter(a => a.id !== deleteModal.assistant?.id)
      );

      const notification = document.createElement('div');
      notification.className = 'save-notification';
      notification.textContent = 'アシスタントを削除しました';
      document.body.appendChild(notification);
      
      setTimeout(() => {
        document.body.removeChild(notification);
      }, 2000);

    } catch (err) {
      console.error('Error deleting assistant:', err);
      setError('削除に失敗しました');
    } finally {
      setDeleteModal({ assistant: null, isOpen: false });
    }
  };

  const handleDownloadImage = async (url: string, fileName: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (err) {
      console.error('Error downloading image:', err);
      setError('画像のダウンロードに失敗しました');
    }
  };

  const duplicateAssistant = async (assistant: AssistantDataType) => {
    try {
      // 新しいアシスタントデータを作成
      const newAssistant = {
        ...assistant,
        name: `${assistant.name}-copy`,
        display_name: `${assistant.display_name}-copy`
      };
      
      // id は自動生成されるので削除
      delete (newAssistant as any).id;

      const { data: createdAssistant, error } = await supabase
        .from('assistants')
        .insert([newAssistant])
        .select()
        .single();

      if (error) throw error;

      // メディアファイルの複製
      if (assistant.avatar_url) {
        await duplicateFile(assistant.avatar_url, createdAssistant.id, 'avatar');
      }
      if (assistant.talkmovie_url) {
        await duplicateFile(assistant.talkmovie_url, createdAssistant.id, 'talk');
      }
      if (assistant.stopmovie_url) {
        await duplicateFile(assistant.stopmovie_url, createdAssistant.id, 'stop');
      }

      // アシスタントリストを更新
      setAssistants(prev => [...prev, createdAssistant]);
      setOriginalAssistants(prev => [...prev, createdAssistant]);

      const notification = document.createElement('div');
      notification.className = 'save-notification';
      notification.textContent = 'アシスタントを複製しました';
      document.body.appendChild(notification);
      
      setTimeout(() => {
        document.body.removeChild(notification);
      }, 2000);

    } catch (err) {
      console.error('Error duplicating assistant:', err);
      setError('アシスタントの複製に失敗しました');
    }
  };

  const duplicateFile = async (sourceUrl: string, newAssistantId: string, type: 'avatar' | 'talk' | 'stop') => {
    try {
      // 元のファイルをダウンロード
      const response = await fetch(sourceUrl);
      const blob = await response.blob();

      // 新しいファイル名を生成
      const fileExt = sourceUrl.split('.').pop();
      const newFileName = `assistants/${newAssistantId}_${type}.${fileExt}`;

      // 新しいファイルをアップロード
      const { error: uploadError } = await supabase.storage
        .from('images')
        .upload(newFileName, blob, { 
          cacheControl: '3600',
          upsert: true 
        });

      if (uploadError) throw uploadError;

      // 新しいファイルのURLを取得
      const { data: { publicUrl } } = supabase.storage
        .from('images')
        .getPublicUrl(newFileName);

      // アシスタントのメディアURLを更新
      const updateData = type === 'avatar' 
        ? { avatar_url: publicUrl }
        : type === 'talk'
        ? { talkmovie_url: publicUrl }
        : { stopmovie_url: publicUrl };

      const { error: updateError } = await supabase
        .from('assistants')
        .update(updateData)
        .eq('id', newAssistantId);

      if (updateError) throw updateError;

    } catch (err) {
      console.error(`Error duplicating ${type} file:`, err);
      throw err;
    }
  };

  return (
    <div className="admin-assistants-page">
      <div className="admin-header">
        <h1>
          <Settings className="settings-icon" />
          アシスタント管理
        </h1>
        <div className="filter-sort-container">
          <div className="country-filter">
            <select
              value={selectedCountryFilter}
              onChange={(e) => setSelectedCountryFilter(e.target.value)}
              className="country-select"
            >
              <option value="">全ての国</option>
              {countries.map(country => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="sort-buttons">
            <button
              className={`sort-button ${sortBy === 'name' ? 'active' : ''}`}
              onClick={() => setSortBy('name')}
            >
              名前順
            </button>
            <button
              className={`sort-button ${sortBy === 'country' ? 'active' : ''}`}
              onClick={() => setSortBy('country')}
            >
              国順
            </button>
          </div>
          <div className="search-box">
            <Search className="search-icon" />
            <input
              type="text"
              placeholder="アシスタントを検索..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <button 
          className="add-assistant-button"
          onClick={() => setIsAddingAssistant(true)}
        >
          アシスタントを追加
        </button>
      </div>

      {isAddingAssistant && (
        <div className="add-assistant-form">
          <h3>新規アシスタント追加</h3>
          <div className="form-group">
            <label>システム名:</label>
            <input
              type="text"
              value={newAssistant.name}
              onChange={(e) => setNewAssistant(prev => ({ ...prev, name: e.target.value }))}
            />
          </div>
          <div className="form-group">
            <label>表示名:</label>
            <input
              type="text"
              value={newAssistant.display_name}
              onChange={(e) => setNewAssistant(prev => ({ ...prev, display_name: e.target.value }))}
            />
          </div>
          <div className="form-group">
            <label>国:</label>
            <select
              value={newAssistant.country}
              onChange={(e) => setNewAssistant(prev => ({ ...prev, country: e.target.value }))}
            >
              <option value="">選択してください</option>
              {countries.map(country => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>モード:</label>
            <select
              value={newAssistant.mode_id}
              onChange={(e) => setNewAssistant(prev => ({ ...prev, mode_id: e.target.value }))}
              disabled={modes.length === 0}
            >
              <option value="">選択してください</option>
              {modes.map(mode => (
                <option key={mode.id} value={mode.id}>
                  {mode.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>指示内容:</label>
            <textarea
              value={newAssistant.instruction}
              onChange={(e) => setNewAssistant(prev => ({ ...prev, instruction: e.target.value }))}
            />
          </div>
          <div className="form-group">
            <label>声の種類:</label>
            <select
              value={newAssistant.voice}
              onChange={(e) => setNewAssistant(prev => ({ ...prev, voice: e.target.value as AssistantDataType['voice'] }))}
            >
              <option value="">選択してください</option>
              {VOICE_OPTIONS.map(voice => (
                <option key={voice.id} value={voice.id}>
                  {voice.label}
                </option>
              ))}
            </select>
          </div>
          <div className="media-upload-section">
            <h4>メディアファイル</h4>
            <div className="upload-group">
              <label>アバター画像:</label>
              <div className="preview-upload">
                <input
                  type="file"
                  name="avatar-file"
                  accept="image/*"
                  className="file-input"
                />
              </div>
            </div>
            <div className="upload-group">
              <label>トーク動画:</label>
              <div className="preview-upload">
                <input
                  type="file"
                  name="talk-file"
                  accept="video/*"
                  className="file-input"
                />
              </div>
            </div>
            <div className="upload-group">
              <label>停止動画:</label>
              <div className="preview-upload">
                <input
                  type="file"
                  name="stop-file"
                  accept="video/*"
                  className="file-input"
                />
              </div>
            </div>
          </div>
          <div className="form-actions">
            <button 
              className="cancel-button"
              onClick={() => {
                setIsAddingAssistant(false);
                resetForm();
              }}
            >
              キャンセル
            </button>
            <button 
              className="save-button"
              onClick={handleAddAssistant}
              disabled={
                !newAssistant.name || 
                !newAssistant.display_name || 
                !newAssistant.country || 
                !newAssistant.mode_id
              }
            >
              追加
            </button>
          </div>
        </div>
      )}

      {error && (
        <div className="error-message">
          <AlertCircle className="error-icon" />
          {error}
        </div>
      )}

      <div className="assistants-grid">
        {sortAndFilterAssistants(assistants).map(assistant => (
          <div key={assistant.id} className="assistant-card">
            <div className="assistant-header">
              <div className="assistant-info-container">
                <div className="assistant-avatar">
                  {assistant.avatar_url ? (
                    <img src={assistant.avatar_url} alt={assistant.display_name} />
                  ) : (
                    <span>{assistant.display_name[0]}</span>
                  )}
                </div>
                <div className="assistant-info">
                  {editingDisplayName === assistant.id ? (
                    <div className="display-name-edit">
                      <input
                        type="text"
                        value={tempDisplayName}
                        onChange={(e) => setTempDisplayName(e.target.value)}
                        className="display-name-input"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            saveDisplayName(assistant);
                          } else if (e.key === 'Escape') {
                            setEditingDisplayName(null);
                          }
                        }}
                        autoFocus
                      />
                      <div className="edit-actions">
                        <button
                          className="cancel-button"
                          onClick={() => setEditingDisplayName(null)}
                          title="キャンセル"
                        >
                          <X size={16} />
                        </button>
                        <button
                          className="save-button"
                          onClick={() => saveDisplayName(assistant)}
                          title="保存"
                        >
                          <Check size={16} />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <h2 
                        onClick={() => startEditingDisplayName(assistant)}
                        className="display-name"
                      >
                        {assistant.display_name}
                      </h2>
                      <div className="location-info">
                        {countries.find(c => c.code === assistant.country)?.name}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="voice-selector">
                <h4>声の種類</h4>
                <select
                  value={assistant.voice || ''}
                  onChange={(e) => handleVoiceChange(assistant.id, e.target.value as AssistantDataType['voice'])}
                  className="voice-select"
                >
                  <option value="">選択してください</option>
                  {VOICE_OPTIONS.map(voice => (
                    <option key={voice.id} value={voice.id}>
                      {voice.label}（{voice.description}）
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="location-selector">
              <div className="form-group">
                <label>国:</label>
                <select
                  value={assistant.country || ''}
                  onChange={(e) => handleCountryChange(assistant.id, e.target.value)}
                >
                  <option value="">選択してください</option>
                  {countries.map(country => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>モード:</label>
                <select
                  value={assistant.mode_id || ''}
                  onChange={(e) => handleChange(assistant.id, 'mode_id', e.target.value)}
                >
                  <option value="">選択してください</option>
                  {modes.map(mode => (
                    <option key={mode.id} value={mode.id}>
                      {mode.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="instruction-editor">
              <div className="editor-header">
                <h4>指示内容</h4>
                {editingId !== assistant.id ? (
                  <button 
                    className="edit-button"
                    onClick={() => startEditing(assistant)}
                  >
                    <Edit2 size={16} />
                    編集
                  </button>
                ) : (
                  <div className="edit-actions">
                    <button 
                      className="cancel-button"
                      onClick={cancelEditing}
                    >
                      <X size={16} />
                      キャンセル
                    </button>
                    <button 
                      className="save-button"
                      onClick={() => {
                        handleChange(assistant.id, 'instruction', tempInstruction);
                        handleChange(assistant.id, 'display_name', tempDisplayName);
                        handleChange(assistant.id, 'description', tempDescription);
                        updateAssistant({
                          ...assistant, 
                          instruction: tempInstruction,
                          display_name: tempDisplayName,
                          description: tempDescription
                        });
                        setEditingId(null);
                      }}
                    >
                      <Check size={16} />
                      保存
                    </button>
                  </div>
                )}
              </div>
              
              {editingId === assistant.id ? (
                <>
                  <textarea
                    value={tempInstruction}
                    onChange={(e) => setTempInstruction(e.target.value)}
                    placeholder="指示内容を入力..."
                  />
                  <div className="description-editor">
                    <h4>説明文</h4>
                    <textarea
                      value={tempDescription}
                      onChange={(e) => setTempDescription(e.target.value)}
                      placeholder="アシスタントの説明文を入力..."
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="instruction-preview">
                    {assistant.instruction ? (
                      <>
                        <div className={`instruction-text ${expandedInstructions.includes(assistant.id) ? 'expanded' : ''}`}>
                          {assistant.instruction}
                        </div>
                        <button 
                          className="more-button"
                          onClick={() => toggleInstruction(assistant.id)}
                        >
                          {expandedInstructions.includes(assistant.id) ? '閉じる' : 'もっと見る'}
                        </button>
                      </>
                    ) : (
                      '指示内容が設定されていません'
                    )}
                  </div>
                  <div className="description-preview">
                    <h4>説明文</h4>
                    <div className="description-text">
                      {assistant.description || '説明文が設定されていません'}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="media-upload-section">
              <div className="media-grid">
                <div className="upload-group">
                  <label>アバター画像:</label>
                  <div className="preview-upload">
                    {assistant.avatar_url && (
                      <div className="preview-container">
                        <img 
                          src={assistant.avatar_url} 
                          alt="アバター" 
                          className="preview-image"
                        />
                        <button
                          className="download-button"
                          onClick={() => handleDownloadImage(
                            assistant.avatar_url!, 
                            `avatar_${assistant.id}.${assistant.avatar_url!.split('.').pop()}`
                          )}
                          title="ダウンロード"
                        >
                          <Download size={16} />
                        </button>
                      </div>
                    )}
                    <label className="upload-button">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) handleFileUpload(assistant.id, file, 'avatar');
                        }}
                        disabled={uploading?.id === assistant.id}
                      />
                      <Upload size={16} />
                      {uploading?.id === assistant.id && uploading.type === 'avatar' 
                        ? 'アップロード中...' 
                        : '編集'}
                    </label>
                  </div>
                </div>

                <div className="upload-group">
                  <label>トーク動画:</label>
                  <div className="preview-upload">
                    {assistant.talkmovie_url && (
                      <div className="preview-container">
                        <video 
                          src={assistant.talkmovie_url} 
                          className="preview-video" 
                          controls
                        />
                        <button
                          className="download-button"
                          onClick={() => handleDownloadImage(
                            assistant.talkmovie_url!, 
                            `talk_${assistant.id}.${assistant.talkmovie_url!.split('.').pop()}`
                          )}
                          title="ダウンロード"
                        >
                          <Download size={16} />
                        </button>
                      </div>
                    )}
                    <label className="upload-button">
                      <input
                        type="file"
                        accept="video/*"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) handleFileUpload(assistant.id, file, 'talk');
                        }}
                        disabled={uploading?.id === assistant.id}
                      />
                      <Upload size={16} />
                      {uploading?.id === assistant.id && uploading.type === 'talk' 
                        ? 'アップロード中...' 
                        : '編集'}
                    </label>
                  </div>
                </div>

                <div className="upload-group">
                  <label>停止動画:</label>
                  <div className="preview-upload">
                    {assistant.stopmovie_url && (
                      <div className="preview-container">
                        <video 
                          src={assistant.stopmovie_url} 
                          className="preview-video" 
                          controls
                        />
                        <button
                          className="download-button"
                          onClick={() => handleDownloadImage(
                            assistant.stopmovie_url!, 
                            `stop_${assistant.id}.${assistant.stopmovie_url!.split('.').pop()}`
                          )}
                          title="ダウンロード"
                        >
                          <Download size={16} />
                        </button>
                      </div>
                    )}
                    <label className="upload-button">
                      <input
                        type="file"
                        accept="video/*"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) handleFileUpload(assistant.id, file, 'stop');
                        }}
                        disabled={uploading?.id === assistant.id}
                      />
                      <Upload size={16} />
                      {uploading?.id === assistant.id && uploading.type === 'stop' 
                        ? 'アップロード中...' 
                        : '編集'}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="role-selector">
              <h4>ロール</h4>
              <select
                value={assistant.role}
                onChange={(e) => handleChange(assistant.id, 'role', e.target.value as AssistantDataType['role'])}
                className="role-select"
              >
                {ROLE_OPTIONS.map(role => (
                  <option key={role.value} value={role.value}>
                    {role.label}（{role.description}）
                  </option>
                ))}
              </select>
            </div>

            <div className="assistant-actions">
              <button
                className="duplicate-button"
                onClick={() => duplicateAssistant(assistant)}
                title="複製"
              >
                <Copy size={16} />
              </button>
              <button
                className="share-button"
                onClick={() => handleShare(assistant)}
                title="共有URL"
              >
                <Share2 size={16} />
              </button>
              <button
                className="delete-button"
                onClick={() => handleDelete(assistant)}
                title="削除"
              >
                <Trash2 size={16} />
              </button>
            </div>
          </div>
        ))}
      </div>

      {shareModalAssistant && (
        <div className="modal-overlay">
          <div className="share-modal">
            <h3>共有URL</h3>
            <p>以下のURLを共有すると、このアシスタントと直接会話できます：</p>
            
            <div className="share-url-container">
              <input
                type="text"
                value={getShareUrl(shareModalAssistant)}
                readOnly
                className="share-url-input"
              />
              <button
                onClick={() => copyToClipboard(getShareUrl(shareModalAssistant))}
                className="copy-button"
              >
                コピー
              </button>
            </div>

            <button onClick={closeShareModal} className="close-button">
              閉じる
            </button>
          </div>
        </div>
      )}

      {hasChanges && (
        <div className="action-bar">
          <div className="action-bar-content">
            <span className="action-message">変更があります</span>
            <div className="action-buttons">
              <button className="cancel-button" onClick={handleCancel}>
                キャンセル
              </button>
              <button className="save-button" onClick={handleSaveAll}>
                保存
              </button>
            </div>
          </div>
        </div>
      )}

      {deleteModal.isOpen && (
        <div className="modal-overlay">
          <div className="delete-modal">
            <h3>アシスタントの削除</h3>
            <p>
              「{deleteModal.assistant?.display_name}」を削除してもよろしいですか？
              <br />
              この操作は取り消せません。
            </p>
            <div className="delete-modal-actions">
              <button 
                className="cancel-button"
                onClick={() => setDeleteModal({ assistant: null, isOpen: false })}
              >
                キャンセル
              </button>
              <button 
                className="delete-button"
                onClick={confirmDelete}
              >
                削除する
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminAssistantsPage;
