import { SUPPORTED_COUNTRIES } from './country';

const IPGEO_API_KEY = '06c51456f27d4648bb1ac17a0d0e89ba';

export interface GeolocationResult {
  countryCode: string;
  timezone: string;
}

// 複数のAPIを使用して位置情報を取得
async function getCountryFromMultipleAPIs(): Promise<GeolocationResult> {
  try {
    // 1. ipapi.coを試す
    const ipapiResponse = await fetch('https://ipapi.co/json/');
    const ipapiData = await ipapiResponse.json();
    console.log('ipapi.co response:', ipapiData);
    
    if (ipapiData.country_code && ipapiData.timezone) {
      const countryCode = ipapiData.country_code.toUpperCase();
      const timezone = ipapiData.timezone;
      if (countryCode in SUPPORTED_COUNTRIES) {
        return { countryCode, timezone };
      }
    }

    // 2. IPGeolocation.ioを試す
    const ipgeoResponse = await fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${IPGEO_API_KEY}`);
    const ipgeoData = await ipgeoResponse.json();
    console.log('IPGeolocation.io response:', ipgeoData);

    if (ipgeoData.country_code2 && ipgeoData.time_zone && ipgeoData.time_zone.name) {
      const countryCode = ipgeoData.country_code2.toUpperCase();
      const timezone = ipgeoData.time_zone.name;
      if (countryCode in SUPPORTED_COUNTRIES) {
        return { countryCode, timezone };
      }
    }

    // 3. ip-api.comを試す（バックアップとして）
    const ipApiResponse = await fetch('http://ip-api.com/json/');
    const ipApiData = await ipApiResponse.json();
    console.log('ip-api.com response:', ipApiData);

    if (ipApiData.countryCode && ipApiData.timezone) {
      const countryCode = ipApiData.countryCode.toUpperCase();
      const timezone = ipApiData.timezone;
      if (countryCode in SUPPORTED_COUNTRIES) {
        return { countryCode, timezone };
      }
    }

    return { countryCode: 'US', timezone: 'America/New_York' }; // デフォルト
  } catch (error) {
    console.error('Error getting country from APIs:', error);
    return { countryCode: 'US', timezone: 'America/New_York' };
  }
}

// 関数をエクスポート
export const getCountryFromIP = getCountryFromMultipleAPIs; 